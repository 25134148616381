import {Typography,Paper,TextField,Stack,Button,Box,Grid,} from "@mui/material";
import React, { useState, useContext } from "react";
import RecentBlogsBox from "./RecentBlogPosts";
import MoreFromCategory from "./MoreFromCategory";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BlogContext } from "./BlogContext";
import { CategoryContext } from "./CategoryProvider";
const BlogsContactForm = () => {
  const {blogCategories } = useContext(BlogContext);
  const { setCategory } = useContext(CategoryContext);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    countryCode: 'ae',
    phone: "",
  });
  const navigate = useNavigate();


  const handleCategoryClick = (category) => {
    setCategory(category);
    navigate('/blogs/blogs-by-category');
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    const sanitizedValue = value.replace(/[^a-zA-Z\s]/g, "");
    setFormData({ ...formData, [name]: sanitizedValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const handleMobileNumberChange = (value, country) => {
    const sanitizedValue = '+' + value.replace(/\D/g, "").slice(0, 15); // Sanitize input to 15 digits
  
    setFormData((prevFormData) => ({
      ...prevFormData,
      countryCode: country.countryCode,
      phone: sanitizedValue,
    }));
  };
  const handleEmailChange = (event) => {
    const { name, value } = event.target;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      const sanitizedValue = value.replace(/[^a-zA-Z0-9@._-]/g, "");

      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: sanitizedValue,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const textfield = {
    backgroundColor: "#ffffff",
    color: "#8593a9",
    borderRadius: "0px",
    border: "0.5px solid #e6e6e6",
    mb: "10px",
  };
  const buttons = {
    m: 0,
    marginRight: "",
    px: "25px",
    backgroundColor: "custom.lightwheat",
    color: "#4c4f54",
    fontSize: "12px",
    borderRadius: "0px",
    "&:hover": { backgroundColor: "#4c4f54", color: "#fff" },
  };
  const saveFormData = async () => {
    try {
      await axios.post("/cityluxedxb/contact.php", {
        name:formData.name,
        email: formData.email,
        countryCode: formData.countryCode,
        phone: formData.phone,
        agentEmail: "sales@cityluxedxb.com ",
        page_name: "single blog page",
        agentName: "",
        page_link: "https://www.pscityluxe.com/blogs"
      });
      console.log('Form data saved successfully');
      alert('Query submitted successfully!');
    } catch (error) {
      console.error('Error saving form data', error);
      alert('Failed to submit query. Please try again.');
    }
  };
  const handleWhatsAppClick = () => {
    saveFormData();
    
  };
  const handleEmailClick = () => {
    saveFormData();
   
  };

  const handleCallClick = () => {
    saveFormData();          
 
  };

  return (
    <>
      <Paper elevation={3}>
        <Typography
          variant="h6"
          sx={{ padding: "16px", borderBottom: ".7px solid #00000018" }}
        >
          CONTACT OUR EXPERTS
        </Typography>
        <form onSubmit={handleSubmit} style={{ padding: "16px" }}>
          <TextField
            label="Name"
            margin="normal"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            sx={{
              ...textfield,
              width: { xs: "100%", md: "335px" },
              mr: { xs: "0px", md: "5px" },
            }}
          />
          <TextField
            label="Email"
            margin="normal"
            name="email"
            value={formData.email}
            onChange={handleEmailChange}
            required
            sx={{
              ...textfield,
              width: { xs: "100%", md: "335px" },
              mr: { xs: "0px", md: "5px" },
              mb: "22px",
            }}
          />

<PhoneInput
  country={formData.countryCode}
  value={formData.phone}
  onChange={(value, country) => handleMobileNumberChange(value, country)}
  countryCodeEditable={false}
  inputProps={{
    name: "phone",
    required: true,
    style: {
      width: "335px",
      height: "56px",
      borderRadius: "0px",
      border: "1px solid #e3e0e0",
      marginBottom: "20px",
    },
  }}
/>

          <Stack
            flexDirection={{ xs: "row", sm: "row" }}
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            marginBottom="0px"
            marginTop="22px"
          >
            <Button
              sx={{ ...buttons, my: { xs: "10px", sm: "0px" } }}
              onClick={handleEmailClick}
            >
              {" "}
              <EmailIcon />{" "}
            </Button>
            <Button
              sx={{ ...buttons, my: { xs: "10px", sm: "0px" } }}
              onClick={handleCallClick}
            >
              {" "}
              <CallIcon />{" "}
            </Button>
            <Button
              sx={{ ...buttons, my: { xs: "10px", sm: "0px" } }}
              onClick={handleWhatsAppClick}
            >
              {" "}
              <WhatsAppIcon />{" "}
            </Button>
          </Stack>
        </form>
      </Paper>

      {/* Second and try now card */}

      <Paper elevation={3}>
        <Typography
          variant="h6"
          sx={{
            borderBottom: ".7px solid #00000018",
            marginTop: "25px",
            padding: "16px",
          }}
        >
          EXPLORE POPULAR TOPICS
        </Typography>
        <Box sx={{ marginTop: "15px", padding: "16px" }}>
          {" "}
          <Typography variant="" style={{}}>
            <Grid item xs={12} md={6}>
            <ul>
              {blogCategories.map((category, index) => (
                <li
                  key={index}
                  style={{
                    color: "#000",
                    fontSize: "16px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleCategoryClick(category)}
                >
                  {category}
                </li>
              ))}
            </ul>
            
            </Grid>
          </Typography>
          <br></br>
        </Box>
      </Paper>
      <RecentBlogsBox />
      {/* <MoreFromCategory /> */}
      {/* <TopCompanies /> */}
    </>
  );
};

export default BlogsContactForm;
