import React, { createContext, useState } from 'react';

// Create a context
const CategoryContext = createContext();

// Create a provider component to manage the category state
const CategoryProvider = ({ children }) => {
  const [category, setCategory] = useState('');

  return (
    <CategoryContext.Provider value={{ category, setCategory }}>
      {children}
    </CategoryContext.Provider>
  );
};

export { CategoryContext, CategoryProvider };
