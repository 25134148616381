import React, { useState, useEffect } from "react";
import TopNavbar from "../components/top_navbar";
import ManManu from "../components/main_manu";
import { Box, Container, Card, Typography, Stack, Button, TextField,} from "@mui/material";
import Contact_img from "../image/contactBanner.jpg";
import ".././index.css";
import Client from "../components/axios";
import MainFooter from "../components/main_footer";
import BottomFooter from "../components/botttom_footer";
import Newsletter from "../components/newsletter";
import FooterButton from "../components/footer_button";
import SendIcon from "@mui/icons-material/Send";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { cardstyle } from "../theme/theme";
import Facebook from "@mui/icons-material/Facebook";
import Twitter from "@mui/icons-material/Twitter";
import LinkedIn from "@mui/icons-material/LinkedIn";
import Instagram from "@mui/icons-material/Instagram";
import YouTube from "@mui/icons-material/YouTube";
import PhoneInput from "react-phone-input-2";

const Contact = (props) => {
  const [alertMessage, setAlertMessage] = useState("");
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [formData, setFormData] = useState({
    countryCode: 'ae',
    phone: "",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { vertical, horizontal, open } = state;
  let handleClick = (newState) => {
    setState({ open: true, ...newState });
  };
  let handleClose = () => {
    setState({ ...state, open: false });
  };

  const handleMobileNumberChange = (value, country) => {
    const sanitizedValue = '+' + value.replace(/\D/g, "").slice(0, 15); // Sanitize input to 15 digits
  
    setFormData((prevFormData) => ({
      ...prevFormData,
      countryCode: country.countryCode,
      phone: sanitizedValue,
    }));
  };
  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
        sx={{ color: "red" }}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  let [phone, setPhone] = useState("");
  let [message, setMessage] = useState("");
  const SendContactDetails = () => {
    if (name !== "") {
      if (email !== "" || phone !== "") {
        Client.post("/cityluxedxb/contact.php", {
          name: name,
          email: email,
          phone: formData.phone,
          message: message,
          page_name: "contact_page",
          agentName: "",
          agentEmail: "sales@cityluxedxb.com",
          page_link: "https://www.pscityluxe.com/contact"
        })
          .then((response) => {
            if (response.data.errorcode === 200) {
              setName("");
              setEmail("");
              setFormData("");
              setMessage("");
              setAlertMessage("Your query submitted successfully, we will get you shortly!");
              handleClick({ vertical: "top", horizontal: "right" });
            } else {
              setAlertMessage("Your query submitted successfully, we will get you shortly!");
              handleClick({ vertical: "top", horizontal: "right" });
            }
          })
          .catch((err) => {
            console.log(err);
            setAlertMessage("Failed to submit query. Please try again.");
            handleClick({ vertical: "top", horizontal: "right" });
          });
      } else {
        setAlertMessage("Please Enter Either Email Or Mobile Number");
        handleClick({ vertical: "top", horizontal: "right" });
      }
    } else {
      setAlertMessage("Please Enter Name");
      handleClick({ vertical: "top", horizontal: "right" });
    }
  };
  
  let matches = props.matches;
  let manmanu = {};
  if (matches) {
    if (props.scroll) {
      manmanu = {
        width: "100%",
        py: "0px",
        mt: "-1px",
        bgcolor: "white",
        position: "fixed",
        top: 0,
        zIndex: 100,
        animation: "scrollmanu 0.3s linear",
        "@keyframes scrollmanu": {
          from: {
            py: "10px",
          },
          to: {
            py: "0px",
          },
        },
      };
    } else {
      manmanu = {
        width: "100%",
        py: "10px",
        mt: "-1px",
        bgcolor: "custom.white",
        position: "unset",
        zIndex: 100,
        animation: "scrollmanu2 0.3s linear",
        "@keyframes scrollmanu2": {
          from: {
            py: "0px",
          },
          to: {
            py: "10px",
          },
        },
      };
    }
  } else {
    manmanu = {
      width: "100%",
      bgcolor: "custom.dark4",
    };
  }

  const heading = {
    textTransform: "capitalize",
    fontWeight: "550",
    fontSize: "30px",
    color: "cutome.dark",
    cursor: "pointer",
  };
  const subheading = {
    textTransform: "capitalize",
    fontWeight: "600",
    color: "#434953",
    mb: "0px",
  };
  const textfield = {
    backgroundColor: "#ffffff",
    color: "#8593a9",
    borderRadius: "0px",
    border: "0.5px solid #e6e6e6",
    mb: "10px",
  };
  const buttons = {
    m: 0,
    marginRight: "20px",
    px: "25px",
    backgroundColor: "custom.lightwheat",
    color: "#4c4f54",
    fontSize: "12px",
    borderRadius: "0px",
    "&:hover": { backgroundColor: "#4c4f54", color: "custom.lightwheat" },
  };

  const HoverStyles = {
    "&:hover": {
      color: "custom.lightwheat",
    },
  };
  let backgroundimg = {
    zIndex: -1,
    top: 0,
    left: 0,
    width: "100%",
    height: { xs: "60vh", sm: "600px", md: "60vh", lg: "60vh" },
    backgroundImage: `url("${Contact_img}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPositionX: "center",
    backgroundPositionY: "bottom",
  };
  let mainfooterdiv = {
    bottom: "10px",
    my: "10px",
    mx: { xs: "auto", md: "0" },
  };
  const IconDiv = {
    color: "#434953",
    transition: "0.3s",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      color: "custom.lightwheat",
    },
  };
  const footerHeading = {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "17px",
    textTransform: "uppercase",
    fontWeight: "700",
    color: "#434953",
  };

  document.title = "contact-city luxe";

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={alertMessage}
        key={vertical + horizontal}
        action={action}
      ></Snackbar>

      <Box
        sx={{
          position: "relative",
          top: 0,
          left: 0,
          minHeight: {
            xs: "60vh",
            sm: "600px",
            md: "580px",
            lg: "580px",
            xl: "580px",
          },
        }}
      >
        <Box bgcolor={{ xs: "custom.dark", md: "custom.dark" }}>
          <Container maxWidth="lg">
            <TopNavbar matches={matches} />
          </Container>
        </Box>
        <Box sx={manmanu}>
          <Container maxWidth="lg">
            <ManManu scroll={props.scroll} color={"dark2"} />
          </Container>
        </Box>
        <Box sx={backgroundimg}></Box>
      </Box>

      <Box sx={{ backgroundColor: "#e5e5e5", border: "1px solid #fff" }}>
        <Container
          maxWidth="lg"
          sx={{
            mt: "60px",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: { xs: "100%", md: "40%" } }}>
            <Card
              sx={{
                backgroundColor: "transparent",
                boxShadow: "none",
                mb: "35px",
                py: "30px",
                width: "100%",
              }}
            >
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                sx={{ ...heading }}
              >
                City Luxe Real Estate
              </Typography>
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                sx={{ fontSize: "20px" }}
              >
                Suite 1113, The Burlington Business Bay, DXB
              </Typography>
              <Typography variant="body2">
                <span style={{ ...subheading }}> Mobile: &nbsp;</span>
                <a
                  href="https://api.whatsapp.com/send?phone=971554235695&text=Hello, I would like to get more information about this property you posted"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "#222",
                  }}
                >
                  +971 55 423 5695
                </a>
              </Typography>
              <Typography variant="body2">
                <span style={{ ...subheading }}> Email: &nbsp; &nbsp; </span>
                <a
                  href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=sales@cityluxedxb.com"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "#222",
                  }}
                >
                  sales@cityluxedxb.com
                </a>
              </Typography>

              <Typography
                variant="body2"
                color="text.secondary"
                marginBottom="10px"
                marginTop="20px"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                At City Luxe Real Estate, we pride ourselves for being trusted
                advisors to our clients and work to build long-term business
                relationships. Acting as advisors, we strive to bring the best
                service, the best deal and the right property to our buyers. To
                our owners, we deliver timely results supported by quality
                marketing and ensure that they keep coming back to us.
              </Typography>

              <Box
                sx={{
                  ...mainfooterdiv,
                  display: "flex",
                  justifyContent: "flex-start",
                  mt: "25px",
                }}
              >
                <Stack sx={{ width: { xs: "100%", md: "30%", m: "0px" } }}>
                  <Typography variant="body2" sx={{ ...footerHeading }}>
                    Follow Us
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  sx={{ width: { xs: "100%", md: "40%" } }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <a href="https://www.facebook.com/cityluxedxb/">
                    <Box sx={IconDiv}>
                      <Facebook sx={{ fontSize: "20px" }} />
                    </Box>
                  </a>

                  <a href="https://twitter.com/luxe_city" target="_blank">
                    <Box sx={IconDiv}>
                      <svg
                        className="forHover"
                        viewBox="0 0 24 24"
                        aria-label="Twitter"
                        width={16}
                        role="img"
                        class="your-icon-class"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g>
                          <path
                            d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
                            fill="#434953"
                          ></path>
                        </g>
                      </svg>
                      <Twitter
                        sx={{ fontSize: "16px", m: "5px", display: "none" }}
                      />
                    </Box>
                  </a>

                  <a
                    href="https://www.linkedin.com/in/cityluxedxb"
                    target="_blank"
                  >
                    <Box sx={IconDiv}>
                      <LinkedIn sx={{ fontSize: "20px" }} />
                    </Box>
                  </a>

                  <a
                    href="https://www.youtube.com/channel/UC0L6TDApvFtl_F_bIlVtQNw"
                    target="_blank"
                  >
                    <Box sx={IconDiv}>
                      <YouTube sx={{ fontSize: "20px" }} />
                    </Box>
                  </a>
                  <a
                    href="https://www.instagram.com/cityluxe.dxb/"
                    target="_blank"
                  >
                    <Box sx={IconDiv}>
                      <Instagram sx={{ fontSize: "20px" }} />
                    </Box>
                  </a>
                </Stack>
              </Box>
            </Card>
          </Box>

          <Box sx={{ width: { xs: "100%", md: "55%" } }}>
            <Card sx={{ mb: "70px", p: "30px", ...cardstyle, width: "100%" }}>
              <Stack flexDirection="row" alignItems="center" marginBottom="8px">
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  component="div"
                  sx={{ ...subheading, fontSize: "14px", m: "0px" }}
                >
                  Contact Us
                </Typography>
              </Stack>

              <Stack
                flexDirection={{ xs: "column", md: "row" }}
                justifyContent="space-between"
                alignItems="center"
                marginBottom="0px"
              >
                <TextField
                  size="small"
                  type="text"
                  placeholder="Enter Name"
                  autoComplete="off"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  sx={{
                    ...textfield,
                    width: { xs: "100%", md: "350px" },
                    mr: { xs: "0px", md: "5px" },
                  }}
                />

                <TextField
                  size="small"
                  value={email}
                  type="email"
                  autoComplete="off"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter Email"
                  sx={{
                    ...textfield,
                    width: { xs: "100%", md: "350px" },
                    mr: { xs: "0px", md: "5px" },
                  }}
                />

                <PhoneInput
                  country={formData.countryCode}
                  value={formData.phone}
                  onChange={(value, country) =>
                    handleMobileNumberChange(value, country)
                  }
                  countryCodeEditable={false}
                  inputProps={{
                    name: "phone",
                    required: true,
                    style: {
                      width: "251px",
                      height: "41px",
                      borderRadius: "0px",
                      border: "1px solid #e3e0e0",
                      marginBottom: "20px",
                    },
                  }}
                />
              </Stack>

              <Stack
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                marginBottom="0px"
              >
                <TextField
                  size="small"
                  // label="Type Keywords"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  multiline
                  rows={7}
                  maxRows={8}
                  placeholder=""
                  sx={{ ...textfield, width: "100%" }}
                />
              </Stack>

              <Stack
                flexDirection={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "flex-start", sm: "center" }}
                marginBottom="0px"
              >
                {/* <Button sx={{ ...buttons, my: { xs: "10px", sm: "0px" } }} startIcon={<EmailIcon />}>
                                Send Email
                            </Button>
                            <Button sx={{ ...buttons, my: { xs: "10px", sm: "0px" } }} startIcon={<CallIcon />}>
                                Call
                            </Button>
                            <Button sx={{ ...buttons, my: { xs: "10px", sm: "0px" } }} startIcon={<WhatsAppIcon />}>
                                Whatsapp
                            </Button> */}
                <Button
                  onClick={SendContactDetails}
                  sx={{ ...buttons, my: { xs: "10px", sm: "0px" } }}
                  startIcon={<SendIcon />}
                >
                  Send
                </Button>
              </Stack>
            </Card>
          </Box>
        </Container>
      </Box>

      {/* main foooter  */}
      <Box sx={{ pt: "50px", backgroundColor: "#2c2c2c" }}>
        <Container maxWidth="lg">
          <Box>
            <MainFooter />
          </Box>
        </Container>
        <Box sx={{ borderTop: "0.5px solid #666666", py: "20px", mt: "25px" }}>
          {/* <Box sx={{ border: "1px solid red" }}> */}
          <Container>
            <Newsletter />
          </Container>
          {/* </Box> */}
        </Box>
      </Box>
      {/* bottom footer  */}
      <Box sx={{ backgroundColor: "#272727", py: "15px" }}>
        <Container maxWidth="lg">
          <BottomFooter />
        </Container>
      </Box>
      {/* footer top scroll button  */}
      <FooterButton scroll={props.scroll} />
    </>
  );
};

export default Contact;
