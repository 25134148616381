import React, { useContext, useEffect, useState } from "react";
import { FooterContext } from "../App";
import { LinkTextContext } from "../App";
import { cardstyle } from "../theme/theme";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import TopNavbar from "../components/top_navbar";
import ManManu from "../components/main_manu";
import {
  Box,
  Container,
  Card,
  Typography,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import home_pro_img from "../uploads/2020/07/house_nice_2-12.jpg";
import Breadcrumb from "../components/bread_crumb";
import CardView from "../components/card_view";
import Advance_search from "../components/advance_search";
import MainFooter from "../components/main_footer";
import BottomFooter from "../components/botttom_footer";
import Newsletter from "../components/newsletter";
import FooterButton from "../components/footer_button";
import OurListings from "../components/our_listings";
import Client from "../components/axios";
import Pagination from "@mui/material/Pagination";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const FooterLinksResult = (props) => {
  const [record, setRecord] = useState([]);
  const { linkData } = useContext(FooterContext);
  const [isLoading, setIsLoading] = useState(true);
  const [updatedData, setUpdatedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const { linkText } = useContext(LinkTextContext);
  const itemsPerPage = 10;
  useEffect(() => {
    if (linkData !== updatedData) {
      const totalRecord = updatedData.length;
      const totalPage = Math.ceil(totalRecord / 10);
      setUpdatedData(linkData);
      settotalcount(totalPage);
      setCurrentPage(0);
    }
  }, [linkData, updatedData]);

  const handlePageChange = (event, newPage) => {
    const newCurrentPage = newPage - 1;
    if (currentPage !== newCurrentPage) {
      setCurrentPage(newCurrentPage);
    }
  };
  
  const [advanceSearchKeywords, setAdvanceSearchKeywords] = useState({
    searchKeyword: [],
    category: "",
    type: "",
    minBed: "",
    maxBed: "",
    minPrice: "",
    maxPrice: "",
    minArea: "",
    maxArea: "",
  });
  const handleImageSelection = (imageUrl) => {
    setBackgroundImage(imageUrl);
  };
  const [backgroundImage, setBackgroundImage] = useState(home_pro_img);

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = updatedData.slice(startIndex, endIndex);

  const [selectcount, setselectcount] = useState({ page: 0 });
  const [totalcount, settotalcount] = useState(0);

  // console.log(selectcount);

  const [highlow, setHighLow] = useState({ sorting: "none" });

  console.log(highlow);

  let get_search_keyword_from_advance_search = (value) => {
    setAdvanceSearchKeywords(value);
  };

  let allproperties = updatedData.map((value, index) => (
    <CardView
      key={index}
      listitem={value}
      cardwidth={"49%"}
      handleImageSelection={handleImageSelection}
    />
  ));

  let matches = props.matches;
  let manmanu = {};
  if (matches) {
    if (props.scroll) {
      manmanu = {
        width: "100%",
        py: "0px",
        mt: "-1px",
        bgcolor: "white",
        position: "fixed",
        top: 0,
        zIndex: 3,
        animation: "scrollmanu 0.3s linear",
        "@keyframes scrollmanu": {
          from: {
            py: "10px",
          },
          to: {
            py: "0px",
          },
        },
      };
    } else {
      manmanu = {
        width: "100%",
        py: "10px",
        mt: "-1px",
        bgcolor: "custom.white",
        position: "unset",
        zIndex: 3,
        animation: "scrollmanu2 0.3s linear",
        "@keyframes scrollmanu2": {
          from: {
            py: "0px",
          },
          to: {
            py: "10px",
          },
        },
      };
    }
  } else {
    manmanu = {
      width: "100%",
      bgcolor: "custom.dark4",
    };
  }

  let backgroundimg = {
    position: "fixed",
    zIndex: -1,
    top: 0,
    left: 0,
    width: "100vw",
    height: { xs: "60vh", sm: "600px", md: "100vh" },
    // backgroundColor :'blue'
    backgroundImage: `url("${home_pro_img}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const subheading = {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "17px",
    textTransform: "uppercase",
    fontWeight: "700",
    color: "#434953",
    mb: "10px",
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Properties-city luxe";
    setRecord([]);
    console.log(highlow);
    Client.post("/cityluxedxb/advance_search.php", {
      advanceSearchKeywords,
      highlow,
      selectcount,
    })
      .then((response) => {
        if (response.data.errorcode == 200) {
          let listOfProperty = response.data.propertieslist;
          let totalRecords = response.data.totalRecords;

          setRecord(listOfProperty);
          setUpdatedData(linkData);
          setIsLoading(false);

          let totalRecord = totalRecords / 10;
          let decimal_value = totalRecord - Math.floor(totalRecord);
          if (decimal_value > 0) {
            settotalcount(Math.floor(totalRecord) + 1);
          } else {
            settotalcount(totalRecord);
          }
        } else {
          console.log(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [advanceSearchKeywords, highlow, selectcount, updatedData]);

  return (
    <>
      <Box sx={{ width: "100vw" }}>
        <Box
          sx={{
            position: "relative",
            top: 0,
            left: 0,
            minHeight: {
              xs: "60vh",
              sm: "600px",
              md: "580px",
              lg: "580px",
              xl: "580px",
            },
          }}
        >
          {/* TopNavBar... */}
          <Box bgcolor={{ xs: "custom.dark", md: "custom.dark" }}>
            <Container maxWidth="lg">
              <TopNavbar matches={matches} />
            </Container>
          </Box>

          {/* NavBar */}
          <Box sx={manmanu}>
            <Container maxWidth="lg">
              <ManManu scroll={props.scroll} color={"dark2"} />
            </Container>
          </Box>

          {/* Hero section with dynamic background image */}
          <Box
            sx={{
              position: "fixed",
              zIndex: -1,
              top: 0,
              left: 0,
              width: "100vw",
              height: { xs: "60vh", sm: "600px", md: "100vh" },
              backgroundImage: `url("${backgroundImage}")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></Box>
        </Box>

        <Box sx={{ backgroundColor: "#f7f7f7", border: "1px solid #fff" }}>
          <Container
            maxWidth="lg"
            sx={{
              my: "27px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box sx={{ width: { xs: "100%", md: "70%" } }}>
              <Box sx={{ mb: "15px" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Stack>
                    {linkText && 
                    <Breadcrumb breadcrumb={["home", linkText]} />
                    }
                  </Stack>
                  <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
                    <InputLabel
                      id="demo-simple-select-standard-label"
                      sx={{ fontSize: "12px" }}
                    >
                      SORT BY{" "}
                      <NavigateNextIcon
                        fontSize="small"
                        sx={{ marginTop: "-2px" }}
                      />
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={highlow.sorting}
                      sx={{ fontSize: "12px", color: "#D7C3A8", height: "0px" }}
                      onChange={(e) => setHighLow({ sorting: e.target.value })}
                    >
                      <MenuItem
                        value={"none"}
                        sx={{ fontSize: "12px", color: "#D7C3A8" }}
                      >
                        SELECT
                      </MenuItem>
                      <MenuItem
                        value={"Price Low to high"}
                        sx={{ fontSize: "12px", color: "#D7C3A8" }}
                      >
                        {" "}
                        PRICE HIGH
                      </MenuItem>
                      <MenuItem
                        value={"Price high to low"}
                        sx={{ fontSize: "12px", color: "#D7C3A8" }}
                      >
                        PRICE LOW
                      </MenuItem>
                      <MenuItem
                        value={"BEDS MOST"}
                        sx={{ fontSize: "12px", color: "#D7C3A8" }}
                      >
                        {" "}
                        BEDS MOST
                      </MenuItem>
                      <MenuItem
                        value={"BEDS LEAST"}
                        sx={{ fontSize: "12px", color: "#D7C3A8" }}
                      >
                        BEDS LEAST
                      </MenuItem>
                    </Select>
                  </Stack>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                {isLoading ? (
                  <>
                    <Stack spacing={1}>
                      <Skeleton
                        variant="rectangular"
                        width={400}
                        height={200}
                      />
                      <Skeleton
                        variant="text"
                        width={300}
                        height={40}
                        sx={{ fontSize: "1rem" }}
                      />
                      <Skeleton
                        variant="text"
                        width={300}
                        height={30}
                        sx={{ fontSize: "1rem" }}
                      />
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                    </Stack>
                    <Stack spacing={1}>
                      <Skeleton
                        variant="rectangular"
                        width={400}
                        height={200}
                      />
                      <Skeleton
                        variant="text"
                        width={300}
                        height={40}
                        sx={{ fontSize: "1rem" }}
                      />
                      <Skeleton
                        variant="text"
                        width={300}
                        height={30}
                        sx={{ fontSize: "1rem" }}
                      />
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                    </Stack>
                  </>
                ) : updatedData.length > 0 ? (
                  paginatedData.map((value, index) => (
                    <CardView
                      key={index}
                      listitem={value}
                      cardwidth={"49%"}
                      handleImageSelection={handleImageSelection}
                    />
                  ))
                ) : (
                  <Typography variant="body1">No properties found.</Typography>
                )}

                {/* Pagination */}
                <Card
                  sx={{ ...cardstyle, width: "100%", mb: "35px", py: "10px" }}
                >
                  <Stack spacing={2} alignItems="center">
                    <Pagination
                      className="propertyPagination"
                      count={Math.ceil(updatedData.length / itemsPerPage)}
                      page={currentPage + 1}
                      onChange={handlePageChange}
                      size="small"
                      variant="outlined"
                      shape="rounded"
                    />
                  </Stack>
                </Card>
              </Box>
            </Box>

            <Box
              sx={{ width: { xs: "100%", md: "28%", position: "relative" } }}
            >
              {" "}
              <Card
                sx={{
                  ...cardstyle,
                  mb: "35px",
                  p: "30px",
                  position: "stdeicky",
                  right: "0px",
                  top: "100px",
                }}
              >
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  component="div"
                  sx={{ ...subheading }}
                >
                  Advanced Search
                </Typography>
                <Advance_search
                  sendKeywords={get_search_keyword_from_advance_search}
                />
                {/* sendRecords={get_records_pagination} */}
              </Card>
              <Card sx={{ ...cardstyle, mb: "35px", p: "30px" }}>
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  component="div"
                  sx={{ ...subheading }}
                >
                  Our Listings
                </Typography>
                <OurListings />
              </Card>
            </Box>
          </Container>
        </Box>

        {/* main foooter  */}
        <Box sx={{ pt: "50px", backgroundColor: "#2c2c2c" }}>
          <Container maxWidth="lg">
            <Box>
              <MainFooter />
            </Box>
          </Container>
          <Box
            sx={{ borderTop: "0.5px solid #666666", py: "20px", mt: "25px" }}
          >
            <Container>
              <Newsletter />
            </Container>
          </Box>
        </Box>
        {/* bottom footer  */}
        <Box sx={{ backgroundColor: "#272727", py: "15px" }}>
          <Container maxWidth="lg">
            <BottomFooter />
          </Container>
        </Box>
        {/* footer top scroll button  */}
        <FooterButton scroll={props.scroll} />
      </Box>
    </>
  );
};

export default FooterLinksResult;
