import React, { createContext, useState, useContext } from 'react';

export const DeveloperContext = createContext();

export const DeveloperProvider = ({ children }) => {
  const [developerName, setDeveloperName] = useState('');
  const [filterType, setFilterType] = useState("");

  const setDeveloperInfo = (name, type) => {
    setDeveloperName(name);
    setFilterType(type);
  };
  return (
    <DeveloperContext.Provider value={{ developerName, filterType, setDeveloperInfo }}>
      {children}
    </DeveloperContext.Provider>
  );
};
export const useDeveloperContext = () => useContext(DeveloperContext);
