import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import errorImage from '../image/defaultImg.jpeg';
import { LazyLoadImage } from "react-lazy-load-image-component";

const ImgCrousel = (props) => {
   
    return (
        <>

            <Carousel slide={true} indicators={false} interval={null}>
                {
                    props.allimage.length > 0 ?
                        props.allimage.map((val, index) => {
                            return <Carousel.Item key={index} className="CardImage">
                               
                                <LazyLoadImage src={val}
                                    alt="Image Alt"
                                    style={{ width: '100%', height: "100%" }}

                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = errorImage;
                                    }}
                                />
                            </Carousel.Item>
                        })
                        :
                        <Carousel.Item>
                            <img
                                style={{ width: '100%', height: '35vh' }}
                                src=''
                                alt="cityluxe"
                            />
                        </Carousel.Item>
                }
            </Carousel>

        </>
    )
}

export default ImgCrousel;