import { useState, useEffect } from "react";
import Client from "../axios";
import useKeywordData from "../../pages/Projects/useKeywordData";
const InputSearchKeywordList = () => {
    const projectsKeyword = useKeywordData();
    const [data, setData] = useState(null);
   
    useEffect(() => {
        Client.post('/cityluxedxb/input_search_keyword.php')
            .then((result) => {

                if (result.data.errorcode == '200') {
                    let list = result.data.result;

                    let unit_type = list.unit_type.map((value, index) => {return { label: value };})
                    let ad_type = list.ad_type.map((value, index) => {return { label: value };})
                    let community = list.community.map((value, index) => {return { label: value };})
                    let property_name = list.property_name.map((value, index) => {return { label: value };})
                    let emirate = list.emirate.map((value, index) => {return { label: value };})

                    setData({
                         "unit_type": unit_type, 
                         "ad_type": ad_type,
                          "community": community,
                          "property_name" : property_name,
                          "emirate" :emirate
                        });
                      
                       
                       
                      } else {
                    setData(null);
                }
            })
            .catch((err) => {
                console.log(`input seach keyword component err ${err}`);
            })

    }, []);
   
    return [data];

};

export default InputSearchKeywordList;