import React, { useEffect, useState } from "react";
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import errorImage from '../image/defaultImg.jpeg';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: { xs: 300, sm: 600 },
    bgcolor: 'background.paper',
};

const button = {
    position: 'absolute',
    top: '0px',
    right: '0px',
    zIndex: 500,
    background: "#fff",
    '&:hover': {
        background: "#fff"
    }
}

const SliderWithPriview = (props) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
      const [allImagesLoaded, setAllImagesLoaded] = useState(false);


    let allimage = props.allimage.map((val, index) => {
        if (val) {
            return {
                original: val,
                thumbnail: val, 
                thumbnailHeight: '80px',
                thumbnailWidth: '150px',
                originalAlt: "cityluxedxb",
            };
        } else {
            return {
                original: errorImage,
                thumbnail: errorImage,
                thumbnailHeight: '80px',
                thumbnailWidth: '150px',
                originalAlt: "cityluxedxb",
            };
        }
    });

    const handleThumbnailError = (event) => {
        console.log('Thumbnail Error:', event.target);
        const thumbnailElement = event.target;
        thumbnailElement.src = errorImage; 
    };
    
    function hideAndShowThumnailImage() {
    }

    let thumnail = true;
    function hideAndShow() {
        const addThumbnailElement = document.querySelector('.addThumnail');
        const thumbnailsWrapperElement = document.querySelector('.image-gallery-thumbnails-wrapper');
        
        if (addThumbnailElement && thumbnailsWrapperElement) {
            if (thumnail) {
                addThumbnailElement.classList.remove('removeThumnail');
                thumbnailsWrapperElement.classList.remove('image-gallery-thumnails-wrapper-show-and-hide-thumnail');
                thumnail = false;
            } else {
                addThumbnailElement.classList.add('removeThumnail');
                thumbnailsWrapperElement.classList.add('image-gallery-thumnails-wrapper-show-and-hide-thumnail');
                thumnail = true;
            }
        }
    }

    useEffect(() => {
        const thumbnailsWrapperElement = document.querySelector('.image-gallery-thumbnails-wrapper');
        const bulletsContainerElement = document.querySelector('.image-gallery-bullets-container');
    
        if (thumbnailsWrapperElement) {
            thumbnailsWrapperElement.classList.add('image-gallery-thumnails-wrapper-show-and-hide-thumnail');
        }
    
        if (bulletsContainerElement) {
            let newDiv = document.createElement('div');
            newDiv.classList.add('addThumnail');
            newDiv.classList.add('removeThumnail');
            newDiv.onclick = hideAndShow;
            bulletsContainerElement.appendChild(newDiv);
        }
    }, []);
    const handleImageLoad = () => {
        setAllImagesLoaded(true);
      };
    const handleImageError = (event) => {
        const imageElement = event.target;
        imageElement.src = errorImage; 
      };
      const allimageWithDefault = props.allimage.map((val, index) => {
        if (val && val.original && val.thumbnail) {
          return {
            original: val.original,
            thumbnail: val.thumbnail,
            thumbnailHeight: '80px',
            thumbnailWidth: '150px',
            originalAlt: "cityluxedxb",
            onImageLoad: handleImageLoad, 
            onError: handleImageError,
          };
        } else {
          return {
            original: errorImage,
            thumbnail: errorImage,
            thumbnailHeight: '80px',
            thumbnailWidth: '150px',
            originalAlt: "cityluxedxb",
            onImageLoad: handleImageLoad, 
            onError: handleImageError,
          };
        }
      });

    return (
        <>
 <div className="wrapper" id="wrapper">
                <ImageGallery
                    items={allimage}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showBullets={true}
                    onThumbnailClick={() => hideAndShowThumnailImage()}
                    slideDuration={1000}
                    onClick={() => handleOpen()}
                    autoPlay={true}
                    slideInterval={5000}
                    onImageError={handleImageError}
                />
            </div>
        {/* model will be open when we will click on image  */}

            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Button onClick={handleClose} sx={{ ...button }}><CloseIcon /></Button>
                    <ImageGallery
                        items={allimage}
                        showThumbnails={false}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        showBullets={false}
                        onImageError={handleImageError}
                    />
                </Box>
            </Modal>
        </>
    )
}
export default SliderWithPriview;
