import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom"
import { Box, Container, TextField, Stack, Button, Snackbar } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import logo from '../../../assests/logo_img/cl-logo.png'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Client from "../../../components/axios";
import UserContext from "../auth/auth";
import UserContextCurrency from "../../../context/currencyChange";
import Cookies from 'js-cookie';

let shadow = {
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    borderRadius: "10px",
}
const button = {
    color: 'custom.white',
    bgcolor: 'custom.lightwheat',
    transition: '0.3s',
    fontWeight: '500',
    border: '1px solid #ffffff',
    px: '40px',
    borderRadius: '0px',
    fontSize: { xs: '9px', md: '13px' },
    py: '10px ',
    '&:hover': {
        bgcolor: 'transparent',
        color: 'custom.lightwheat',
        border: "1px solid #e1d3bc"
    }

}

const Login = () => {
    const { currencySign, setCurrencySign, value, setValue } = useContext(UserContextCurrency);
    // console.log(value);

    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const [state, setState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });

    const { vertical, horizontal, open } = state;
    let handleClick = (newState) => {
        setState({ open: true, ...newState });
    };

    let handleClose = () => {
        setState({ ...state, open: false });
    };

    const action = (
        <>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
                sx={{ color: "red" }}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );

    let navigate = useNavigate();

    const checkLoginValue = () => {
        if (name !== '' && password !== '') {
            setLoading(true);
            // window.scrollTo(0, 300);
            Client.post('/cityluxedxb/login_action.php', {
                "username": name,
                "password": password,
            })
                .then((response) => {
                    if (response.data.errorcode == 200) {
                        setLoading(false);
                        // console.log(userstatus);
                        // console.log(response.data);
                        // browserHistory.push("/adminpanel/login/login");
                        // userstatus = true;
                        setValue(true);
                        // document.cookie = `username=${name}`;
                        // document.cookie = `userpassword=${password}`;
                        // Set a cookie with a name, value, and expiration date
                        Cookies.set('userName', name, { expires: 7, path: '/' });
                        Cookies.set('userPassword', password, { expires: 7, path: '/' });

                        navigate("/adminpanel/dashboard/dashboard");

                    } else {
                        // console.log(response.data);
                        setAlertMessage('Please Enter Correct Details');
                        handleClick({ vertical: 'top', horizontal: 'right', })
                        setLoading(false);
                    }
                })
                .catch(err => {
                    console.log(err);
                    setLoading(false);
                });

        } else {
            setAlertMessage('Please Enter All Details');
            handleClick({ vertical: 'top', horizontal: 'right', })
        }
    }


    return (
        <>

            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={alertMessage}
                key={vertical + horizontal}
                action={action}

            >
            </Snackbar>

            <Box sx={{ width: '100vw', height: "100vh", backgroundColor: "custom.dark4" }}>
                <Container sx={{ display: "flex", justifyContent: 'center', height: '100vh' }}>
                    <Box sx={{ backgroundColor: '#fff', ...shadow, height: "fit-content", mt: '10vh', padding: '30px', width: { xs: "100%", md: "60%" } }}>
                        <Stack sx={{ width: "50%", mx: "auto" }}>
                            <img src={logo} alt="logo" style={{ width: '100%' }} />
                        </Stack>

                        <Stack sx={{ py: "30px" }}>
                            <TextField id="standard-basic" type='text' label="User Name" variant="standard" sx={{ my: "10px" }} onChange={(e) => setName(e.target.value)} />
                            <TextField id="standard-basic" type='password' label="Password" variant="standard" sx={{ my: "10px" }} onChange={(e) => setPassword(e.target.value)} />
                        </Stack>
                        <Stack>

                            {/* <Button variant="" sx={{ ...button }} onClick={() => checkLoginValue()}>Login</Button> */}

                            <LoadingButton
                                // size="small"
                                sx={{ ...button }}
                                onClick={checkLoginValue}
                                // endIcon={<SaveIcon />}
                                loading={loading}
                                loadingPosition="end"
                            // variant="contained"
                            >
                                Login
                            </LoadingButton>

                        </Stack>
                    </Box>
                </Container>
            </Box>
        </>
    );
}

export default Login;