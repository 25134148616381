import React, { useState, useEffect } from "react";
import { cardstyle } from "../theme/theme";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import Slider from "react-slick";

const CustomerReview = () => {
  const [reviews, setReviews] = useState([]);
  const [filteredReviews, setFilteredReviews] = useState([]);

  useEffect(() => {
    const filteredData = reviews.filter((value) => value.showPage.includes("Home Page"));
    setFilteredReviews(filteredData);
  }, [reviews]);
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await fetch(
          "https://cityluxedxb.com/cityluxedxb/all_reviews.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          const data = await response.json();
          console.error(`API Error ${data.errorcode}: ${data.status}`);
          return;
        }

        const data = await response.json();
        setReviews(data.reviewslist);
      } catch (error) {
        console.error("Error fetching reviews:", error.message);
      }
    };

    fetchReviews();
  }, []);

  const heading = {
    textTransform: "capitalize",
    fontWeight: "600",
    fontFamily: "'Roboto', sans-serif;",
    fontSize: "16px",
    color: "#222",
    lineHeight: "1.65em",
  };

  const subheading = {
    fontSize: "14px",
    color: "#5c727d",
    fontWeight: "600",
    fontFamily: "'Nunito Sans', sans-serif",
    backgroundColor: "#fff",
    lineHeight: "1.65em",
  };

  const renderStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    for (let i = 0; i < fullStars; i++) {
      stars.push(<StarIcon key={i} fontSize="small" color="warning" />);
    }

    if (hasHalfStar) {
      stars.push(
        <StarHalfIcon key={fullStars} fontSize="small" color="warning" />
      );
    }

    return stars;
  };

  var settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 724,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };
        const filterReviews = reviews.filter((value) =>
        value.showPage.includes("Home Page, Team Page") || value.showPage.includes("Home Page")
      );
  return (
    <>
       
        <Slider {...settings}
 key="unique-slider-key" itemsToShow={2}>
         {filterReviews.map((value, index) => (
         
          <div key={index} style={{ border: "1px solid red" }}>
            <Stack sx={{ mb: "20px", mx: "5px" }}>
              <Card
                sx={{
                  ...cardstyle,
                  boxShadow: "0 0px 10px 0 rgb(7 152 255 / 9%)",
                  maxWidth: { xs: "100%" },
                  minHeight: { xs: "400px" },
                }}
              >
                <CardActionArea sx={{ backgroundColor: "#fff" }}>
                  <CardContent
                    sx={{
                      px: { xs: "10px", md: "40px" },
                      py: { xs: "15px", md: "30px" },
                      minHeight: { xs: "400px", sm: "250px", md: "430px" },
                    }}
                  >
                    <Typography variant="body2" sx={{ ...heading }}>
                      {value.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        ...subheading,
                        fontFamily: "'Roboto', sans-serif;",
                        lineHeight: "2em",
                      }}
                    >
                      ({value.source})
                    </Typography>
                    <Typography variant="body2" sx={{ ...subheading, mt: 2 }}>
                      {value.comment}
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={0}
                      sx={{
                        mt: "20px",
                        position: "absolute",
                        left: { xs: "5px", md: "40px" },
                        bottom: "30px",
                      }}
                    >
                      {renderStars(parseFloat(value.rating))}
                    </Stack>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Stack>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default CustomerReview;
