import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';

function Breadcrumb(props) {
    const breadcrumbs = props.breadcrumb.map((val, index) => {
        const length = props.breadcrumb.length;
        
        if (index === 0) {
            return (
                <Link underline="none" key={index} color="custom.dark3" href={'/'}>
                    HOME
                </Link>
            );
        } else if (length - 1 === index) {
            // Render the last breadcrumb item as plain text
            return (
                <span key={index} style={{ color: 'custom.lightwheat', textDecoration: 'none' }}>
                    {val.toUpperCase()}
                </span>
            );
        } else {
            return (
                <Link underline="none" key={index} color="custom.dark3" href={`/${val}`}>
                    {val.toUpperCase()}
                </Link>
            );
        }
    });


    return (
        <Stack spacing={2}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" sx={{ fontSize: '12px' }}>
                {breadcrumbs}
            </Breadcrumbs>
        </Stack>
    );
}

export default Breadcrumb;