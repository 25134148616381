
import React from "react";
import { Box, Stack, Avatar, List } from "@mui/material";




const Dashnavbar = () => {
    return (
        <>
            <Box>
                <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ py: "15px"}}>
                    {/* <List>Home </List> */}
                    <Avatar alt="" src="/static/images/avatar/1.jpg" sx={{ border: "1px solid blue", mx: "20px" }} />
                </Stack>
            </Box>
        </>
    )
}

export default Dashnavbar;