import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const date = new Date();
let year = date.getFullYear();

const BottomFooter = () => {
    return (
        <>
            <Stack sx={{ display: "flex", alignItems: "center", }}>

                <Box sx={{ display: "flex", mb: "5px" }} >
                    <Typography variant="body2" color="#bbbbbb" sx={{ cursor: "pointer" }}>
                        <Link to="/privacy-policy" style={{ color: "inherit", textDecoration: "inherit", }}>
                           [ Privacy Policy &nbsp;  |  &nbsp;
                        </Link>
                    </Typography>
                    <Typography variant="body2" color="#bbbbbb" sx={{ cursor: "pointer" }}>
                        <Link to="/terms-of-use" style={{ color: "inherit", textDecoration: "inherit", }}>
                            Terms Of Use ]
                        </Link>
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="body2" color="#bbbbbb" >
                    ©2019 by CITY LUXE REAL ESTATE BROKER. RERA ORN No. 22570
                         {/* {year} */}
                    </Typography>
                </Box>

            </Stack>
            {/* </Box> */}
        </>
    )
}

export default BottomFooter;