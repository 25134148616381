import React,{useEffect, useState} from 'react';
import { Box, Grid, Typography} from '@mui/material';


const AmenitiesFetures = ({ projectDetails }) => {
 const [bannerImage, setBannerImage ] = useState('');
  const gridStyle={
    display:"flex", flexDirection:"column", border:"1px solid white", borderRadius:"10px", justifyContent: "center", alignItems: "center", padding:"10px", width:"100px", height:"100px",
    '&:hover': {
      color: "black",
      backgroundColor:"#e1d3bc"},
      margin:{xs:"5px", sm:"5px", md:"auto" }
  }
  useEffect(() => {
    setBannerImage(projectDetails.project.features_image);
  }, [projectDetails]);
  
  return (
    <Box className="mainBox"
      sx={{
        backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${bannerImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: {xs:"600px",sm:"500px", md:"400px"}, 
        position: 'relative', }}>
      <Box className="secondLayer"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          height:"inherit",
          width:"60%",
          opacity:".5",
        }}
      >
      </Box>
      
      <Box className="thirdLayer"
        sx={{
          position: 'absolute',
          bottom: '20px', 
          left: '50%',
          transform: 'translateX(-50%)',
          textAlign: 'center',
          color: 'white', 
          width:"70%",
          height:"100%",
          paddingTop:"50px"
        }}
        >
       
      <Typography variant="h5" mb={4}>PREMIUM AMENITIES & FEATURES</Typography>

      <Grid container spacing={2} justifyContent="space-around" alignItems="center" mt={2} mb={1} >
    {projectDetails.projectFeatures.slice(0, 4).map((feature, index) => (
      <Grid key={index} sx={gridStyle} item>
        <img src={feature.features_image} alt='icons' style={{width:"2.8vmax", height:"auto"}} />
        <Typography sx={{ fontSize: "12px" }}>
          {feature.features_name}
        </Typography>
      </Grid>
    ))}
  </Grid>
      <Grid container spacing={2} justifyContent="space-around" alignItems="center" mt={2} mb={1} >
        {/* Second row of icons */}
        {projectDetails.projectFeatures.slice(4, 8).map((feature, index) => (
      <Grid key={index} sx={gridStyle} item>
        <img src={feature.features_image} alt='features' style={{ width:"2.8vmax", height:"auto", color: "white !important", fill: "white" }} />
        <Typography sx={{ fontSize: "12px" }}>
          {feature.features_name}
        </Typography>
      </Grid>
    ))}
      </Grid>
    </Box>
      </Box>
  );
};

export default AmenitiesFetures;
