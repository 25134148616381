import React, { useState, useEffect } from "react";
import { cardstyle } from "../theme/theme";
import TopNavbar from "../components/top_navbar";
import ManManu from "../components/main_manu";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import { BsBoxArrowInRight } from "react-icons/bs";
import {
  Box,
  Container,
  Card,
  Typography,
  Stack,
  Button,
  TextField,
} from "@mui/material";
import ProperytList from "../image/propertylist.jpg";
import MainFooter from "../components/main_footer";
import BottomFooter from "../components/botttom_footer";
import Newsletter from "../components/newsletter";
import FooterButton from "../components/footer_button";
import Client from "../components/axios";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputSearchKeywordList from "../components/customHooks/inputSearchKeywordList";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import '../index.css'
const heading = {
  textTransform: "uppercase",
  maxWidth: "900px",
  fontSize: { xs: "27px", md: "60px" },
  fontFamily: "'Roboto', sans-serif",
  fontWeight: "700",
  color: "rgb(255, 255, 255)",
};


let backgroundimg = {
  position: "fixed",
  zIndex: -1,
  top: 0,
  left: 0,
  width: "100vw",
  height: { xs: "60vh", sm: "600px", md: "100vh" },
  backgroundImage: `url("${ProperytList}")`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
};

const textfield = {
  backgroundColor: "#ffffff",
  borderRadius: "0px",
  border: "0.5px solid #e6e6e6",
  mb: "10px",
};

const buttons = {
  m: 0,
  px: "25px",
  backgroundColor: "custom.lightwheat",
  color: "#4c4f54",
  fontSize: "12px",
  borderRadius: "0px",
  "&:hover": { backgroundColor: "#4c4f54", color: "custom.lightwheat" },
};

const ListProperties = (props) => {
  let [name, setName] = useState("");
  let [mobile, setMobile] = useState("");
  let [email, setEmail] = useState("");
  let [query, setQuery] = useState("");
  let [nameError, setNameError] = useState("");
  let [emailError, setEmailError] = useState("");
  let [mobileError, setMobileError] = useState("");
  let [queryError, setQueryError] = useState("");
  const [property_type, setType] = useState("Property Type");
  const [property_category, setCategory] = useState("Property Category");
  const [button_type, setButtonType] = useState("");
  const [formData, setFormData] = useState({
    countryCode: 'ae',
    phone: "",
  });

  function validateForm() {
    let isValid = true;

    if (name.length < 3) {
      setNameError("Name must be 3+ characters.");

      isValid = false;
    } else {
      setNameError("");
    }

    if (formData.phone && !/^\+\d+$/.test(formData.phone)) {
      setMobileError("Mobile number must start with '+' followed by digits.");
      isValid = false;
    } else {
      setMobileError("");
    }

    if (query.split(/\s+/).filter(Boolean).length < 5) {
      setQueryError("Query needs 5+ words.");
      isValid = false;
    } else {
      setQueryError("");
    }

    return isValid;
  }
  const resetFormFields = () => {
    setName("");
    setMobile("");
    setFormData("");
    setEmail("");
    setType("Property Type");
    setCategory("Property Category");
    setQuery("");
  };

  function saveQuery() {
    if (validateForm()) {
      let data = {
        name,
mobile:formData.phone,
        email,
        property_type,
        property_category,
        query,
        button_type,
        page:"List Property",
        page_link:"https://www.pscityluxe.com/list%20property",
        agentEmail:"sales@cityluxedxb.com"


      };
      fetch("/cityluxedxb/saveListPropertyQuery.php", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then((result) => {
        console.warn("result", result);
      });
      toast(
        "Your callback request has been submitted successfully. We will get back to you soon. ",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          style: {
            fontSize: "18px",
            fontWeight: "bold",
          },
        }
      );
    }
  }

  let matches = props.matches;
  let manmanu = {};

  if (matches) {
    if (props.scroll) {
      manmanu = {
        width: "100%",
        py: "0px",
        mt: "-1px",
        bgcolor: "white",
        position: "fixed",
        top: 0,
        zIndex: 100,
        animation: "scrollmanu 0.3s linear",
        "@keyframes scrollmanu": {
          from: {
            py: "10px",
          },
          to: {
            py: "0px",
          },
        },
      };
    } else {
      manmanu = {
        width: "100%",
        py: "10px",
        mt: "-1px",
        bgcolor: "custom.white",
        position: "unset",
        zIndex: 100,
        animation: "scrollmanu2 0.3s linear",
        "@keyframes scrollmanu2": {
          from: {
            py: "0px",
          },
          to: {
            py: "10px",
          },
        },
      };
    }
  } else {
    manmanu = {
      width: "100%",
      bgcolor: "custom.dark4",
    };
  }

  const [list, setlist] = useState(null);
  let [data] = InputSearchKeywordList();
  useEffect(() => {
    window.scrollTo(0, 0);
    setlist(data);
  }, [data]);

  const sendWhatsAppMessage = () => {
    if (validateForm()) {
      const message = `Name: ${name}\nMobile: ${mobile}\nProperty Type: ${property_type}\nProperty Category: ${property_category}\nQuery: ${query}`;
      const phoneNumber = "+971554235695"; // Replace with the desired phone number
      const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
        message
      )}`;

      // Send the form data to the API
      const formData = {
        name,
        mobile:formData.phone,
        email,
        property_type,
        property_category,
        query,
        button_type: "whatsapp",
        page:"List Property",
        page_link:"https://www.pscityluxe.com/list%20property"
      };

      // Call the API to save form data
      Client.post("/cityluxedxb/saveListPropertyQuery.php", formData)
        .then((response) => {
          console.log("API response:", response);
          // After API call is successful, redirect to WhatsApp
          window.location.href = whatsappLink;
        })
        .catch((error) => {
          console.error("API error:", error);
          // Handle API error here
        });
    }
  };
  const validateEmail = (email) => {
    // Regular expression to validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email');
    } else {
      setEmailError('');
    }
  };
  const handleMobileNumberChange = (value, country) => {
    const sanitizedValue = '+' + value.replace(/\D/g, "").slice(0, 15); // Sanitize input to 15 digits
  
    setFormData((prevFormData) => ({
      ...prevFormData,
      countryCode: country.countryCode,
      phone: sanitizedValue,
    }));
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    validateEmail(newEmail);
  };
  document.title = "List Property-city luxe";
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      <Box sx={{ width: "100vw" }}>
        <Box
          sx={{
            position: "relative",
            top: 0,
            left: 0,
            minHeight: {
              xs: "60vh",
              sm: "600px",
              md: "580px",
              lg: "580px",
              xl: "580px",
            },
          }}
        >
          <Box bgcolor={{ xs: "custom.dark", md: "custom.dark" }}>
            <Container maxWidth="lg">
              <TopNavbar matches={matches} />
            </Container>
          </Box>
          <Box sx={manmanu}>
            <Container maxWidth="lg">
              <ManManu scroll={props.scroll} color={"dark2"} />
            </Container>
          </Box>
          <Box sx={{ ...backgroundimg }}></Box>
          <Box
            sx={{ minHeight: "30vh", display: "flex", alignItems: "center" }}
          >
            <Container maxWidth="lg" sx={{}}>
              <div class="PropertyListTextslider">
                <Typography
                  gutterBottom
                  component="div"
                  sx={{ ...heading, lineHeight: "1.5", display: "flex" }}
                >
                  <div>
                    <div></div>
                  </div>
                </Typography>
              </div>
            </Container>
          </Box>
        </Box>

        <Box
          sx={{
            backgroundColor: "#f7f7f7",
            border: "1px solid #fff",
            py: "30px",
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                mb: { xs: "10px", md: "0px" },
                width: { xs: "100%", md: "40%" },
              }}
            >
              <Card
                sx={{
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  mb: "35px",
                  py: { xs: "0px", md: "30px" },
                  width: "100%",
                }}
              >
                <Typography
                  variant="body2"
                  color="#333"
                  marginBottom="10px"
                  fontSize="18px"
                  marginTop="20px"
                  sx={{
                    fontFamily: "'Nunito Sans',sans-serif",
                    fontWeight: "200",
                  }}
                >
                  Unlock the potential of your property! List it hassle-free and
                  experience a smooth journey from start to finish. Through our
                  broad-based marketing opportunities and international
                  presence, we provide maximum exposure for our listings,
                  reaching qualified buyers.
                </Typography>
              </Card>
            </Box>

            <Box sx={{ width: { xs: "100%", md: "55%" } }}>
              <Card sx={{ mb: "70px", p: "30px", ...cardstyle, width: "100%" }}>
                <Stack
                  flexDirection={{ xs: "column", md: "row" }}
                  justifyContent="space-between"
                  alignItems="center"
                  marginBottom="0px"
                >
                  <TextField
                    size="small"
                    type="text"
                    placeholder="Full Name"
                    autoComplete="off"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    sx={{
                      ...textfield,
                      width: { xs: "100%", md: "98%" },
                      marginRight: "10px",
                    }}
                  />

                  {nameError && (
                    <Typography variant="body2" color="error">
                      {" "}
                      {nameError}
                    </Typography>
                  )}
                  <PhoneInput
                    country={formData.countryCode}
                    value={formData.phone}
                    onChange={(value, country) =>
                      handleMobileNumberChange(value, country)            }
                    countryCodeEditable={false}
                    inputProps={{
                      name: "phone",
                      required: true,
                      style: {
                        height: "42px",
                        marginBottom: "12px",
                        width: "100%",
                        borderRadius: "0px",
                        border: "1px solid #e3e0e0",
                      },
                    }}
                  />
                  {mobileError && (
                    <Typography variant="body2" color="error">
                      {mobileError}
                    </Typography>
                  )}
                </Stack>
                <Stack
                  flexDirection={{ xs: "column", md: "row" }}
                  justifyContent="space-between"
                  alignItems="center"
                  marginBottom="0px"
                >
                  <TextField
                    important
                    size="small"
                    type="text"
                    placeholder="Email"
                    autoComplete="off"
                    value={email}
                    onChange={handleEmailChange}
                    sx={{ ...textfield, width: { xs: "100%", md: "100%" } }}
                  />

                  {emailError && (
                    <Typography variant="body2" color="error">
                      {" "}
                      {emailError}
                    </Typography>
                  )}
                </Stack>

                <Stack
                  width="100%"
                  flexDirection={{ xs: "column", md: "row" }}
                  justifyContent="space-between"
                  alignItems="center"
                  marginBottom="0px"
                >
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={property_type}
                    onChange={(e) => setType(e.target.value)}
                    label="Property Type"
                    size="small"
                    sx={{ ...textfield, width: { xs: "100%", md: "49%" } }}
                    className="ProperytList"
                  >
                    <MenuItem value="Property Type" disabled>
                      Property Type
                    </MenuItem>
                    <MenuItem value="rent">Rent</MenuItem>
                    <MenuItem value="Sale">Sale</MenuItem>
                    <MenuItem value="Both">Both</MenuItem>
                  </Select>

                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={property_category}
                    onChange={(e) => setCategory(e.target.value)}
                    label="Property Category"
                    size="small"
                    sx={{ ...textfield, width: { xs: "100%", md: "49%" } }}
                  >
                    <MenuItem value="Property Category" disabled>
                      Property Category
                    </MenuItem>
                    {list &&
                      list.unit_type.map((val, index) => {
                        return (
                          <MenuItem value={val.label} key={index}>
                            {val.label}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Stack>

                <Stack>
                  <TextField
                    size="small"
                    // label="Type Keywords"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    multiline
                    rows={7}
                    maxRows={8}
                    placeholder="Enter query and other details..."
                    sx={{ ...textfield, width: "100%" }}
                  />
                  {queryError && (
                    <Typography color="error">{queryError}</Typography>
                  )}
                </Stack>

                <Stack
                  flexDirection={{ xs: "column", sm: "row" }}
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  justifyContent="space-between"
                  marginBottom="0px"
                >
                  <Button
                    sx={{ ...buttons, my: { xs: "10px", sm: "0px" } }}
                    startIcon={<CallIcon />}
                    onClick={() => {
                      saveQuery();
                      resetFormFields(); // Reset form fields after clicking "Get A Call Back"
                    }}
                  >
                    Get A Call Back
                  </Button>

                  <Button
                    sx={{ ...buttons, my: { xs: "10px", sm: "0px" } }}
                    onClick={() => {
                      sendWhatsAppMessage();
                      resetFormFields();
                    }}
                    startIcon={<WhatsAppIcon />}
                  >
                    Whatsapp
                  </Button>
                  <Button
                    sx={{ ...buttons, my: { xs: "10px", sm: "0px" } }}
                    onClick={() => {
                      saveQuery();
                      resetFormFields();
                    }}
                    startIcon={<BsBoxArrowInRight />}
                  >
                    Submit Query
                  </Button>
                </Stack>
              </Card>
            </Box>
          </Container>
        </Box>

        {/* main foooter  */}
        <Box sx={{ pt: "50px", backgroundColor: "#2c2c2c" }}>
          <Container maxWidth="lg">
            <Box>
              <MainFooter />
            </Box>
          </Container>
          <Box
            sx={{ borderTop: "0.5px solid #666666", py: "20px", mt: "25px" }}
          >
            {/* <Box sx={{ border: "1px solid red" }}> */}
            <Container>
              <Newsletter />
            </Container>
            {/* </Box> */}
          </Box>
        </Box>
        {/* bottom footer  */}
        <Box sx={{ backgroundColor: "#272727", py: "15px" }}>
          <Container maxWidth="lg">
            <BottomFooter />
          </Container>
        </Box>
        {/* footer top scroll button  */}
        <FooterButton scroll={props.scroll} />
      </Box>
    </>
  );
};

export default ListProperties;
