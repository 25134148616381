import React, { useState, useEffect } from "react";
import { cardstyle } from "../theme/theme";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import Slider from "react-slick";

const AgentReview = (props) => {
    const { agentName } = props;
      const [reviews, setReviews] = useState([]);
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await fetch(
          "https://cityluxedxb.com/cityluxedxb/all_reviews.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          const data = await response.json();
          console.error(`API Error ${data.errorcode}: ${data.status}`);
          return;
        }

        const data = await response.json();
        setReviews(data.reviewslist);
        // console.warn("Dynamic reviews:", reviews);
      } catch (error) {
        console.error("Error fetching reviews:", error.message);
      }
    };

    fetchReviews();
  }, []);

  const renderStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    for (let i = 0; i < fullStars; i++) {
      stars.push(<StarIcon key={i} fontSize="small" color="warning" />);
    }

    if (hasHalfStar) {
      stars.push(
        <StarHalfIcon key={fullStars} fontSize="small" color="warning" />
      );
    }

    return stars;
  };
  const heading = {
    textTransform: "capitalize",
    fontWeight: "600",
    fontFamily: "'Roboto', sans-serif;",
    fontSize: "16px",
    color: "#222",
    lineHeight: "1.65em",
  };
  const subheading = {
    fontSize: "14px",
    color: "#5c727d",
    fontWeight: "600",
    fontFamily: "'Nunito Sans', sans-serif",
    backgroundColor: "#fff",
    lineHeight: "1.65em",
  };

 

  var settings = {
    infinite: true,
    slidesToShow: 2,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    cssEase: "linear",
  };
  return (
    <>
      <Slider {...settings}>
        {reviews
          .filter(
            (value) =>
              agentName === value.agentName &&
              (value.showPage === "Team Page" || value.showPage === "Home Page, Team Page")
          )
          .map((value, index) => (
            <div key={index} style={{ border: "1px solid red" }}>
              <Stack sx={{ mb: "20px", mx: "5px" }}>
                <Card
                  sx={{
                    ...cardstyle,
                    boxShadow: "0 0px 10px 0 rgb(7 152 255 / 9%)",
                    maxWidth: { xs: "100%" },
                    minHeight: { xs: "400px" },
                  }}
                >
                  <CardActionArea sx={{ backgroundColor: "#fff" }}>
                    <CardContent
                      sx={{
                        px: { xs: "10px", md: "40px" },
                        py: { xs: "15px", md: "30px" },
                        minHeight: { xs: "400px", sm: "250px", md: "430px" },
                      }}
                    >
                      <Typography variant="body2" sx={{ ...heading }}>
                        {value.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          ...subheading,
                          fontFamily: "'Roboto', sans-serif;",
                          lineHeight: "2em",
                        }}
                      >
                        ({value.source})
                      </Typography>
                      <Typography variant="body2" sx={{ ...subheading, mt: 2 }}>
                        {value.comment}
                      </Typography>
                      <Stack
                        direction="row"
                        spacing={0}
                        sx={{
                          mt: "20px",
                          position: "absolute",
                          left: { xs: "5px", md: "40px" },
                          bottom: "30px",
                        }}
                      >
                        {renderStars(parseFloat(value.rating))}
                      </Stack>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Stack>
            </div>
          ))}
  
        {/* Check if there are no reviews for the agent */}
        {reviews
          .filter(
            (value) =>
              agentName === value.agentName
          )
          .length === 0 && (
          <Typography
            key="no-reviews"
            variant="body2"
            sx={{ ...subheading, mt: 2, textAlign: "center" }}
          >
            No reviews yet for the agent.
          </Typography>
        )}
      </Slider>
    </>
  );
  
};

export default AgentReview;
