import React, { useEffect, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Box,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Icon,
} from "@mui/material";
import Client from "../../../../components/axios";
import EditIcon from "@mui/icons-material/Edit";
import "./property_type_table.css";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import TextField from "@mui/material/TextField";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

export default function DataTable() {
  const [property, setProperty] = useState([]);
  const [id, setid] = useState("");
  const [loading, setLoading] = useState(false);

  //   const [typeProperty, setTypeProperty] = useState("");
  const [PropertyRefNo, setPropertyRefNo] = useState("");

  const [selectedProperties, setSelectedProperties] = useState([]);

  const [deleteOpen, setDeleteOpen] = useState(false);
  const deleteHandleClose = () => {
    setDeleteOpen(false);
  };

  const [updateOpen, setUpdateOpen] = useState(false);
  const upadateHandleClose = () => {
    // setTypeProperty("");
    setPropertyRefNo("");
    setUpdateOpen(false);
  };

  const [addOpen, setAddOpen] = useState(false);
  const addHandleClose = () => {
    // setTypeProperty("");
    setPropertyRefNo("");
    setAddOpen(false);
  };

  const handlePropertyRefNo = (event) => {
    if (event.target.value.length < 30) {
      setPropertyRefNo(event.target.value);
    } else {
      alert("Property Refrence No can't be longer");
    }
  };

  const handlePropertyType = (event) => {
    // Update the selectedProperties state to handle multiple selection
    const selectedValues = event.target.value;
    if (selectedValues.length <= 3) {
      setSelectedProperties(selectedValues);
    } else {
      alert("You can't select more than 3 Property Types");
    }
  };

  const GetPropetylist = () => {
    Client.post("/cityluxedxb/get_property_type.php", { property_id: id })
      .then((response) => {
        if (response.data.errorcode == 200) {
          // console.log(response.data);
          setProperty(response.data.propertieslist);
        } else {
          console.log(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetPropetylist();
  }, []);

  const deleteEvent = (idclick) => {
    setid(idclick);
    setDeleteOpen(true);
  };

  const editEvent = (idclick) => {
    // console.log(idclick);
    setid(idclick);
    let item = property.find((item) => item.id == idclick);
    // console.log(item);
    // setTypeProperty(item.property_type);
    setPropertyRefNo(item.property_ref_no);
    setUpdateOpen(true);
  };

  const Delete = () => {
    // console.log(id);
    setLoading(true);
    Client.post("/cityluxedxb/delete_property_type.php", {
      property_id: id,
    })
      .then((response) => {
        if (response.data.errorcode == 200) {
          // console.log(response.data);
          GetPropetylist();
          setLoading(false);
          setDeleteOpen(false);
        } else {
          console.log(response.data);
          setLoading(false);
          setDeleteOpen(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setDeleteOpen(false);
      });
  };

  const Update = () => {
    if (PropertyRefNo === "") {
      alert("Please Enter Refrence No");
    } else if (selectedProperties.length === 0) {
      alert("Please Enter Property Type");
    } else {
      setLoading(true);
      Client.put("/cityluxedxb/update_property_type.php", {
        property_id: id,
        property_refrence_no: PropertyRefNo,
        property_type: selectedProperties,
      })
        .then((response) => {
          if (response.data.errorcode === 200) {
            console.log(response.data);
            GetPropetylist();
            setProperty((prevProperty) =>
              prevProperty.map((item) =>
                item.id === id
                  ? {
                      ...item,
                      property_type: selectedProperties,
                    }
                  : item
              )
            );
            setSelectedProperties((prevSelected) =>
              prevSelected.map((selectedProp) =>
                selectedProp.id === id
                  ? {
                      ...selectedProp,
                      property_type: selectedProperties,
                    }
                  : selectedProp
              )
            );
            // setTypeProperty("");
            setPropertyRefNo("");
            setLoading(false);
            setUpdateOpen(false);
          } else {
            console.log(response.data);
            setLoading(false);
            setUpdateOpen(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setUpdateOpen(false);
        });
    }
  };

  const button = {
    color: "custom.white",
    bgcolor: "custom.lightwheat",
    transition: "0.3s",
    fontWeight: "500",
    border: "1px solid #ffffff",
    px: "40px",
    borderRadius: "0px",
    fontSize: { xs: "9px", md: "13px" },
    py: "10px ",
    "&:hover": {
      bgcolor: "transparent",
      color: "custom.lightwheat",
      border: "1px solid #e1d3bc",
    },
  };

  return (
    <>
      <Box className="main_section_type_properties">
        <Box
          sx={{
            margin: "15px 0px",
            padding: "0px 20px",
            backgroundColor: "#fff",
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Stack sx={{ paddingLeft: "20px", width: "75%" }}></Stack>
          <Stack
            sx={{
              paddingLeft: "20px",
              width: "15%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          ></Stack>
          <Stack
            sx={{
              paddingLeft: "20px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "10%",
            }}
          >
            {/* <Tooltip title="Add Property Type">
                            <IconButton onClick={() => setAddOpen('true')}> <AddIcon sx={{ cursor: 'pointer' }} /></IconButton>
                        </Tooltip> */}
            <Tooltip title="Download property list">
              <IconButton>
                {" "}
                <FileDownloadOutlinedIcon sx={{ cursor: "pointer" }} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Box>

        <div className="table_box" style={{ height: "80vh" }}>
          <table className="table">
            <tr className="table_head">
              <th style={{ width: "5%" }}>SR.NO</th>
              <th style={{ width: "35%" }}>Property Ref No</th>
              <th style={{ width: "40%" }}>Property Type</th>
              <th style={{ width: "10%" }}>Edit</th>
              <th style={{ width: "10%" }}>Delete</th>
            </tr>

            {property.length > 0 ? (
              property.map((val, index) => {
                // Map through the selected property types array for each property
                const propertyTypes = selectedProperties
                  .filter((selectedProp) => selectedProp.id === val.id)
                  .map((selectedProp) => selectedProp.property_type);

                let count = index + 1;
                // if (minvalue == 1) {
                //     count = minvalue + index;
                // } else {
                //     count = minvalue + (index + 1);
                // }

                return (
                  <>
                    <tr className="table_body" >
                      <td>{count}</td>
                      <td>{val.property_ref_no}</td>
                      <td key={val.id}>{selectedProperties}</td>

                      <td className="icon">
                        <Tooltip title="Edit">
                          <IconButton>
                            <EditIcon
                              fontSize="medium"
                              onClick={() => editEvent(val.id)}
                            />
                          </IconButton>
                        </Tooltip>
                      </td>
                      <td className="icon">
                        <Tooltip title="Delete">
                          <IconButton>
                            <DeleteForeverIcon
                              fontSize="medium"
                              sx={{ color: "red" }}
                              onClick={() => deleteEvent(val.id)}
                            />
                          </IconButton>
                        </Tooltip>
                      </td>
                    </tr>
                  </>
                );
              })
            ) : (
              <Box>
                <Stack>Loding.....</Stack>
              </Box>
            )}
          </table>
        </div>
      </Box>
      {/* code for dialog box of delete properties  */}
      <div>
        <Dialog
          open={deleteOpen}
          onClose={deleteHandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure want to delete?"}
          </DialogTitle>
          {/* <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Let Google help apps determine location. This means sending anonymous
                            location data to Google, even when no apps are running.
                        </DialogContentText>
                    </DialogContent> */}
          <DialogActions
            sx={{ display: "flex", justifyContent: "space-around" }}
          >
            <Button onClick={deleteHandleClose} sx={{ ...button }}>
              Cancel
            </Button>
            {/* <Button onClick={Delete} sx={{ color: 'red' }} autoFocus>
                            Delete
                        </Button> */}
            <LoadingButton
              sx={button}
              onClick={Delete}
              loading={loading}
              loadingPosition="end"
            >
              DELETE
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </div>
      {/* code for dialog box update Properties */}
      <div>
        <Dialog
          open={updateOpen}
          onClose={upadateHandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Update Property Type"}
          </DialogTitle>
          <DialogContent sx={{ p: 3, minWidth: "25vw" }}>
            <TextField
              id="outlined-basic"
              label="Property Ref No"
              value={PropertyRefNo}
              onChange={handlePropertyRefNo}
              variant="standard"
              sx={{ mt: "10px", width: "100%" }}
            />

            <FormControl
              variant="standard"
              sx={{ mt: "10px", minWidth: "100%" }}
            >
              <InputLabel id="demo-multiple-select-standard-label">
                Property Type
              </InputLabel>
              <Select
                labelId="demo-multiple-select-standard-label"
                id="demo-multiple-select-standard"
                multiple // Enable multiple selection
                value={selectedProperties} // Use the selectedProperties array as the value
                onChange={handlePropertyType} // Use the handlePropertyType function for handling changes
                label="Property Type"
              >
                {/* <MenuItem value="">
                                    <em>None</em>
                                </MenuItem> */}
                <MenuItem value={"Premium"}>Premium</MenuItem>
                <MenuItem value={"Premium_Top Three"}>
                  Premium_Top Three
                </MenuItem>
                <MenuItem value={"Super Hot"}>Super Hot</MenuItem>
                <MenuItem value={"Super Hot_Top Three"}>
                  Super Hot_Top Three
                </MenuItem>
                <MenuItem value={"Best Off Plan"}>Best Off Plan</MenuItem>
                <MenuItem value={"Best Off Plan_Top Three"}>
                  Best Off Plan_Top Three
                </MenuItem>
                <MenuItem value={"Featured"}>Featured</MenuItem>
                <MenuItem value={"Featured_Top Three"}>
                  Featured_Top Three
                </MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={upadateHandleClose} sx={{ ...button }}>
              Cancel
            </Button>
            {/* <Button onClick={Update} sx={{ color: 'red' }} autoFocus>
                            Update
                        </Button> */}
            <LoadingButton
              sx={button}
              onClick={Update}
              loading={loading}
              loadingPosition="end"
            >
              UPDATE
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </div>
      {/* code for dialog box add Properties */}
      <div>
        <Dialog
          open={addOpen}
          onClose={addHandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Add New Property Type"}
          </DialogTitle>
          <DialogContent sx={{ p: 3, minWidth: "25vw" }}>
            <TextField
              id="outlined-basic"
              label="Property Ref No"
              value={PropertyRefNo}
              onChange={handlePropertyRefNo}
              variant="standard"
              sx={{ mt: "10px", width: "100%" }}
            />

            <FormControl
              variant="standard"
              sx={{ mt: "10px", minWidth: "100%" }}
            >
              <InputLabel id="demo-simple-select-standard-label">
                Property Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={selectedProperties}
                onChange={handlePropertyType}
                label="Property Type"
              >
                <MenuItem value={"Premium"}>Premium</MenuItem>
                <MenuItem value={"Premium"}>Premium_Top Three</MenuItem>
                <MenuItem value={"Super Hot"}>Super Hot</MenuItem>
                <MenuItem value={"Super Hot"}>Super Hot_Top Three</MenuItem>
                <MenuItem value={"Best Off Plan"}>Best Off Plan</MenuItem>
                <MenuItem value={"Best Off Plan"}>
                  Best Off Plan_Top Three
                </MenuItem>
                <MenuItem value={"Featured"}>Featured</MenuItem>
                <MenuItem value={"Featured"}>Featured_Top Three</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={addHandleClose} sx={{ ...button }}>
              Cancel
            </Button>
            {/* <Button onClick={add} sx={{ color: 'red' }} autoFocus>
                            Add
                        </Button> */}
            <LoadingButton
              sx={button}
              // onClick={add}
              loading={loading}
              loadingPosition="end"
            >
              ADD
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
