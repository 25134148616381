
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";



const Heading = (props) => {
    return (
        <>
            <Box>
                <Typography sx={{ textTransform: 'uppercase', color: '#222', fontSize: { xs: "25px", sm: "30px", md: '34px' }, fontWeight: '700', fontFamily: "'Roboto', sans-serif", lineHeight: '1.1em', textAlign: { xs: 'left', md: 'left' } }}>{props.title}</Typography>
            </Box>

        </>
    )
}

export default Heading;

