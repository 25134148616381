import React, { useContext } from 'react';
import { BlogContext } from './BlogContext';
import { Box, Grid, Avatar, Typography, Paper } from '@mui/material';

function RecentBlogPosts() {
  const { blogs } = useContext(BlogContext);

  const sortedBlogs = blogs
    .slice()
    .sort((a, b) => new Date(b.posted_on).getTime() - new Date(a.posted_on).getTime()) 
    .slice(0, 6); 
  return (
    <Paper elevation={3} sx={{marginBottom:"25px"}}>
      <Typography variant="h6" sx={{ borderBottom: ".7px solid #00000018", marginTop: "25px", padding: "16px" }}>
        RECENT BLOG POSTS
      </Typography>
      <Box sx={{ marginTop: '25px', padding: '16px' }}>
        <Grid container spacing={2}>
          {sortedBlogs.map((post) => (
            <Grid item xs={12} sm={12} key={post.id}>
              <Box display="flex" alignItems="center">
                <Avatar
                  src={post.image1}
                  alt={post.title}
                  variant="rounded"
                  sx={{ width:10, height: 10, marginRight: 2 }}
                  style={{width:"75px", height:"75px"}}
                />
                <div>
                  <Typography variant="subtitle1">
                    {post.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {new Date(post.posted_on).toLocaleDateString()} 
                  </Typography>
                </div>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Paper>
  );
}

export default RecentBlogPosts;
