import React, { useEffect, useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, Stack, Select, MenuItem } from '@mui/material';
import Client from '../../../../components/axios';
import EditIcon from '@mui/icons-material/Edit';
import './list_of_projects.css';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import LoadingButton from '@mui/lab/LoadingButton';

import FullScreenDialog from './add_projects';

export default function DataTable() {
    const [property, setProperty] = useState([]);
    const [id, setid] = useState('');
    const [loading, setLoading] = useState(false);


    const [deleteOpen, setDeleteOpen] = useState(false);
    const deleteEvent = (idclick) => {
        setid(idclick);
        setDeleteOpen(true);
    }

    const Delete = () => {
        // console.log(id);
        setLoading(true);
        console.log('are you sure want to delete');
        // Client.post('/cityluxedxb/delete_property_type.php', {
        //     "property_id": id
        // })
        //     .then((response) => {
        //         if (response.data.errorcode == 200) {
        //             // console.log(response.data);
        //             GetPropetylist();
        //             setLoading(false);
        //             setDeleteOpen(false);
        //         } else {
        //             console.log(response.data);
        //             setLoading(false);
        //             setDeleteOpen(false);
        //         }
        //     })
        //     .catch(err => {
        //         console.log(err);
        //         setLoading(false);
        //         setDeleteOpen(false);
        //     });
    }

    const deleteHandleClose = () => {
        setDeleteOpen(false);
    };

    const GetPropetylist = () => {
        Client.post('/cityluxedxb/get_all_projects.php')
            .then((response) => {
                if (response.data.errorcode == 200) {
                    // console.log(response.data);
                    setProperty(response.data.propertieslist);

                } else {
                    console.log(response.data);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    useEffect(() => {
        GetPropetylist();
    }, [])


    // const Update = () => {
    //     if (PropertyRefNo == '') {
    //         alert('Please Enter Refrence No');
    //     } else if (typeProperty == '') {
    //         alert('Please Enter Property Type');
    //     } else {
    //         setLoading(true);
    //         Client.put('/cityluxedxb/update_property_type.php', {
    //             "property_id": id,
    //             "property_refrence_no": PropertyRefNo,
    //             "property_type": typeProperty
    //         })
    //             .then((response) => {
    //                 if (response.data.errorcode == 200) {
    //                     // console.log(response.data);
    //                     GetPropetylist();
    //                     setTypeProperty('');
    //                     setPropertyRefNo('');
    //                     setLoading(false);
    //                     setUpdateOpen(false);
    //                 } else {
    //                     console.log(response.data);
    //                     setLoading(false);
    //                     setUpdateOpen(false);
    //                 }
    //             })
    //             .catch(err => {
    //                 console.log(err);
    //                 setLoading(false);
    //                 setUpdateOpen(false);
    //             });
    //     }
    // }


    // const add = () => {
    //     if (PropertyRefNo == '') {
    //         alert('Please Enter Refrence No');
    //     } else if (typeProperty == '') {
    //         alert('Please Enter Property Type');
    //     } else {

    //         setLoading(true);
    //         Client.post('/cityluxedxb/add_property_type.php', {
    //             "property_refrence_no": PropertyRefNo,
    //             "property_type": typeProperty
    //         })
    //             .then((response) => {
    //                 if (response.data.errorcode == 200) {
    //                     // console.log(response.data);

    //                     GetPropetylist();
    //                     setTypeProperty('');
    //                     setPropertyRefNo('');

    //                     setLoading(false);
    //                     setAddOpen(false);

    //                 } else {
    //                     console.log(response.data);
    //                     setLoading(false);
    //                     setAddOpen(false);
    //                 }
    //             })
    //             .catch(err => {
    //                 console.log(err);
    //                 setLoading(false);
    //                 setAddOpen(false);
    //             });
    //     }
    // }


    const button = {
        color: 'custom.white',
        bgcolor: 'custom.lightwheat',
        transition: '0.3s',
        fontWeight: '500',
        border: '1px solid #ffffff',
        px: '40px',
        borderRadius: '0px',
        fontSize: { xs: '9px', md: '13px' },
        py: '10px ',
        '&:hover': {
            bgcolor: 'transparent',
            color: 'custom.lightwheat',
            border: "1px solid #e1d3bc"
        }

    }

    // states for table 
    const [perpage, setperpage] = useState(10);
    const [minvalue, setminvalue] = useState(1);
    const [maxvalue, setmaxvalue] = useState(10);
    const [showrecord, setshowrecord] = useState([]);

    useEffect(() => {
        if (minvalue == 1) {
            setshowrecord(property.slice(minvalue - 1, maxvalue));
        } else {
            setshowrecord(property.slice(minvalue, maxvalue));
        }

    }, [property]);

    const perPageFun = (event) => {
        setperpage(event.target.value);
        setminvalue(1);
        setmaxvalue(event.target.value);
        setshowrecord(property.slice((0), (event.target.value)));

    }

    const increment = () => {
        if (property.length > 10) {
            if (maxvalue !== property.length) {
                if (maxvalue + perpage <= property.length) {
                    setminvalue(maxvalue);
                    setmaxvalue(maxvalue + perpage);
                    setshowrecord(property.slice(maxvalue, maxvalue + perpage));
                } else {
                    setminvalue(maxvalue);
                    setmaxvalue(property.length);
                    setshowrecord(property.slice(maxvalue, maxvalue + perpage));
                }
            }
        }
    }

    const decrement = () => {
        if (minvalue !== 1) {
            if (minvalue - perpage >= 1) {
                setminvalue(minvalue - perpage);
                setmaxvalue(minvalue);
                setshowrecord(property.slice(minvalue - perpage, minvalue));

            } else {
                setminvalue((minvalue - perpage) + 1);
                setmaxvalue(minvalue);
                setshowrecord(property.slice(minvalue - perpage, minvalue));
            }
        }
    }

    return (
        <>

            <Box className='main_section_type_properties'>
                <Box sx={{ margin: "15px 0px", padding: "0px 20px", backgroundColor: "#fff", width: "100%", display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>

                    <Stack sx={{ paddingLeft: "20px", width: "75%" }}>

                    </Stack>

                    <Stack sx={{ paddingLeft: "20px", width: "15%", display: 'flex', flexDirection: "row", justifyContent: 'space-around' }}>

                    </Stack>

                    <Stack sx={{ paddingLeft: "20px", display: "flex", flexDirection: 'row', justifyContent: 'flex-end', width: "10%" }}>
                        <Tooltip title="Add Project">
                            <FullScreenDialog />
                        </Tooltip>
                    </Stack>

                </Box>

                <div className='table_box'>
                    <table className="table">
                        <tr className='table_head'>
                            <th style={{ width: "5%" }}>SR.NO</th>
                            <th style={{ width: "25%" }}>Property Ref No</th>
                            <th style={{ width: "25%" }}>Category</th>
                            <th style={{ width: "25%" }}>Property Type</th>
                            <th style={{ width: "10%" }}>Edit</th>
                            <th style={{ width: "10%" }}>Delete</th>
                        </tr>

                        {showrecord.length > 0 ?

                            showrecord.map((val, index) => {
                                let count = 0;
                                if (minvalue == 1) {
                                    count = minvalue + index;
                                } else {
                                    count = minvalue + (index + 1);
                                }

                                return <>
                                    <tr className='table_body'>
                                        <td>{count}</td>
                                        <td>{val.Unit_Reference_No}</td>
                                        <td>{val.Unit_Type}</td>
                                        <td>{val.Ad_Type}</td>
                                        <td className='icon'>
                                            <Tooltip title="Edit">
                                                <IconButton>
                                                    <EditIcon fontSize='medium' onClick={() => alert(val.id)} />
                                                </IconButton>
                                            </Tooltip>
                                        </td>
                                        <td className='icon'>
                                            <Tooltip title="Delete">
                                                <IconButton>
                                                    <DeleteForeverIcon fontSize='medium' sx={{ color: 'red' }} onClick={() => deleteEvent(val.id)} />
                                                </IconButton>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                </>

                            })

                            :

                            <Box>
                                <Stack>
                                    Loding.....
                                </Stack>
                            </Box>

                        }

                    </table>
                </div>

                <Box sx={{ margin: "15px 0px", padding: "0px 20px", backgroundColor: "#fff", width: "100%", display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    {/* <Stack sx={{ paddingLeft: "20px", width: "50%" }}>

                    </Stack> */}
                    <Stack sx={{ paddingLeft: "20px", width: "25%", display: 'flex', justifyContent: "flex-end", flexDirection: "row", alignItems: 'center' }}>
                        <span> Rows per page:</span>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={perpage}
                            onChange={(e) => perPageFun(e)}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={15}>15</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                        </Select>
                    </Stack>
                    <Stack sx={{ paddingLeft: "20px", width: "150px", display: 'flex', flexDirection: "row", justifyContent: 'space-around' }}>
                        {/* <p>1–10</p>
                        <p>of</p>
                        <p>15</p> */}
                        <span>{minvalue}-{maxvalue} </span> <span> of </span> <span> {property.length} </span>

                    </Stack>
                    <Stack sx={{ paddingLeft: "20px", display: "flex", flexDirection: 'row', justifyContent: 'space-between', width: "100px" }}>

                        <IconButton onClick={() => decrement()}> <KeyboardArrowLeftIcon sx={{ cursor: 'pointer' }} /></IconButton>

                        <IconButton onClick={() => increment()}>  <KeyboardArrowRightIcon sx={{ cursor: 'pointer' }} /></IconButton>
                    </Stack>
                </Box>
            </Box>

            {/* code for dialog box of delete properties  */}

            <div>
                <Dialog
                    open={deleteOpen}
                    onClose={deleteHandleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Are you sure want to delete?"}
                    </DialogTitle>

                    <DialogActions sx={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Button onClick={deleteHandleClose} sx={{ ...button }}>Cancel</Button>

                        <LoadingButton
                            sx={button}
                            onClick={Delete}
                            loading={loading}
                            loadingPosition="end"
                        >
                            DELETE
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </div>


        </>

    );
}