import React, { createContext, useState, useContext } from 'react';

export const CommunityContext = createContext();

export const CommunityProvider = ({ children }) => {
  const [communityName, setCommunityName] = useState('');
  const [filterType, setFilterType] = useState("");
  const setCommunityInfo = (name, type) => {
    setCommunityName(name);
    setFilterType(type);
  };
  return (
    <CommunityContext.Provider value={{ communityName, filterType, setCommunityInfo }}>
      {children}
    </CommunityContext.Provider>
  );
};
export const useCommunityContext = () => useContext(CommunityContext);
