
import React, {useEffect } from "react";
import TopNavbar from "../components/top_navbar";
import ManManu from "../components/main_manu";
import { Box, Container, Typography} from "@mui/material";
import Project_img from '../uploads/2022/07/100720221657436249-768x523.jpeg';
import Breadcrumb from "../components/bread_crumb";
import MainFooter from "../components/main_footer";
import BottomFooter from "../components/botttom_footer";
import Newsletter from "../components/newsletter";
import FooterButton from "../components/footer_button";

const TermOfUse = (props) => {
    let matches = props.matches;
    let manmanu = {}
    if (matches) {
        if (props.scroll) {
            manmanu = {
                width: '100%',
                py: '0px',
                mt: '-1px',
                bgcolor: 'white',
                position: 'fixed',
                top: 0,
                zIndex: 100,
                animation: "scrollmanu 0.3s linear",
                "@keyframes scrollmanu": {
                    from: {
                        py: '10px',
                    },
                    to: {
                        py: '0px',

                    },
                }
            }
        } else {
            manmanu = {
                width: '100%',
                py: '10px',
                mt: '-1px',
                bgcolor: 'custom.white',
                position: 'unset',
                zIndex: 100,
                animation: "scrollmanu2 0.3s linear",
                "@keyframes scrollmanu2": {
                    from: {
                        py: '0px',
                    },
                    to: {
                        py: '10px',
                    },
                }
            }
        }

    } else {
        manmanu = {
            width: '100%',
            bgcolor: 'custom.dark4'
        }
    }

    let backgroundimg = {
        position: 'fixed',
        zIndex: -1,
        top: 0,
        left: 0,
        width: '100%',
        height: { xs: "60vh", md: '100vh' },
        backgroundImage: `url("${Project_img}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'

    }

    const heading = {
        fontWeight: '700',
        fontSize: '28px',
        color: '#222',
        fontFamily: "'Nunito Sans', sans-serif !important",
        my: '10px'
    }

    const subheading = {
        fontWeight: '600',
        fontSize: '14px',
        color: '#5c727d',
        fontFamily: "'Nunito Sans', sans-serif !important",
        my: '15px',
        display: "block"
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'terms-city luxe'; 
    },[]);

    return (
        <>

            <Box sx={{ position: 'relative', top: 0, left: 0 }}>
                <Box bgcolor={{ xs: 'custom.dark', md: 'custom.dark' }}>
                    <Container maxWidth="lg">
                        <TopNavbar matches={matches} />
                    </Container>
                </Box>
                <Box sx={manmanu} >
                    <Container maxWidth="lg">
                        <ManManu scroll={props.scroll} color={'dark2'} />
                    </Container>
                </Box>
                <Box sx={backgroundimg}>

                </Box>
            </Box>

            <Box sx={{ backgroundColor: '#f7f7f7', border: "1px solid #fff" }}>

                <Container maxWidth="lg" sx={{ pt: '15px' }}>
                    <Breadcrumb breadcrumb={['Home', 'Privacy Policy']} />
                </Container>

                <Container maxWidth="lg" sx={{ my: '27px' }}>
                <Typography gutterBottom variant="h6" component="div" sx={{ ...heading, fontSize: "34px" }}  >
                        Terms of Use
                    </Typography>
                    <Box>

                        <Typography component="body2" sx={{ ...subheading }}  >
                            This Privacy Policy governs the manner in which Real Estate WordPress Theme collects, uses, maintains and discloses information collected from users (each, a “User”) of the website (“Site”). This privacy policy applies to the Site and all products and services offered by Real Estate WordPress Theme.
                        </Typography>
                        <Typography component="body2" sx={{ ...subheading }}  >
                            Personal identification information
                        </Typography>
                        <Typography component="body2" sx={{ ...subheading }}  >
                            We may collect personal identification information from Users in a variety of ways in connection with activities, services, features or resources we make available on our Site.. Users may visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site related activities.
                        </Typography>
                        <Typography component="body2" sx={{ ...subheading }}  >
                            Non-personal identification information
                        </Typography>
                        <Typography component="body2" sx={{ ...subheading }}  >
                            We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection to our Site, such as the operating system and the Internet service providers utilized and other similar information.
                        </Typography>
                        <Typography component="body2" sx={{ ...subheading }}  >
                            Web browser cookies
                        </Typography>
                        <Typography component="body2" sx={{ ...subheading }}  >
                            Our Site may use “cookies” to enhance User experience. User’s web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly.
                        </Typography>
                        <Typography component="body2" sx={{ ...subheading }}  >
                            How we protect your information
                        </Typography>
                        <Typography component="body2" sx={{ ...subheading }}  >
                            Sharing your personal information
                        </Typography>
                        <Typography component="body2" sx={{ ...subheading }}  >
                            We do not sell, trade, or rent Users personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above.
                        </Typography>
                        <Typography component="body2" sx={{ ...subheading }}  >
                            Changes to this privacy policy
                        </Typography>
                        <Typography component="body2" sx={{ ...subheading }}  >
                            Real Estate WordPress Theme has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.
                        </Typography>
                        <Typography component="body2" sx={{ ...subheading }}  >
                            Your acceptance of these terms
                        </Typography>
                        <Typography component="body2" sx={{ ...subheading }}  >
                            By using this Site, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.
                        </Typography>


                    </Box>
                </Container>
            </Box >

            {/* main foooter  */}
            <Box sx={{ pt: '50px',  backgroundColor: '#2c2c2c', }}>
                <Container maxWidth="lg">
                    <Box>
                        <MainFooter />
                    </Box>
                </Container>
                <Box sx={{ borderTop: "0.5px solid #666666", py: "20px", mt: "25px",}}>
                    <Container>
                        <Newsletter />
                    </Container>
                </Box>
            </Box>
            {/* bottom footer  */}
            < Box sx={{ backgroundColor: '#272727', py: '15px' }}>
                <Container maxWidth="lg">
                    <BottomFooter />
                </Container>
            </Box >
            {/* footer top scroll button  */}
            < FooterButton scroll={props.scroll} />
        </>

    )
};

export default TermOfUse;
