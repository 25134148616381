import React,{useEffect, useState} from 'react'
import { Box, Grid, Typography,Button, Modal, TextField, Snackbar, Alert} from '@mui/material';
import { buttonStyle } from "../../theme/theme";
import BookAUnit from './BookAUnit';
import ReactHtmlParser from 'react-html-parser';
import CloseIcon from '@mui/icons-material/Close';
import PhoneInput from "react-phone-input-2";
const VideoSection = ({ projectDetails }) => {
  const [open, setOpen] = useState(false);
const [ projectTitle, setProjectTitle ] = useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const paraStyle = {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: "500",
    color: "#5c727d",
    lineHeight: "1.6em",
    wordBreak: "word-break",
    paddingRight: "5px", letterSpacing: ".5px"
  }
  
  useEffect(() => {
   
    setProjectTitle(projectDetails.project.title);
  }, [projectDetails, projectTitle]);
  

  return (
   <>
   <Box sx={{ width: '100%', maxWidth: '1200px', marginTop: '20px' }}>
        <Grid container spacing={2}>
          {/* First column with text and button */}
          <Grid item xs={12} md={6}>
            <Box sx={{ p: 2 }}>
            <Typography variant="body1" sx={paraStyle} >
                {ReactHtmlParser(projectDetails.project.description2)}          
                    </Typography>
      <BookAUnit open={open} handleClose={handleClose} projectTitle={projectTitle} />                         
          </Box>
          </Grid>
          {/* Second column with video player */}
          <Grid item sm={12} md={6} >
            {/* <video src={projectDetails.project.video} autoPlay loop muted width="100%" height="250" controls></video> */}
            <img src={projectDetails.project.img2} alt="Location of the project" style={{ maxWidth: '100%', height: 'auto' }}  />

          </Grid>
        </Grid>
      </Box>

      {/* next section */}
      <Box sx={{ display: 'flex', justifyContent: 'center', my:"20px" }}>
      <Grid container spacing={2}>
        {/* First column with image */}
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <img src={projectDetails.project.img3} alt="Location of the project" style={{ maxWidth: '100%', height: 'auto' }}  />
          </Box>
        </Grid>
        {/* Second column with text and lists */}
        <Grid item xs={12} md={6}>
          <Box sx={{ p: 0, m: 0 }}>
          <Typography variant="body1" sx={paraStyle} >
          {ReactHtmlParser(projectDetails.project.description3)}          
              </Typography>  
          </Box>
        </Grid>
      </Grid>
    </Box>
    <Box sx={{display:"flex", justifyContent:"center", my:"20px" }}>
    
<RequestModal projectTitle={projectTitle}/>
       <LearnMoreModal projectTitle={projectTitle}/>
    </Box>
   </>
  )
}

export default VideoSection
export const RequestModal = ({projectTitle}) => {
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [formData, setFormData] = useState({
      name: "",
      email: "",
      phone: "",
      message: "",
      page_name: "single_project_page",
      agentName: "",
      agentEmail: "sales@cityluxedxb.com ",
      button_type:"unit_request_button"
    });
    const handleMobileNumberChange = (value) => {
      let sanitizedValue = value.replace(/\D/g, "");
      if (sanitizedValue && sanitizedValue[0] !== "+") {
        sanitizedValue = `+${sanitizedValue}`;
      }
    
      setFormData((prevFormData) => ({
        ...prevFormData,
        phone: sanitizedValue,
      }));
    };
    const sendFormDataToAPI = () => {
      const dataToSend = {
        ...formData,
        page_link: `https://www.pscityluxe.com/projects/single-project-details/${projectTitle}`
      };
      fetch('/cityluxedxb/contact.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      })
      .then(response => {
        if (response.ok) {
          setSnackbarMessage("Thanks For Your Interest! We Will Get Back To You Shortly!");
          setSnackbarOpen(true);       
        } else {
          console.error('Failed to send form data');
        }
      })
      .catch(error => {
        console.error('Error sending form data:', error);
        
      });
    };
    const handleSubmitClick = () => {
      if (!isFormValid()) {
        setSnackbarMessage("Please fill all input fields first.");
          setSnackbarOpen(true);  
        return;
      }
  
      sendFormDataToAPI();
    };
    const isFormValid = () => {
      return (
        formData.name &&
        formData.email &&
        formData.phone 
        // formData.message
      );
    };
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
    };
    const handleClick = () => {
      handleClose();
      handleSubmitClick();
    };
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: "2px solid #e1d3bc",
      boxShadow: 24,
      p: 3,
    };
    const CloseIconStyle = {
       position: "absolute",
      top: "5px",
      right:"3px",
      cursor:"pointer",
      '&:hover': {
        color: "red",
        backgroundColor:"#4c4f54"
    } 
     }
     const textfield = {
      backgroundColor: "#ffffff",
      color: "#8593a9",
      borderRadius: "0px",
      border: "0.5px solid #e6e6e6",
      mb: "10px",
    };
    const subheading = {
          mt: '0px',
          maxWidth: '700px',
          paddingRight: '25px',
          color: "#6a6363",
          lineHeight: { xs: '25px', md: '27px' },
          fontSize: { xs: '15px', md: '18px' },
          fontFamily: "'Nunito Sans', sans-serif",
          fontWeight: '500',
          marginLeft:"58px",
          mb:"10px"
      }
      const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbarOpen(false);
      };
  return (
    <div>
      <Button variant="contained" sx={buttonStyle} onClick={handleOpen}>
      Request Units & Prices
      </Button>
     
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`animate__animated ${open ? 'animate__fadeIn' : 'animate__fadeOut'}`}
      >
        <Box sx={style}>
          <CloseIcon sx={CloseIconStyle} onClick={handleClose} />
          <Typography sx={subheading}>
          REQUEST UNITS & PRICES
          </Typography>
          <TextField size="small" name="name" value={formData.name} onChange={handleInputChange} type="text" placeholder="Enter Name" id='nameTextField' sx={{ ...textfield, width: "100%" }} />
          <TextField size="small" type="email" name="email" value={formData.email} onChange={handleInputChange} placeholder="Enter Email" id='emailTextField' sx={{ ...textfield, width: "100%" }}/>
          <PhoneInput country={"ae"} value={formData.phone} id="phoneNumberTextField"
          onChange={handleMobileNumberChange}
          countryCodeEditable={false}
          inputProps={{
            name: "phone",
            required: true,
            style: {
              width: "inherit",
              height: "auto",
              borderRadius: "0px",
              border: "1px solid #e3e0e0",
            },
          }}
        />
          <Button variant="contained" color="primary" fullWidth sx={buttonStyle} onClick={handleClick}>
           Request
          </Button>
          <Typography variant="body2" sx={{ fontSize: "10px", padding: "2px", marginTop: "10px" }}>
            We value your privacy. Your contact information is never shared with any third party and will remain internal where you can opt out at any time.
          </Typography>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}

      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};
export const LearnMoreModal = ({projectTitle}) => {
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [formData, setFormData] = useState({
      name: "",
      email: "",
      phone: "",
      message: "",
      page_name: "single_project_page",
      agentName: "",
      agentEmail: "sales@cityluxedxb.com ",
      button_type:"know_more_button"
    });
    const handleMobileNumberChange = (value) => {
      let sanitizedValue = value.replace(/\D/g, "");
      if (sanitizedValue && sanitizedValue[0] !== "+") {
        sanitizedValue = `+${sanitizedValue}`;
      }
    
      setFormData((prevFormData) => ({
        ...prevFormData,
        phone: sanitizedValue,
      }));
    };
    const sendFormDataToAPI = () => {
      const dataToSend = {
        ...formData,
        page_link: `https://www.pscityluxe.com/projects/single-project-details/${projectTitle}`
      };
      fetch('/cityluxedxb/contact.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      })
      .then(response => {
        if (response.ok) {
          setSnackbarMessage("Thanks For Your Interest! We Will Get Back To You Shortly!");
          setSnackbarOpen(true);  
                 } else {
          console.error('Failed to send form data');
        }
      })
      .catch(error => {
        console.error('Error sending form data:', error);
      });
    };
    const handleSubmitClick = () => {
      if (!isFormValid()) {
        setSnackbarMessage("Please fill all input fields first.");
          setSnackbarOpen(true);  
       
        return;
      }
  
      sendFormDataToAPI();
    };
    const isFormValid = () => {
      return (
        formData.name &&
        formData.email &&
        formData.phone 
        // formData.message
      );
    };
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
    };
    const handleClick = () => {
      handleClose();
      handleSubmitClick();
    };
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: "2px solid #e1d3bc",
      boxShadow: 24,
      p: 3,
    };
    const CloseIconStyle = {
       position: "absolute",
      top: "5px",
      right:"3px",
      cursor:"pointer",
      '&:hover': {
        color: "red",
        backgroundColor:"#4c4f54"
    } 
     }
     const textfield = {
      backgroundColor: "#ffffff",
      color: "#8593a9",
      borderRadius: "0px",
      border: "0.5px solid #e6e6e6",
      mb: "10px",
    };
    const subheading = {
          mt: '0px',
          maxWidth: '700px',
          paddingRight: '25px',
          color: "#6a6363",
          lineHeight: { xs: '25px', md: '27px' },
          fontSize: { xs: '15px', md: '18px' },
          fontFamily: "'Nunito Sans', sans-serif",
          fontWeight: '500',
          mb:"10px",
          marginLeft:"60px"
          
      }
      const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbarOpen(false);
      };
  return (
    <div>
      <Button variant="contained" sx={buttonStyle} onClick={handleOpen}>
      Learn More
      </Button>
     
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`animate__animated ${open ? 'animate__fadeIn' : 'animate__fadeOut'}`}
      >
        <Box sx={style}>
          <CloseIcon sx={CloseIconStyle} onClick={handleClose} />
          <Typography sx={subheading}>
          REQUEST TO LEARN MORE
          </Typography>
          <TextField size="small" name="name" value={formData.name} onChange={handleInputChange} type="text" placeholder="Enter Name" id='nameTextField' sx={{ ...textfield, width: "100%" }} />
          <TextField size="small" type="email" name="email" value={formData.email} onChange={handleInputChange} placeholder="Enter Email" id='emailTextField' sx={{ ...textfield, width: "100%" }}/>
          <PhoneInput country={"ae"} value={formData.phone} id="phoneNumberTextField"
          onChange={handleMobileNumberChange}
          countryCodeEditable={false}
          inputProps={{
            name: "phone",
            required: true,
            style: {
              width: "inherit",
              height: "auto",
              borderRadius: "0px",
              border: "1px solid #e3e0e0",
            },
          }}
        />
          <Button variant="contained" color="primary" fullWidth sx={buttonStyle} onClick={handleClick}>
           Learn More
          </Button>
          <Typography variant="body2" sx={{ fontSize: "10px", padding: "2px", marginTop: "10px" }}>
            We value your privacy. Your contact information is never shared with any third party and will remain internal where you can opt out at any time.
          </Typography>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}

      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};