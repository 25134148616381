import React, { useEffect, useState, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import Client from '../../../../components/axios';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import AddIcon from '@mui/icons-material/Add';
import { Box, Container } from '@mui/material';
import { cardstyle } from '../../../../theme/theme';

import { TextField } from '@mui/material';
import { Stack } from 'react-bootstrap';
import './add_projects.css';

import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



let projectDetails = {
    count: "",
    Ad_Type: "",
    Unit_Type: "",
    Unit_Model: "",
    Primary_View: "",
    Unit_Builtup_Area: "",
    No_of_Bathroom: "",
    Property_Title: "",
    Web_Remarks: "",
    Emirate: "",
    Community: "",
    Exclusive: "",
    Cheques: "",
    Plot_Area: "",
    Property_Name: "",
    Property_Ref_No: "",
    Listing_Agent: "",
    Listing_Agent_Phone: "",
    Listing_Agent_Photo: "",
    Listing_Date: "",
    Last_Updated: "",
    Bedrooms: "",
    Listing_Agent_Email: "",
    Price: "",
    Frequency: "",
    Unit_Reference_No: "",   
    No_of_Rooms: "",
    Latitude: "",
    Longitude: "",
    unit_measure: "",
    Featured: "",
    Fitted: "",
    Images: "",
    Thumb: "",
    Facilities: "",
    company_name: "",
    Web_Tour: "",
    Threesixty_Tour: "",
    Audio_Tour: "",
    Virtual_Tour: "",
    QR_Code: "",
    company_logo: "",
    Parking: "",
    Strno: "",
    preview_link: "",
    price_on_application: "",
    off_plan: "",
    permit_number: "",
    property_type: "",
    completion_status: "",
    date_time: "",

}



const NextAndPreviousButton = (props) => {
    return (
        <>

            <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', mt: "20px" }}>

                {
                    props.slideBox == 1 ?
                        <Button></Button>
                        :
                        <Button onClick={() => props.setSlideBox(props.slideBox - 1)}>Back</Button>

                }

                <Button sx={{ color: "#222" }}>{props.slideBox}/6</Button>

                <Button onClick={() => props.setSlideBox(props.slideBox + 1)}>Next</Button>

            </Box>
        </>
    )
}

const AMENITIES = [
    { title: 'Balcony' },
    { title: 'Central Air Conditioning', },
    { title: 'Covered Parking' },
    { title: 'Fully Fitted Kitchen' },
    { title: 'Intercom' },
    { title: "Maid's Room" },
    { title: 'Private Garage' },
    { title: 'Storage Room' },
    { title: "Children's Play Area " },
    { title: "Fitness Center" },
    { title: "Mosque" },
    { title: "Public Park" },
    { title: "Restaurants" },
    { title: "Shopping Mall" },
    { title: "Shops" },
];

export default function FullScreenDialog() {
    //open modal while clicking on the modal
    const [open, setOpen] = useState(false);
    //set box slider
    const [slideBox, setSlideBox] = useState(1);
    //set the project id 
    const [projectId, setProjectID] = useState(0);
    //set value in a array
    const [facilites, setFacilites] = useState(AMENITIES);
    //Enter all the details of form
    const [projects, setProjects] = useState(projectDetails);

    //set the facilities value in variable 
    const [selectedAmenities, setSelectedAmenities] = useState([]);



    // const [projectImage, setProjectImage] = useState({});
    // const fixedOptions = [AMENITIES];
    // console.log(value);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleInputValue = (traget) => {

        const { name, value } = traget;
        setProjects({
            ...projects,
            [name]: value,
        })
    }


    const editorRef = useRef(null);

    const SaveDetailsOfProject = () => {
        if (editorRef.current) {
            setProjectID(0);
            // console.log(editorRef.current.getContent());
            let description = editorRef.current.getContent();
            handleInputValue({ name: "Web_Remarks", value: description });
            alert('Description is saved');

            Client.post('/cityluxedxb/add_project_listing.php', projects, { headers: { "Content-type": "application/json" } })
                .then((response) => {

                    if (response.data.errorCode == '200') {
                        console.log(response.data);
                        setProjectID(response.data.lastInsertedId);
                        setSlideBox(6);

                    } else {
                        alert('something wrong please try again!');
                    }

                })
                .catch(err => {
                    console.log({ "error": err });
                });

        }
    };

    return (
        <div>
            <IconButton onClick={handleClickOpen}> <AddIcon sx={{ cursor: 'pointer' }} /></IconButton>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'sticky', top: "0" }}>
                    <Container maxWidth="lg">
                        <Toolbar>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                Add Details Of Project To List
                            </Typography>
                            <Button>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                    sx={{ color: "red" }}
                                >
                                    <CloseIcon onClick={() => setSlideBox(1)} />
                                </IconButton>
                            </Button>
                        </Toolbar>
                    </Container>
                </AppBar>

                {slideBox == 1 ? (

                    <Box>
                        <Container maxWidth="lg" sx={{ background: "#f2f2f2" }}>

                            <Box sx={{ py: "25px", my: "50px" }}>

                                <TextField id="outlined-basic" value={projects.Ad_Type} name="Ad_Type" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Ad_Type" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                <TextField id="outlined-basic" value={projects.Unit_Type} name="Unit_Type" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Unit_Type" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                <TextField id="outlined-basic" value={projects.Unit_Model} name="Unit_Model" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Unit_Model" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                <TextField id="outlined-basic" value={projects.Primary_View} name="Primary_View" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Primary_View" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                <TextField id="outlined-basic" value={projects.Unit_Builtup_Area} name="Unit_Builtup_Area" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Unit_Builtup_Area" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                <TextField id="outlined-basic" value={projects.No_of_Bathroom} name="No_of_Bathroom" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter No_of_Bathroom" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                <TextField id="outlined-basic" value={projects.Property_Title} name="Property_Title" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Property_Title" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />


                                <TextField id="outlined-basic" value={projects.Emirate} name="Emirate" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Emirate" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                <TextField id="outlined-basic" value={projects.Community} name="Community" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Community" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                <TextField id="outlined-basic" value={projects.Exclusive} name="Exclusive" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Exclusive" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', mt: "20px" }}>

                                    <Button></Button>
                                    <Button sx={{ color: "#222" }}>{slideBox}/6</Button>
                                    <Button onClick={() => setSlideBox(slideBox + 1)}>Next</Button>

                                </Box>

                                {/* <NextAndPreviousButton slideBox={slideBox} setSlideBox={setSlideBox} /> */}

                            </Box>
                        </Container>
                    </Box>

                ) : slideBox == 2 ? (

                    <Box>
                        <Container maxWidth="lg" sx={{ background: "#f2f2f2" }}>
                            <Box sx={{ py: "25px", my: "50px" }}>
                                <TextField id="outlined-basic" value={projects.Cheques} name="Cheques" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Cheques" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                <TextField id="outlined-basic" value={projects.Plot_Area} name="Plot_Area" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Plot_Area" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                <TextField id="outlined-basic" value={projects.Property_Name} name="Property_Name" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Property_Name" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                <TextField id="outlined-basic" value={projects.Property_Ref_No} name="Property_Ref_No" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Property_Ref_No" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                <TextField id="outlined-basic" value={projects.Listing_Agent} name="Listing_Agent" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Listing_Agent" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                <TextField id="outlined-basic" value={projects.Listing_Agent_Phone} name="Listing_Agent_Phone" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Listing_Agent_Phone" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                <TextField type="url" id="outlined-basic" value={projects.Listing_Agent_Photo} name="Listing_Agent_Photo" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Listing_Agent_Photo" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px", }} />
                                {/* 
                                <TextField type="date" id="outlined-basic" value={projects.Listing_Date} name="Listing_Date" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Listing_Date" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px", }} />
                                <TextField type="date" id="outlined-basic" value={projects.Last_Updated} name="Last_Updated" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Last_Updated" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px", }} /> */}

                                <TextField id="outlined-basic" value={projects.Bedrooms} name="Bedrooms" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Bedrooms" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px", }} />

                                <TextField id="outlined-basic" value={projects.Listing_Agent_Email} name="Listing_Agent_Email" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Listing_Agent_Email" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px", }} />

                                <TextField id="outlined-basic" value={projects.Price} name="Price" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Price" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px", }} />




                                <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', mt: "20px" }}>

                                    <Button onClick={() => setSlideBox(slideBox - 1)}>Back</Button>
                                    <Button sx={{ color: "#222" }}>{slideBox}/6</Button>
                                    <Button onClick={() => setSlideBox(slideBox + 1)}>Next</Button>

                                </Box>
                            </Box>

                        </Container>
                    </Box>

                ) : slideBox == 3 ? (

                    <Box>
                        <Container maxWidth="lg" sx={{ background: "#f2f2f2" }}>
                            <Box sx={{ py: "25px", my: "50px" }}>
                                <TextField id="outlined-basic" value={projects.Frequency} name="Frequency" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Frequency" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px", }} />

                                <TextField id="outlined-basic" value={projects.Unit_Reference_No} name="Unit_Reference_No" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Unit_Reference_No" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                <TextField id="outlined-basic" value={projects.No_of_Rooms} name="No_of_Rooms" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter No_of_Rooms" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                <TextField id="outlined-basic" value={projects.Latitude} name="Latitude" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Latitude" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />


                                <TextField id="outlined-basic" value={projects.Longitude} name="Longitude" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Longitude" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                <TextField id="outlined-basic" value={projects.unit_measure} name="unit_measure" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter unit_measure" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                <TextField id="outlined-basic" value={projects.Featured} name="Featured" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Featured" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />


                                <TextField id="outlined-basic" value={projects.Fitted} name="Fitted" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Fitted" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                {/* <TextField id="outlined-basic" value={projects.Images} name="Images" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Images" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} /> */}
                                {/* 
                                <TextField id="outlined-basic" value={projects.Thumb} name="Thumb" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Thumb" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} /> */}

                                {/* <TextField id="outlined-basic" value={projects.Facilities} name="Facilities" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Facilities" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} /> */}

                                <TextField id="outlined-basic" value={projects.company_name} name="company_name" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter company_name" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                <TextField id="outlined-basic" value={projects.Web_Tour} name="Web_Tour" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Web_Tour" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />




                                <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', mt: "20px" }}>

                                    <Button onClick={() => setSlideBox(slideBox - 1)}>Back</Button>
                                    <Button sx={{ color: "#222" }}>{slideBox}/6</Button>
                                    <Button onClick={() => setSlideBox(slideBox + 1)}>Next</Button>

                                </Box>

                            </Box>
                        </Container>
                    </Box>


                ) : slideBox == 4 ? (
                    <>
                        <Box>
                            <Container maxWidth="lg" sx={{ background: "#f2f2f2" }}>
                                <Box sx={{ py: "25px", my: "50px" }}>
                                    <TextField id="outlined-basic" value={projects.Threesixty_Tour} name="Threesixty_Tour" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Threesixty_Tour" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                    <TextField id="outlined-basic" value={projects.Audio_Tour} name="Audio_Tour" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter  Audio_Tour" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                    <TextField id="outlined-basic" value={projects.Virtual_Tour} name="Virtual_Tour" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter  Virtual_Tour" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                    <TextField id="outlined-basic" value={projects.QR_Code} name="QR_Code" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter  QR_Code" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                    <TextField id="outlined-basic" value={projects.company_logo} name="company_logo" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter  company_logo" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                    <TextField id="outlined-basic" value={projects.Parking} name="Parking" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter  Parking" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                    <TextField id="outlined-basic" value={projects.Strno} name="Strno" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter  Strno" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                    <TextField id="outlined-basic" value={projects.preview_link} name="preview_link" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter  preview_link" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                    <TextField id="outlined-basic" value={projects.price_on_application} name="price_on_application" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter price_on_application" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                    <TextField id="outlined-basic" value={projects.off_plan} name="off_plan" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter off_plan" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                    <Autocomplete
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={facilites}
                                        disableCloseOnSelect
                                        label="Enter Facilities" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }}

                                        value={selectedAmenities}
                                        onChange={(event, newValue) => {
                                            setSelectedAmenities([
                                                ...newValue
                                            ]);
                                        }}

                                        getOptionLabel={(option) => option.title}

                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.title}
                                            </li>
                                        )}

                                        // style={{ width: 500 }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Select Amenities" placeholder="Amenities" />
                                        )}
                                    />

                                    {/* <TextField id="outlined-basic" value={projects.permit_number} name="permit_number" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter permit_number" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                    <TextField id="outlined-basic" value={projects.property_type} name="property_type" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter property_type" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} />

                                    <TextField id="outlined-basic" value={projects.completion_status} name="completion_status" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter completion_status" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} /> */}


                                    <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', mt: "20px" }}>

                                        <Button onClick={() => setSlideBox(slideBox - 1)}>Back</Button>
                                        <Button sx={{ color: "#222" }}>{slideBox}/6</Button>
                                        <Button onClick={() => setSlideBox(slideBox + 1)}>Next</Button>

                                    </Box>

                                </Box>
                            </Container>
                        </Box>
                    </>

                ) : slideBox == 5 ? (

                    <>
                        <Box>
                            <Container maxWidth="lg" sx={{ background: "#f2f2f2" }}>
                                <Box sx={{ py: "25px", mt: "50px", }}>
                                    {/* <TextField id="outlined-basic" value={projects.Web_Remarks} name="Web_Remarks" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Web_Remarks" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} /> */}
                                    <Stack > Enter Project Descripton</Stack>
                                    <Editor
                                        onInit={(evt, editor) => editorRef.current = editor}

                                        init={{
                                            height: 500,
                                            menubar: false,
                                            plugins: [
                                                'a11ychecker', 'advlist', 'advcode', 'advtable', 'autolink', 'checklist', 'export',
                                                'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
                                                'powerpaste', 'fullscreen', 'formatpainter', 'insertdatetime', 'media', 'table', 'help', 'wordcount'
                                            ],
                                            toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                                                'alignleft aligncenter alignright alignjustify | ' +
                                                'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help'
                                            //  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                        }}
                                    />

                                </Box>

                                <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', mt: "20px" }}>

                                    <Button onClick={() => setSlideBox(slideBox - 1)}>Back</Button>
                                    <Button sx={{ color: "#222" }}>{slideBox}/6</Button>

                                    <Button>
                                        <button style={{ textAlign: "center", padding: "10px", fontWeight: "600", marginTop: "5px", background: "#333", color: "#fff" }} onClick={SaveDetailsOfProject}>Save Descrition</button>
                                    </Button>

                                </Box>

                                {/* <NextAndPreviousButton slideBox={slideBox} setSlideBox={setSlideBox} /> */}

                            </Container>
                        </Box>
                    </>

                ) : slideBox == 6 ? (

                    <Box>
                        <Container maxWidth="lg" sx={{ background: "#f2f2f2" }}>
                            <Box sx={{ py: "25px", my: "50px" }}>

                                <TextField type="file" inputProps={{ multiple: true }} id="outlined-basic" label="Upload Images" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }}

                                    onChange={(e) => {

                                        let formData = new FormData();
                                        let files = e.target.files;
                                        for (let i = 0; i < files.length; i++) {
                                            formData.append(`uploadImage[]`, files[i]);
                                        }

                                        formData.append('project_id', projectId)

                                        Client.post('/cityluxedxb/upload_project_images.php', formData, { headers: { "Content-type": "multipart/form-data" } })
                                            .then((response) => {

                                                console.log(response);
                                            })
                                            .catch(err => {
                                                console.log({ "error": err });
                                            });

                                    }}

                                />

                                {/* <Box sx={{ display: 'flex', justifyContent: "center", alignItems: 'center', width: "100%", my: 5 }}>

                                    <Button sx={{ fontSize: "20px", color: "#222", border: "1px solid #333", }}
                                        onClick={() => {
                                            console.log('submit');

                                        }}
                                    >Submit Project</Button>

                                </Box> */}

                                {/* <TextField id="outlined-basic" value={projects.Thumb} name="Thumb" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Thumb" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} /> */}

                                {/* <TextField id="outlined-basic" value={projects.Facilities} name="Facilities" onChange={(e) => handleInputValue(e.target)} autoComplete="off" label="Enter Facilities" variant="outlined" fullWidth='100%' sx={{ borderBottom: "2px solid #8c8c8c", autoFocus: "false", mt: "10px" }} /> */}



                            </Box>
                        </Container>
                    </Box>

                ) : (

                    <>

                    </>
                )
                }


            </Dialog >
        </div >
    );

}

