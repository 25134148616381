import React from "react";
import { useNavigate } from "react-router-dom";
import { cardstyle } from "../theme/theme";
import IconButton from "@mui/material/IconButton";
import LinkedIn from "@mui/icons-material/LinkedIn";
import { Box, Card, Typography } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";

const heading = {
  textTransform: "capitalize",
  fontWeight: "600",
  fontFamily: "'Roboto', sans-serif",
  fontSize: "24px",
  color: "cutome.lightwheat",
  cursor: "pointer",
  lineHeight: "1em",
};
const subheading = {
  fontSize: "14px",
  color: "#5c727d",
  fontWeight: "600",
  fontFamily: "'Nunito Sans', sans-serif",
  textTransform: "capitalize",
  lineHeight: "1.3em",
};

function childFunction(event) {
  event.preventDefault();
}

const AgentCardView = (props) => {
  let val = props.agentDetail;
  // console.log(val);
  let socialMedia = val.social_media.split("##");
// console.warn("social media links are", socialMedia);
  const navigate = useNavigate();
  let single_team_member_details = (id) => {
    // alert(id);
    navigate(`/team-member-details/${val.id}`);
  };
  let width = {};
  if (props.agentCarousel) {
    width = {
      width: { xs: "100%" },
    };
  } else {
    width = {
      width: { xs: "100%", sm: "45%", md: "30%" },
    };
  }

  return (
    <>
      <Card
        key={val.id}
        sx={{
          ...cardstyle,
          ...width,
          mr: { xs: "0px", md: "3%" },
          minHeight: "300px",
          mb: { xs: "20px" },
        }}
      >
        <CardActionArea onClick={() => single_team_member_details(val.id)}>
          <Box>
            <img src={val.image} alt="" className="TeamMemberPhoto" />
          </Box>

          <CardContent sx={{ height: "120px" }} >
            {props.agentCarousel ? (
              <Typography
                variant="body2"
                sx={{
                  ...heading,
                  fontSize: "20px",
                  my: "0px",
                  py: "0px",
                  mt: "2px",
                }}
              >
                {val.name}
              </Typography>
            ) : (
              <Typography
                variant="body2"
                sx={{ ...heading, my: "0px", py: "0px", mt: "2px" }}
              >
                {val.name}
              </Typography>
            )}
            <Typography variant="body2" sx={{ mb: "10px", ...subheading }}>
              {val.position}
            </Typography>

            {props.agentCarousel ? (
              <></>
            ) : (
              <Typography
                variant="body2"
                sx={{ ...subheading, fontWeight: "400" }}
              >
                Specialisation - {val.specialisation}
              </Typography>
            )}
          </CardContent>
          </CardActionArea>

          {props.agentCarousel ? (
            <></>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                my: "15px",
                px: "30px",
              }}
            >
              {socialMedia.map((val, index) => {
                // console.log(val + 'this is index of arrayF' + index);
                if (val.includes("x.com")) {
                  return (
                    <a href={val} target="_blank" rel="noopener noreferrer" key={index}>
                      <IconButton
                        sx={{ backgroundColor: "#efefef", mr: "8px" }}>
                        <svg
                          fontSize="small"
                          sx={{
                            fontSize: "14px",
                            color: "#777",
                            "&:hover": { fill: "#d7c3a8" },
                          }}
                          className="forHover"
                          viewBox="0 0 28 28"
                          aria-label="Twitter"
                          width={13}
                          role="img"
                          class="your-icon-class"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g>
                            <path
                              d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
                              fill="black"
                            ></path>
                          </g>
                        </svg>
                      </IconButton>
                    </a>
                  );
                } else if (val.includes("linkedin")) {
                  return (
                    <a href={val} target="_blank" rel="noopener noreferrer" key={index}>
                      <IconButton sx={{ backgroundColor: "#efefef", mr: "8px" }} >
                        <LinkedIn fontSize="small" sx={{zIndex:"10000", fontSize: "14px", color: "#777", "&:hover": { color: "#d7c3a8" },}}/>
                      </IconButton>
                    </a>
                  );
                }
              })}
            </Box>
          )}
      </Card>
    </>
  );
};

export default AgentCardView;
