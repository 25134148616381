import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import FormControl from "@mui/material/FormControl";
import TuneIcon from "@mui/icons-material/Tune";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { bedroom, Price, Area } from "./subcomponent/all_values_search";
import {useNavigate } from "react-router-dom";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import InputSearchKeywordList from "./customHooks/inputSearchKeywordList";

const filter = createFilterOptions();
const SearchFilter = (props) => {
  const [serchProperty, setSearchProperty] = useState([]);
  const [catList, setCatList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [clickbutton, setclickbutton] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  const [minBed, setMinBed] = useState("");
  const [maxBed, setMaxBed] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [minArea, setMinArea] = useState("");
  const [maxArea, setMaxArea] = useState("");
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
   let handleClose = () => {
    setState({ ...state, open: false });
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} sx={{ color: "red" }}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const navigate = useNavigate();
  const findMatchRecords = () => {
    if (
      searchKeyword == null &&
      category === "" &&
      type === "" &&
      minBed === "" &&
      maxBed === "" &&
      minPrice === "" &&
      maxPrice === "" &&
      minArea === "" &&
      maxArea === ""
    ) {
      navigate(`/properties`);
    } else {
      let searchValue = searchKeyword ? [searchKeyword.label] : "";
      
      let item = {
        advanceSearchKeywords: {
          searchKeyword: searchValue,
          category: category,
          type: type,
          minBed: minBed,
          maxBed: maxBed,
          minPrice: minPrice,
          maxPrice: maxPrice,
          minArea: minArea,
          maxArea: maxArea,
        },
        highlow: {
          sorting: "none",
        },
        selectcount: {
          page: 0,
        },
      };
      navigate(`/search`, {  state: { ...item } });

    
    }
  };

  let buttonclick = () => {
    setclickbutton(!clickbutton);
    return props.getclicked(!clickbutton);
  };

  const button = {
    color: "#4c4f54",
    bgcolor: "custom.lightwheat",
    transition: "0.3s",
    fontWeight: "600",
    px: "40px ",
    borderRadius: "0px",
    fontSize: { xs: "9px", md: "13px" },
    "&:hover": {
      bgcolor: "#4c4f54",
      color: "custom.lightwheat",
    },
  };

  const expandfilter = {
    borderRadius: "0px",
    bgcolor: "custom.lightwheat",
    color: "#4c4f54",
    px: "25px",
    cursor: "pointer",
    "&:hover": {
      color: "custom.lightwheat",
      bgcolor: "#4c4f54",
    },
  };

  const selectitem = {
    backgroundColor: "#ffffff",
    color: "#8593a9",
    borderRadius: "0px",
    cursor: "pointer",
    border:"none !important",
    "&:hover": {
      border: "0px",
    },
  };

  let filtermaindiv = {
    backgroundColor: "#ffffff59",
    p: "10px 10px",
  };

  let mainfilterbox = {};
  if (clickbutton) {
    mainfilterbox = {
      display: "block",
    };
  } else {
    mainfilterbox = {
      display: "none",
    };
  }

  let [data] = InputSearchKeywordList();
  useEffect(() => {
    if (data !== null) {
      setSearchProperty([
        ...data.community,
        ...data.property_name,
        ...data.emirate,
      ]);
      setCatList(data.unit_type);
      setTypeList(data.ad_type);
    }
   
  }, [data]);

  
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={alertMessage}
        key={vertical + horizontal}
        action={action}
        sx={{ zIndex: "1000" }}
      ></Snackbar>

      <Box display="flex" flexDirection="column" sx={filtermaindiv}>
        <Box display="flex">
          <Stack flexDirection="row" width="40%">
               <FormControl className="formAdvanceSearch" sx={{ minWidth: '98%' }} size="small">
                           <Autocomplete
                                disablePortal
                                size="small"
                                id="combo-box-demo"
                                value={searchKeyword}
                                onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                        setSearchKeyword({
                                            label: newValue,
                                        });
                                    } else if (newValue && newValue.inputValue) {
                                        setSearchKeyword({
                                            label: newValue.inputValue,
                                        });
                                    } else {
                                        setSearchKeyword(newValue);
                                    }
                                }}

                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    const { inputValue } = params;
                                    const isExisting = options.some((option) => inputValue === option.label);
                                    if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                            inputValue,
                                            label: `Add "${inputValue}"`,
                                        });
                                    }

                                    return filtered;
                                }}

                                options={serchProperty}

                                getOptionLabel={(option) => {
                                    if (typeof option === 'string') {
                                        return option;
                                    }
                                    if (option.inputValue) {
                                        return option.inputValue;
                                    }
                                    return option.label;
                                }}
                                className="autocompliteInput search_property"
                                renderOption={(props, option) => <li {...props}>{option.label}</li>}
                                sx={{ ...selectitem }}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder="Search Property" />
                                )}
                            /> 
                        </FormControl>
            <FormControl
              className="formAdvanceSearch"
              sx={{ minWidth: "98%" }}
              size="small"
            >
              <Autocomplete
                size="small"
                multiple
                id="combo-box-demo"
                options={serchProperty}
                getOptionLabel={(option) => option.label}
                filterSelectedOptions
                className="autocompliteInput search_property"
                sx={{ background: "#fff", m: "0px",  border: "none !important" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ background: "#fff", m: "0px",  border: "none !important" }}
                    placeholder="Search Property"
                  />
                )}
              />
            </FormControl>
          </Stack>

          <Stack flexDirection="row" justifyContent="space-between" width="40%" >
          <FormControl
  className="formAdvanceSearch"
  sx={{ minWidth: "49%", }}
  size="small"
>
<Autocomplete
  disablePortal
  size="small"
  id="combo-box-demo"
  className=" search_categories" 
  onChange={(e, val) => (val ? setCategory(val.label) : setCategory(""))}
  options={catList}
  sx={{ ...selectitem, height:"37px", marginTop:"2px" }}
  renderInput={(params) => (
    <TextField
      {...params}
      placeholder="Categories"
      sx={{
        '& .autocompliteInput': {
          border: 'none', padding:"0", margin:"0"
        },
      }}
    />
  )}
/>


</FormControl>


            <FormControl
              className="formAdvanceSearch"
              sx={{ minWidth: "49%", border:"none" }}
              size="small"
            >
              <Autocomplete
                disablePortal
                size="small"
                id="combo-box-demo"
                className="autocompliteInput search_type"
                onChange={(e, val) => (val ? setType(val.label) : setType(""))}
                options={typeList}
                sx={{...selectitem, border:"none"}}
                renderInput={(params) => (
                  <TextField
                  sx={{border:"none"}}
                    {...params}
                    placeholder="Type"
                  />
                )}
              />
            </FormControl>
          </Stack>

          <Stack
            flexDirection="row"
            justifyContent="space-between"
            width="20%"
            sx={{ paddingLeft: "1%" }}
          >
            <Button
              sx={{ ...expandfilter, width: "35%" }}
              onClick={buttonclick}
            >
              <TuneIcon fontSize="small" />
            </Button>
            <LoadingButton sx={{ ...button, width: "60%" }} onClick={findMatchRecords} loading={loading} loadingPosition="end" >
              Search
            </LoadingButton>
          </Stack>
        </Box>

        <Box sx={mainfilterbox}>
          <Box display="flex" sx={{ mt: "10px" }}>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
            >
              <FormControl
                className="formAdvanceSearch"
                sx={{ minWidth: "24%" }}
                size="small"
              >
                <Autocomplete
                  disablePortal
                  size="small"
                  id="combo-box-demo"
                  className="autocompliteInput search_min_bed"
                  onChange={(e, val) =>
                    val ? setMinBed(val.label) : setMinBed("")
                  }
                  options={bedroom}
                  sx={selectitem}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Min Bed"
                    />
                  )}
                />
              </FormControl>
              <FormControl
                className="formAdvanceSearch"
                sx={{ minWidth: "25%" }}
                size="small"
              >
                <Autocomplete
                  disablePortal
                  size="small"
                  id="combo-box-demo"
                  className="autocompliteInput search_max_bed"
                  options={bedroom}
                  onChange={(e, val) =>
                    val ? setMaxBed(val.label) : setMaxBed("")
                  }
                  sx={selectitem}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Max Bed"
                    />
                  )}
                />
              </FormControl>
              <FormControl
                className="formAdvanceSearch"
                sx={{ minWidth: "24%" }}
                size="small"
              >
                <Autocomplete
                  disablePortal
                  size="small"
                  id="combo-box-demo"
                  className="autocompliteInput search_min_price"
                  onChange={(e, val) =>
                    val ? setMinPrice(val.label) : setMinPrice("")
                  }
                  options={Price}
                  sx={selectitem}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Min Price"
                    />
                  )}
                />
              </FormControl>
              <FormControl
                className="formAdvanceSearch"
                sx={{ minWidth: "25%" }}
                size="small"
              >
                <Autocomplete
                  disablePortal
                  size="small"
                  id="combo-box-demo"
                  className="autocompliteInput search_max_price"
                  onChange={(e, val) =>
                    val ? setMaxPrice(val.label) : setMaxPrice("")
                  }
                  options={Price}
                  sx={selectitem}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Max Price"
                    />
                  )}
                />
              </FormControl>
            </Stack>
          </Box>

          <Box display="flex" sx={{ mt: "10px" }}>
            <Stack
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
            >
              <FormControl
                className="formAdvanceSearch"
                sx={{ minWidth: "24%" }}
                size="small"
              >
                <Autocomplete
                  disablePortal
                  size="small"
                  id="combo-box-demo"
                  className="autocompliteInput search_min_area"
                  onChange={(e, val) =>
                    val ? setMinArea(val.label) : setMinArea("")
                  }
                  options={Area}
                  sx={selectitem}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Min Area"
                    />
                  )}
                />
              </FormControl>
              <FormControl
                className="formAdvanceSearch"
                sx={{ minWidth: "25%" }}
                size="small"
              >
                <Autocomplete
                  disablePortal
                  size="small"
                  id="combo-box-demo"
                  className="autocompliteInput search_max_area"
                  onChange={(e, val) =>
                    val ? setMaxArea(val.label) : setMaxArea("")
                  }
                  options={Area}
                  sx={selectitem}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Max Area" />
                  )}
                />
              </FormControl>
              <FormControl sx={{ minWidth: "24%" }} size="small"></FormControl>
              <FormControl sx={{ minWidth: "25%" }} size="small"></FormControl>
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SearchFilter;
