import React, { useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Stack } from "@mui/material";
import logo from '../../../assests/logo_img/cl-logo.png'
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import PostAddIcon from '@mui/icons-material/PostAdd';
import AddBoxIcon from '@mui/icons-material/AddBox';
import PasswordIcon from '@mui/icons-material/Password';
import ListIcon from '@mui/icons-material/List';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import LogoutIcon from '@mui/icons-material/Logout';
// import UserContext from "../auth/auth";
import UserContextCurrency from "../../../context/currencyChange";
import Cookies from "js-cookie";

const Sidebar = () => {
    // const { value, setValue } = useContext(UserContext);
    const { currencySign, setCurrencySign, value, setValue } = useContext(UserContextCurrency);

    const location = useLocation();
    // let path = location();
    let path = location.pathname;
    let lastrouter = path.split('/');



    function logout() {
        // setValue(false);
        // alert('logout');
        Cookies.remove('userName');
        Cookies.remove('userPassword');

    }

    return (
        <>
            <Box>
                <Stack sx={{ py: "10px" }}>
                    <img src={logo} alt="logo" style={{ maxWidth: '200px' }} />
                </Stack>
                <Box sx={{ width: '100%' }}>
                    <nav aria-label="main mailbox folders">
                        <List>
                            <Link to="/adminpanel/dashboard/dashboard" style={{ textDecoration: "none", color: 'inherit' }}>
                                <ListItem disablePadding>
                                    <ListItemButton id="adminManu1" className={lastrouter[3] === "dashboard" ? "activeManu" : ""}  >
                                        {/* sx={{ backgroundColor: '#d7c3a8', '&:hover': { color: '#777' } }} */}
                                        <ListItemIcon>
                                            <DashboardCustomizeIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Dashboard" />
                                    </ListItemButton>
                                </ListItem>
                            </Link>

                            <ListItem disablePadding>
                                <ListItemButton className={lastrouter[3] === "" ? "activeManu" : ""}>
                                    <ListItemIcon>
                                        <AddBoxIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="List Property" />
                                </ListItemButton>
                            </ListItem>

                            <Link to="/adminpanel/dashboard/team" style={{ textDecoration: "none", color: 'inherit' }}>
                                <ListItem disablePadding>
                                    <ListItemButton className={lastrouter[3] === "team" ? "activeManu" : ""} >
                                        <ListItemIcon>
                                            <Diversity3Icon />
                                        </ListItemIcon>
                                        <ListItemText primary="Team" />
                                    </ListItemButton>
                                </ListItem>
                            </Link>

                            <Link to="/adminpanel/dashboard/property_type" style={{ textDecoration: "none", color: 'inherit' }}>
                                <ListItem disablePadding>
                                    <ListItemButton className={lastrouter[3] === "property_type" ? "activeManu" : ""}>
                                        <ListItemIcon>
                                            <PostAddIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Property 
                                    Type" />
                                    </ListItemButton>
                                </ListItem>
                            </Link>

                            <Link to="/adminpanel/dashboard/change_password" style={{ textDecoration: "none", color: 'inherit' }}>
                                <ListItem disablePadding>
                                    <ListItemButton className={lastrouter[3] === "change_password" ? "activeManu" : ""}>
                                        <ListItemIcon>
                                            <PasswordIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Update Password" />
                                    </ListItemButton>
                                </ListItem>
                            </Link>



                            <Link to="/adminpanel/dashboard/allprojects" style={{ textDecoration: "none", color: 'inherit' }}>
                                <ListItem disablePadding>
                                    <ListItemButton className={lastrouter[3] === "allprojects" ? "activeManu" : ""}>
                                        <ListItemIcon>
                                            <ListIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="All Projects" />
                                    </ListItemButton>
                                </ListItem>
                            </Link>

                            <ListItem disablePadding>
                                <ListItemButton className={lastrouter[3] === "" ? "activeManu" : ""} onClick={logout}>
                                    <ListItemIcon>
                                        <LogoutIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Logout" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </nav>
                </Box>
            </Box>
        </>
    )
}

export default Sidebar;