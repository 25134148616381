import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import InputSearchKeywordList from "./customHooks/inputSearchKeywordList";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import {useFooterContext } from "../App";
import { useLinkTextContext } from "../App";
import { BlogContext } from "../pages/Blogs/BlogContext";
import { CategoryContext } from "../pages/Blogs/CategoryProvider";

const footerHeading = {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "17px",
    textTransform: 'uppercase',
    fontWeight: '700',
    color: "#eeeeee",
    mt: '20px',
    mb: '10px',
};

const footerSubheading = {
    pt: '5px',
    color: "#bbbbbb",
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "13px",
};

const MainFooter = () => {
    const {blogCategories } = useContext(BlogContext);
    const { setCategory } = useContext(CategoryContext);
    const { linkText, setLinkText } = useLinkTextContext();
    const { setFooterData, linkData, setLinkData } = useFooterContext();
    const [isOnPropertiesPage, setIsOnPropertiesPage] = useState(false);
    const navigate = useNavigate();

    const handleCategoryClick = (category) => {
        setCategory(category);
        navigate('/blogs/blogs-by-category');
      };
    


    const keywords = {
        "Apartment in Dubai Marina": { unit_type: "Apartment", community: "Dubai Marina", ad_type:"-", emirate:"-", property_name:"-" },
        "Apartment in Downtown Dubai" :{unit_type:"Apartment", community:"Downtown Dubai", ad_type:"-", emirate:"-", property_name:"-"},
        "Penthouse in Dubai Marina": { unit_type: "Penthouse", community: "Dubai Marina", ad_type:"-", emirate:"-", property_name:"-"  },
        "Penthouse in Palm Jumeirah": { unit_type: "Penthouse", community: "Palm Jumeirah", ad_type:"-", emirate:"-", property_name:"-"  },
        "Villa in Emirates Hills": { unit_type: "Villa", community: "Emirates Hills", ad_type:"-", emirate:"-", property_name:"-"  },
        "Villa on Palm Jumeirah":{ unit_type: "Villa", community: "Palm Jumeirah", ad_type:"-", emirate:"-", property_name:"-" },
        "Villa in Jumeirah Golf Estate": { unit_type: "Villa", community: "Jumeirah", ad_type:"-", emirate:"-", property_name:"-"  },
        "Villa in Dubai Hills Estate": { unit_type: "Villa", community: "Hills Estate", emirate:"Dubai", ad_type:"-", property_name:"-" },
        // second grid
        "Dubai Villas for Sale": { unit_type: "Villa", emirate:"Dubai", ad_type:"sale", community:"-", property_name:"-" },
        "Buy Property in Dubai": {  emirate:"Dubai", ad_type:"sale", unit_type:"-", community:"-", property_name:"-" },
        "Buy Penthouse in Dubai": { unit_type: "Penthouse", emirate:"Dubai", ad_type:"sale", community:"-", property_name:"-" },
        "Buy Apartment in Dubai": { unit_type: "Apartment", emirate:"Dubai", ad_type:"sale", property_name:"-" },
        "Buy Mansions in Dubai": {  emirate:"Dubai", ad_type:"sale", unit_type:"-", property_name:"-", community:"-"  },
        "Dubai Penthouses for Sale": { unit_type: "Penthouse",  emirate:"Dubai", ad_type:"sale", community:"-", property_name:"-" },
        "Dubai Apartments for Sale": { unit_type: "Apartment",  emirate:"Dubai", ad_type:"sale", property_name:"-", community:"-" },
        "Buy Villa in Dubai": { unit_type: "Villa",  emirate:"Dubai", ad_type:"sale",property_name:"-", community:"-" },
        // third grid
        "Buy Off Plan Villas in Dubai": { unit_type: "Villa",  emirate:"Dubai", ad_type:"sale", community:"-", property_name:"-" },
        "Buy Off Plan Apartments in Dubai": { unit_type: "Apartments",  emirate:"Dubai", ad_type:"-", community:"-", property_name:"-" },
        "Buy Off Plan Properties in Dubai": {  emirate:"Dubai", ad_type:"sale", unit_type:"-", community:"-", property_name:"-" },
        "Buy Off Plan Penthouse in Dubai": { unit_type: "Penthouse",  emirate:"Dubai", ad_type:"sale", community:"-", property_name:"-" },
        "Buy Off Plan Projects in Dubai": {  emirate:"Dubai", ad_type:"sale", unit_type:"-", community:"-", property_name:"-" },
        "Buy Off Plan Mansion in Dubai": {  emirate:"Dubai", ad_type:"sale", unit_type:"-", community:"-", property_name:"-" },
        "Off Plan Properties in Dubai": {  emirate:"Dubai",ad_type:"-", unit_type:"-", community:"-", property_name:"-" },
        "Latest Off Plan Projects in Dubai": {  emirate:"Dubai", ad_type:"-", unit_type:"-", community:"-", property_name:"-" },
        // fourth grid
        "Dubai Lifestyle":{ emirate:"Dubai", ad_type:"-", unit_type:"-", community:"-", property_name:"-" },
        "Luxury lifestyle":{ emirate:"-", ad_type:"-", unit_type:"-", community:"-", property_name:"-"},
        "Beachfront Living":{emirate:"-", ad_type:"-", unit_type:"-", community:"-", property_name:"-"},
        "Waterfront Lifestyle":{ emirate:"-", ad_type:"-", unit_type:"-", community:"-", property_name:"-"},
        "Downtown Dubai":{ emirate:"-", ad_type:"-", unit_type:"-", community:"Downtown Dubai", property_name:"-"},
        "Golf Course Communities":{community:"-", ad_type:"-", unit_type:"-", emirate:"-", property_name:"-"},
        "Private Island Living":{community:"-", ad_type:"-", unit_type:"-", emirate:"-", property_name:"-"},
        "Drean City Lifestyle":{community:"-", ad_type:"-", unit_type:"-", emirate:"-", property_name:"-"}
    };

    const handleLinkClick = async (linkText) => {
        const queryParams = keywords[linkText];
        setLinkText(linkText);
        if (!queryParams) return;
        try {
            const response = await axios.post("/cityluxedxb/get_footer_search_results.php", {
                "unit_type": queryParams.unit_type,
                "ad_type": queryParams.ad_type,
                "community": queryParams.community,
                "emirate": queryParams.emirate,
                "property_name": queryParams.property_name
            });
            if (response.data.errorcode === "200" && response.data.propertieslist.length > 0) {
                const linkResult = response.data.propertieslist;
                setLinkData(linkResult);
                if (!isOnPropertiesPage) {
                  setIsOnPropertiesPage(true);
                  navigate("/footerLinksResult");
                } else {
                    setLinkData(response.data.propertieslist);
                }
              } else if (response.data.errorcode === "400" && response.data.status === "no record found") {
               
                setLinkData([]);
                setIsOnPropertiesPage(false);

              } else {
               
                setIsOnPropertiesPage(false); 

              }
            } catch (error) {
              console.error("Error fetching data fooooter:", error);
              setLinkData([]);
              setIsOnPropertiesPage(false);
            }
          };
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get("/cityluxedxb/get_footer_search_results.php");
          } catch (error) {
            console.error("Error fetching initial data:", error);
          }
        };
        fetchData(); 
      }, []);
    useEffect(() => {
  }, [linkData]);

    const [data] = InputSearchKeywordList();
    const renderLinks = (links) => {
        return links.map((link, index) => (
            <Link key={index} to="/footerLinksResult" style={{ textDecoration: 'none' }} onClick={() => handleLinkClick(link.keyword)}>
                <Typography variant="body2" sx={footerSubheading}>
                    {link.label}
                </Typography>
            </Link>
        ));
    };
    const renderGrid = (gridData) => {
        return (
            <Grid xs={12} sm={12} md={6} lg={3}>
                <Typography gutterBottom variant="subtitle1" component="div" sx={footerHeading}>
                    {gridData.heading}
                </Typography>
                {renderLinks(gridData.links)}
            
            </Grid>
        );
    };
    const gridsData = [
        {
            heading: "Dubai Communities",
            links: [
                { keyword: "Apartment in Dubai Marina", label: "Apartment in Dubai Marina" },
                { keyword: "Apartment in Downtown Dubai", label: "Apartment in Downtown Dubai" },
                { keyword: "Penthouse in Dubai Marina", label: "Penthouse in Dubai Marina" },
                { keyword: "Penthouse in Palm Jumeirah", label: "Penthouse in Palm Jumeirah" },
                { keyword: "Villa in Emirates Hills", label: "Villa in Emirates Hills" },
                { keyword: "Villa on Palm Jumeirah", label: "Villa on Palm Jumeirah" },
                { keyword: "Villa in Jumeirah Golf Estate", label: "Villa in Jumeirah Golf Estate" },
                { keyword: "Villa in Dubai Hills Estate", label: "Villa in Dubai Hills Estate" },
            ]
        },
        {
            heading: "Dubai Properties for Sale",
            links: [
                { keyword: "Dubai Villas for Sale", label: "Dubai Villas for Sale" },
                { keyword: "Buy Property in Dubai", label: "Buy Property in Dubai" },
                { keyword: "Buy Penthouse in Dubai", label: "Buy Penthouse in Dubai" },
                { keyword: "Buy Apartment in Dubai", label: "Buy Apartment in Dubai" },
                { keyword: "Buy Mansions in Dubai", label: "Buy Mansions in Dubai" },
                { keyword: "Dubai Penthouses for Sale", label: "Dubai Penthouses for Sale" },
                { keyword: "Dubai Apartments for Sale", label: "Dubai Apartments for Sale" },
                { keyword: "Buy Villa in Dubai", label: "Buy Villa in Dubai" },
            ]
        },
        {
            heading: "OFF PLAN PROPERTIES IN DUBAI",
            links: [
                { keyword: "Buy Off Plan Villas in Dubai", label: "Buy Off Plan Villas in Dubai" },
                { keyword: "Buy Off Plan Apartments in Dubai", label: "Buy Off Plan Apartments in Dubai" },
                { keyword: "Buy Off Plan Properties in Dubai", label: "Buy Off Plan Properties in Dubai" },
                { keyword: "Buy Off Plan Penthouses in Dubai", label: "Buy Off Plan Penthouses in Dubai" },
                { keyword: "Buy Off Plan Projects in Dubai", label: "Buy Off Plan Projects in Dubai" },
                { keyword: "Buy Off Plan Mansions in Dubai", label: "Buy Off Plan Mansions in Dubai" },
                { keyword: "Off Plan Properties in Dubai", label: "Off Plan Properties in Dubai" },
                { keyword: "Latest Off Plan Projects in Dubai", label: "Latest Off Plan Projects in Dubai" },
            ]
        },
    ];
    return (
      <>
        <Grid
          container
          spacing={2}
          sx={{ m: 0, p: 0 }}
          textAlign={{ xs: "center", md: "left" }}
        >
          {gridsData.map((gridData, index) => renderGrid(gridData, index))}

          <Grid xs={12} sm={12} md={6} lg={3}>
            <Typography
              gutterBottom
              variant="subtitle1"
              component="div"
              sx={footerHeading}
            >
              REAL ESTATE NEWS & BLOGS
            </Typography>
            <ul style={{paddingLeft:"0px"}}>
              {blogCategories.map((category, index) => (
                <li
                  key={index}
                  style={{
                    listStyleType: "none",
                    color: "#BBBBBB",
                    fontSize: "13px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleCategoryClick(category)}
                >
                  {category}
                </li>
              ))}
            </ul>
          </Grid>
        </Grid>
      </>
    );
};
export default MainFooter;