import React, { useState, useEffect } from 'react';
import TopNavbar from "../../components/top_navbar";
import ManManu from "../../components/main_manu";
import { cardstyle } from "../../theme/theme";
import {Box, Skeleton, Stack,Container,Typography,Card,} from "@mui/material";
import MainFooter from "../../components/main_footer";
import BottomFooter from "../../components/botttom_footer";
import Newsletter from "../../components/newsletter";
import FooterButton from "../../components/footer_button";
import ProjectsCards from "./ProjectsCards";
import Breadcrumb from "../../components/bread_crumb";
import HeroSlider from "./HeroSlider";
import AdvanceSearchProjects from "./AdvanceSearchProjects";
import ContactUs from "../../components/ContactUs";
import { CardsContainer, breadcrumbContainer, subheading } from '../../theme/theme';
const AllProjectsView = (props) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [bannerData, setBannerData] = useState([]);

   useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://cityluxedxb.com/adminpanel/getProjects.php');
        const data = await response.json();
        setProjects(data.project);
        setLoading(false);
        setBannerData(data.bannerContent);
        // console.warn("all banner data from api ", data.bannerContent);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
// console.warn("all data is ", projects);
  let matches = props.matches;
  let manmanu = {};
  if (matches) {
    if (props.scroll) {
      manmanu = {
        width: "100%",
        py: "0px",
        mt: "-1px",
        bgcolor: "white",
        position: "fixed",
        top: 0,
        zIndex: 100,
        animation: "scrollmanu 0.3s linear",
        "@keyframes scrollmanu": {
          from: {
            py: "10px",
          },
          to: {
            py: "0px",
          },
        },
      };
    } else {
      manmanu = {
        width: "100%",
        py: "10px",
        mt: "-1px",
        bgcolor: "custom.white",
        position: "unset",
        zIndex: 100,
        animation: "scrollmanu2 0.3s linear",
        "@keyframes scrollmanu2": {
          from: {
            py: "0px",
          },
          to: {
            py: "10px",
          },
        },
      };
    }
  } else {
    manmanu = {
      width: "100%",
      bgcolor: "custom.dark4",
    };
  }
 
  
  
  const styles = {
    container: {
      width: "100%",
    },
    text: {
      textTransform: "uppercase",
      mt: "25%",
      maxWidth: "900px",
      fontSize: { xs: "27px", md: "60px" },
      fontFamily: "'Roboto', sans-serif",
      fontWeight: "100",
      color: "rgb(255, 255, 255)",
    },
    subtext: {
      mt: "0px",
      maxWidth: "700px",
      fontSize: { xs: "18px", md: "24px" },
      fontFamily: "'Roboto Flex', sans-serif",
      color: "rgb(255, 255, 255)",
      letterSpacing: "1px",
      fontWeight: "100",
    },
   
    
    mainContainer: {
      bgcolor: "#F7F7F7",
      marginTop:"-17px"
    },
  };
  
  return (
    <>
    {/* TopNavBar Section */}
    <Box bgcolor={{ xs: "custom.dark", md: "custom.dark" }}>
      <Container maxWidth="lg">
        <TopNavbar matches={matches} />
      </Container>
    </Box>
    {/* NavBar/main-menu section */}
    <Box sx={manmanu}>
      <Container maxWidth="lg">
        <ManManu scroll={props.scroll} color={"dark2"} />
      </Container>
    </Box>
    {/* hero section-->  */}

    <Box sx={styles.container}>
    <HeroSlider bannerData={bannerData} />
    </Box>
    <Box sx={styles.mainContainer}>
      <Container maxWidth="lg">
        <Box sx={breadcrumbContainer}>
          {" "}
          <Breadcrumb breadcrumb={["home", "projects", "all projects"]} />
        </Box>

        <Box sx={{ flexGrow: 1 }}>
        <Box display="flex" flexDirection={{ xs: "column", md: "row" }} spacing={1}>
{/* First part (main content): 2/3 width */}
<Box flex="3" sx={{marginBottom:"10px"}}>
  
  {/* projects cards container */}
  <Box sx={CardsContainer}>
  {projects.length > 0 ? (
    <Box key={projects[0].id} style={{ textDecoration: 'none'}}>
      <ProjectsCards key={projects[0].id} projects={projects} />
    </Box>
  ) : (
    <>
      <Stack spacing={1}>
        <Skeleton variant="rectangular" width={400} height={200} />
        <Skeleton variant="text" width={300} height={40} sx={{ fontSize: '1rem' }} />
        <Skeleton variant="text" width={300} height={30} sx={{ fontSize: '1rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      </Stack>
      <Stack spacing={1}>
        <Skeleton variant="rectangular" width={400} height={200} />
        <Skeleton variant="text" width={300} height={40} sx={{ fontSize: '1rem' }} />
        <Skeleton variant="text" width={300} height={30} sx={{ fontSize: '1rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      </Stack>
    </>
  )}
</Box>
</Box>
{/* Second part (sidebar): 1/3 width */}
<Box flex="1">
  <Box>
    <Card sx={{ ...cardstyle, mb: "35px", p: "20px" }}>
      <Typography
        gutterBottom
        variant="subtitle1"
        component="div"
        sx={{ ...subheading }}
      >
        Advanced Search
      </Typography>
      <AdvanceSearchProjects />
    </Card>
  </Box>
  {/* contact us form */}
  <Box style={{ position: "sticky", top: 74, height: "500px", }}>
    <ContactUs />
  </Box>
</Box>
</Box>

        </Box>
      </Container>
    </Box>

    {/* MainFooter Section */}
    <Box sx={{ pt: "50px", backgroundColor: "#2c2c2c" }}>
      <Container maxWidth="lg">
        <Box>
          <MainFooter />
        </Box>
      </Container>
      {/* NewsLetter subscription section */}
      <Box sx={{ borderTop: "0.5px solid #666666", py: "20px", mt: "25px" }}>
        <Container>
          <Newsletter />
        </Container>
      </Box>
    </Box>
    {/* Bottom footer section*/}
    <Box sx={{ backgroundColor: "#272727", py: "15px" }}>
      <Container maxWidth="lg">
        <BottomFooter />
      </Container>
    </Box>
    <FooterButton scroll={props.scroll} />
  </>
 )
}

export default AllProjectsView