import React, { useState, useEffect } from "react";
import TopNavbar from "../components/top_navbar";
import ManManu from "../components/main_manu";
import {
  Box,
  Container,
  Typography,
  Stack,
} from "@mui/material";
import Team_img from "../uploads/2022/07/Depositphotos_5F25685857_xl-2015-scaled-e1628186425133.jpg";
import MainFooter from "../components/main_footer";
import BottomFooter from "../components/botttom_footer";
import Newsletter from "../components/newsletter";
import FooterButton from "../components/footer_button";
import Client from "../components/axios";
import Skeleton from "@mui/material/Skeleton";
import AgentCardView from "../components/agentCardView";

const Team = (props) => {
  let [managmentTeam, setManagmentTeam] = useState([]);
  let [advisoryTeam, setAdvisoryTeam] = useState([]);

  let matches = props.matches;
  let manmanu = {};
  if (matches) {
    if (props.scroll) {
      manmanu = {
        width: "100%",
        py: "0px",
        mt: "-1px",
        bgcolor: "white",
        position: "fixed",
        top: 0,
        zIndex: 100,
        animation: "scrollmanu 0.3s linear",
        "@keyframes scrollmanu": {
          from: {
            py: "10px",
          },
          to: {
            py: "0px",
          },
        },
      };
    } else {
      manmanu = {
        width: "100%",
        py: "10px",
        mt: "-1px",
        bgcolor: "custom.white",
        position: "unset",
        zIndex: 100,
        animation: "scrollmanu2 0.3s linear",
        "@keyframes scrollmanu2": {
          from: {
            py: "0px",
          },
          to: {
            py: "10px",
          },
        },
      };
    }
  } else {
    manmanu = {
      width: "100%",
      bgcolor: "custom.dark4",
    };
  }

  let backgroundimg = {
    position: "fixed",
    zIndex: -1,
    top: 0,
    left: 0,
    width: "100vw",
    height: { xs: "60vh", sm: "600px", md: "100vh" },
    backgroundImage: `url("${Team_img}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    Client.post("/cityluxedxb/get_team_members.php")
      .then((response) => {
        if (response.data.errorcode == 200) {
          let doctorList = response.data.propertieslist;

          let management = doctorList.filter((val, index) => {
            if (val.department == "management team") {
              return val;
            }
          });
          setManagmentTeam(management);
          // console.log(management);

          let advisory = doctorList.filter((val, index) => {
            if (val.department == "client advisory team") {
              return val;
            }
          });
          setAdvisoryTeam(advisory);
        } else {
          console.log(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  document.title = "Team-city luxe";

  return (
    <>
      <Box
        sx={{
          position: "relative",
          top: 0,
          left: 0,
          minHeight: {
            xs: "60vh",
            sm: "600px",
            md: "580px",
            lg: "580px",
            xl: "580px",
          },
        }}
      >
        <Box bgcolor={{ xs: "custom.dark", md: "custom.dark" }}>
          <Container maxWidth="lg">
            <TopNavbar matches={matches} />
          </Container>
        </Box>
        <Box sx={manmanu}>
          <Container maxWidth="lg">
            <ManManu scroll={props.scroll} color={"dark2"} />
          </Container>
        </Box>
        <Box sx={backgroundimg}></Box>
      </Box>

      <Box sx={{ backgroundColor: "#f7f7f7", border: "1px solid #fff" }}>
      
        <Container maxWidth="lg" sx={{ my: "27px" }}>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            sx={{
              fontWeight: "700",
              fontSize: "25px",
              color: "#090909FC",
              my: "10px",
              fontFamily: "'Roboto', sans-serif",
            }}
          >
            MANAGEMENT TEAM
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: {
                xs: "center",
                sm: "space-between",
                md: "flex-start",
              },
              flexWrap: "wrap",
            }}
          >
            {managmentTeam.length > 0 ? (
              managmentTeam.map((val, index) => {
                return (
                  <AgentCardView agentDetail={val} agentCarousel={false} />
                );
              })
            ) : (
              <>
                <Stack spacing={1} sx={{ mr: "15px" }}>
                  <Skeleton variant="rectangular" width={250} height={200} />
                  <Skeleton
                    variant="text"
                    width={200}
                    height={40}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={150}
                    height={30}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                </Stack>
                <Stack spacing={1} sx={{ mr: "15px" }}>
                  <Skeleton variant="rectangular" width={250} height={200} />
                  <Skeleton
                    variant="text"
                    width={200}
                    height={40}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={150}
                    height={30}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                </Stack>
                <Stack spacing={1} sx={{ mr: "15px" }}>
                  <Skeleton variant="rectangular" width={250} height={200} />
                  <Skeleton
                    variant="text"
                    width={200}
                    height={40}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={150}
                    height={30}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                </Stack>
              </>
            )}
          </Box>
        </Container>

        <Container maxWidth="lg" sx={{ my: "27px" }}>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            sx={{
              fontWeight: "700",
              fontSize: "25px",
              color: "#090909FC",
              my: "10px",
              fontFamily: "'Roboto', sans-serif",
            }}
          >
            CLIENT ADVISORY TEAM
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: {
                xs: "center",
                sm: "space-between",
                md: "flex-start",
              },
              flexWrap: "wrap",
            }}
          >
            {advisoryTeam.length > 0 ? (
              advisoryTeam.map((val, index) => {
                return (
                  <AgentCardView agentDetail={val} agentCarousel={false} />
                );
              })
            ) : (
              <>
                <Stack spacing={1} sx={{ mr: "15px" }}>
                  <Skeleton variant="rectangular" width={250} height={200} />
                  <Skeleton
                    variant="text"
                    width={200}
                    height={40}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={150}
                    height={30}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                </Stack>
                <Stack spacing={1} sx={{ mr: "15px" }}>
                  <Skeleton variant="rectangular" width={250} height={200} />
                  <Skeleton
                    variant="text"
                    width={200}
                    height={40}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={150}
                    height={30}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                </Stack>
                <Stack spacing={1} sx={{ mr: "15px" }}>
                  <Skeleton variant="rectangular" width={250} height={200} />
                  <Skeleton
                    variant="text"
                    width={200}
                    height={40}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={150}
                    height={30}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                </Stack>
              </>
            )}
          </Box>
        </Container>
      </Box>

      {/* main foooter  */}
      <Box sx={{ pt: "50px", backgroundColor: "#2c2c2c" }}>
        <Container maxWidth="lg">
          <Box>
            <MainFooter />
          </Box>
        </Container>
        <Box sx={{ borderTop: "0.5px solid #666666", py: "20px", mt: "25px" }}>
          <Container>
            <Newsletter />
          </Container>
        </Box>
      </Box>
      {/* bottom footer  */}
      <Box sx={{ backgroundColor: "#272727", py: "15px" }}>
        <Container maxWidth="lg">
          <BottomFooter />
        </Container>
      </Box>
      {/* footer top scroll button  */}
      <FooterButton scroll={props.scroll} />
    </>
  );
};

export default Team;
