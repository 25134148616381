import React from "react";
import Slider from 'react-slick';
import Stack from '@mui/material/Stack';
import CardView from "./card_view";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: 'red' }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "green", }}
            onClick={onClick}
        />
    );
}

var settings = {

    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    cssEase: "linear",
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
            }
        }
    ]
};

const SimilarPropertiesSlider = (props) => {
    return (
        <>

            <Slider {...settings}>
                {
                    props.properties.map((value, index) => {
                        return <div key={index}>
                            <Stack sx={{ mb: "0px", mx: "5px", }}>
                                <CardView key={index} listitem={value} cardwidth={'100%'} />
                            </Stack>
                        </div>
                    })
                }
            </Slider>

        </>
    )
}

export default SimilarPropertiesSlider;