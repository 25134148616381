import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import logo from "../assests/logo_img/cl-logo.png";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Link } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

const navItems = [  "Home",  "Properties", "projects",  "Team",  "List Property", "blogs", "Contact",   ];
// const navItems = ["Home", "Properties", "Team", "List Property", "Contact"];

function ManManu(props) {
  const [value, setValue] = useState(0);
  const [open, setDrower] = useState(false);
  let location = useLocation();
  
  let color = {};
  if (props.color) {
    color = {
      color: "custom.dark3",
    };
  } else {
    color = {
      color: props.scroll ? "custom.dark3" : "custom.white",
    };
  }


  let linkstyle = {
    color: "inherit",
    textDecoration: "inherit",
  };

  const theme = useTheme();
  let matches = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    if (matches) {
      let pathname = location.pathname;
      let activeTabIndex = navItems.findIndex(
        (val) =>
          val.toLowerCase() ===
          (pathname === "/" ? "home" : pathname.substring(1).toLowerCase())
      );
      setValue(activeTabIndex);
    }
  }, [location, matches]);

  return (
    <>
      <AppBar
        position="relative"
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          textAlign: "center",
        }}
      >
        <Toolbar style={{ padding: "0px" }}>
          <Box>
            <Link to="/" style={linkstyle}>
              <img
                src={logo}
                alt="logo"
                style={{ width: "180px", height: "50px" }}
              />
            </Link>
          </Box>

          <Box sx={{ marginLeft: "auto" }}>
            {matches ? (
              <Tabs
                value={value}
                sx={{ py: "4px" }}
                textColor="primary"
                indicatorColor="none"
                aria-label="nav tabs example"
              >
                {navItems.map((val, index) => (
                  <Tab
                    key={index}
                    label={val}
                    component={RouterLink}
                    to={
                      val.toLowerCase() === "home"
                        ? "/"
                        : "/" + val.toLowerCase()
                    }
                    sx={{
                      fontSize: "15px",
                      fontFamily: "'Nunito Sans', sans-serif",
                      fontWeight: "700",
                      transition: "0.4s",
                      minHeight: "0px",
                      height: "35px",
                      color: props.color
                        ? "custom.dark3"
                        : props.scroll
                        ? "custom.dark3"
                        : "custom.white",
                      "&:hover": {
                        backgroundColor: "custom.lightwheat",
                        color: "custom.white",
                      },
                    }}
                  />
                ))}
              </Tabs>
            ) : (
              <>
                <IconButton>
                  {" "}
                  <MenuIcon
                    sx={{ color: "custom.white" }}
                    onClick={() => {
                      setDrower(!open);
                    }}
                  />{" "}
                </IconButton>
              </>
            )}
          </Box>
        </Toolbar>

        <SwipeableDrawer
          anchor={"left"}
          open={open}
          onClose={() => setDrower(!open)}
          // onOpen={toggleDrawer(anchor, true)}
        >
          <List
            component="nav"
            sx={{ backgroundColor: "custom.dark4", height: "100%" }}
          >
            {navItems.map((val, index) => {
              return (
                <>
                  {" "}
                  <ListItemText
                    primary={
                      <Link
                        to={
                          val.toLowerCase() == "home"
                            ? "/"
                            : "/" + val.toLowerCase()
                        }
                        style={linkstyle}
                      >
                        {val}
                      </Link>
                    }
                    key={index}
                    onClick={() => {
                      setDrower(!open);
                    }}
                    sx={{
                      px: "30px",
                      my: "10px",
                      fontSize: "16px",
                      fontWeight: "550",
                      color: "custom.white",
                      textTransform: "uppercase",
                    }}
                  />
                  <Divider color="#ffffff" />
                </>
              );
            })}
          </List>
        </SwipeableDrawer>
      </AppBar>
    </>
  );
}

export default ManManu;
