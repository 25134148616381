
import { useState, useEffect } from "react";
import Client from "../axios";
import Cookies from "js-cookie";

const LoginHook = () => {
    const [data, setData] = useState(null);
    // Get the value of a specific cookie
    const name = Cookies.get('userName');
    const password = Cookies.get('userPassword');

    Client.post('/cityluxedxb/login_action.php', {
        "username": name,
        "password": password,
    })
        .then((response) => {
            if (response.data.errorcode == 200) {

                setData(true);
            } else {

                setData(false);
            }

        })
        .catch(err => {
            console.log(err);
            setData(false);

        });
    return data;

};

export default LoginHook;