import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Sidebar from '../components/sidebar';
import Dashnavbar from '../components/dashnavbar';

import { Box, Stack } from '@mui/material';
import DataTable from '../components/allProjects/list_of_projects';
import UserContextCurrency from '../../../context/currencyChange';
import LoginHook from '../../../components/customHooks/loginHook';

const AllProjects = () => {
    const { currencySign, setCurrencySign, value, setValue } = useContext(UserContextCurrency);
    let navigate = useNavigate();

    let cookie = LoginHook();

    if (cookie == false) {
        navigate("/adminpanel/login/login");
    }

    return (
        <>
            {
                cookie &&

                <Box sx={{ display: 'flex', minHeight: '100vh', }}>
                    <Box sx={{ minHeight: "100vh", width: "20%", px: "10px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                        <Sidebar />
                    </Box>
                    <Box sx={{ minHeight: "100vh", width: "80%", backgroundColor: "" }}>
                        <Box sx={{ boxShadow: "rgba(0, 0, 0, 0.1) -4px 9px 25px -6px" }}>
                            <Dashnavbar />
                        </Box>
                        <Box>
                            <Box sx={{ display: 'flex', justifyContent: "space-around", alignItems: 'center', }}>

                            </Box>

                            <Box>

                                <Stack sx={{ width: "95%", my: "10px", mx: "auto" }}>
                                    <DataTable />
                                </Stack>



                            </Box>

                        </Box>
                    </Box>
                </Box>

            }

        </>
    )
}

export default AllProjects