import React, { useState } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Facebook from "@mui/icons-material/Facebook";
import Instagram from "@mui/icons-material/Instagram";
import LinkedIn from "@mui/icons-material/LinkedIn";
import YouTube from "@mui/icons-material/YouTube";
import { TextField } from "@mui/material";
import Client from "./axios";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";

const footerHeading = {
  fontFamily: "'Nunito Sans', sans-serif",
  fontSize: "17px",
  textTransform: "uppercase",
  fontWeight: "700",
  color: "#eeeeee",
};

const footerSubheading = {
  pt: "5px",
  color: "#bbbbbb",
  fontFamily: "'Nunito Sans', sans-serif",
  fontSize: "13px",
};

let mainfooterdiv = {
  bottom: "10px",
  my: "10px",
  mx: { xs: "auto", md: "0" },
};

const IconDiv = {
  color: "#bbbbbb",
  transition: "0.3s",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItem: "center",
  "&:hover": {
    color: "cutome.lightwheat",
  },
};

const button = {
  color: "rgb(44, 44, 44)",
  bgcolor: "#eeeeee",
  transition: "0.3s",
  fontWeight: "500",
  border: "1px solid #ffffff",
  px: "40px",
  borderRadius: "0px",
  fontSize: { xs: "9px", md: "15px" },
  py: "6px ",
  "&:hover": {
    bgcolor: "transparent",
    color: "#eeeeee",
    border: "1px solid #e1d3bc",
  },
};

const Newsletter = () => {
  const [newsletter, setnewsletter] = useState("");

  function ValidateEmail() {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newsletter)) {
      subscribe();
    }
    setAlertMessage("Not Valid Email");
    handleClick({ vertical: "top", horizontal: "right" });
    setLoading(false);
  }

  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = state;
  let handleClick = (newState) => {
    setState({ open: true, ...newState });
  };
  let handleClose = () => {
    setState({ ...state, open: false });
  };
  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
        sx={{ color: "red" }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const subscribe = () => {
    console.log(newsletter);
    if (newsletter !== "") {
      setLoading(true);
      Client.post("/cityluxedxb/newsletter.php", {
        email: newsletter,
      })
        .then((response) => {
          if (response.data.errorcode == "200") {
            setAlertMessage("Successfully Subscribed!");
            handleClick({ vertical: "top", horizontal: "right" });
            setLoading(false);
          } else if (response.data.errorcode == "422") {
            setAlertMessage("Email is already subscribed!");
            handleClick({ vertical: "top", horizontal: "right" });
            setLoading(false);
          } else {
            setAlertMessage("Something Wrong Try Again!");
            handleClick({ vertical: "top", horizontal: "right" });
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      setAlertMessage("Please Enter All Details");
      handleClick({ vertical: "top", horizontal: "right" });
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={alertMessage}
        key={vertical + horizontal}
        action={action}
      ></Snackbar>

      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={{ xs: "column", md: "row" }}
        mt={{ xs: "20px", md: "0px" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: { xs: "100%", md: "50%" },
          }}
        >
          <Stack
            sx={{ width: { xs: "100%", md: "60%" } }}
            textAlign={{ xs: "center", md: "left" }}
          >
            <Typography variant="body2" sx={{ ...footerHeading, mb: "5px" }}>
              <Link
                to="/contact"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                {" "}
                CONTACT CITY LUXE{" "}
              </Link>
            </Typography>
            <Typography
              variant="body2"
              sx={{
                ...footerSubheading,
                textTransform: "uppercase",
                mb: "3px",
              }}
            >
              City Luxe Real Estate
            </Typography>
            <Typography variant="body2" sx={footerSubheading}>
              Suite 1113, The Burlington
            </Typography>
            <Typography variant="body2" sx={footerSubheading}>
              Business Bay, DXB
            </Typography>
            <Typography variant="body2" sx={footerSubheading}>
              E: sales@cityluxedxb.com
            </Typography>
            <Typography variant="body2" sx={footerSubheading}>
              M: +971 55 423 5695
            </Typography>
          </Stack>
        </Box>

        <Box
          sx={{
            borderLeft: { xs: "0px", md: "0.5px solid #666666" },
            width: { xs: "100%", md: "50%" },
            px: { xs: "0px", md: "50px" },
            mt: { xs: "15px", md: "0px" },
          }}
        >
          <Typography
            variant="body2"
            sx={{
              ...footerHeading,
              mb: "5px",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            subscribe to our newsletter
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
              alignItems: "center",
              my: "10px",
            }}
          >
            <Stack sx={{ width: "65%", mb: { xs: "20px", md: "0px" } }}>
              <TextField
                value={newsletter}
                onChange={(e) => setnewsletter(e.target.value)}
                id="newsletter"
                size="small"
                placeholder="Enter your email here*"
                sx={{ backgroundColor: "#fff" }}
              />
            </Stack>
            <Stack sx={{ width: "30%" }}>
              <LoadingButton
                sx={{ ...button }}
                onClick={ValidateEmail}
                loading={loading}
                loadingPosition="end"
              >
                Subscribe
              </LoadingButton>
            </Stack>
          </Box>

          <Stack
            sx={{
              borderBottom: "0.5px solid #666666",
              my: "20px",
              width: { xs: "100%", md: "80%" },
            }}
          ></Stack>

          <Box
            sx={{
              ...mainfooterdiv,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Stack sx={{ width: { xs: "100%", md: "30%", m: "0px" } }}>
              <Typography variant="body2" sx={{ ...footerHeading }}>
                Follow Us
              </Typography>
            </Stack>
            <Stack
              direction="row"
              sx={{ width: { xs: "100%", md: "40%" } }}
              justifyContent="space-between"
              alignItems="center"
            >
              <a href="https://www.facebook.com/cityluxedxb/">
                <Box sx={IconDiv}>
                  <Facebook sx={{ fontSize: "20px" }} />
                </Box>
              </a>

              <a href="https://twitter.com/luxe_city" target="_blank">
                <Box sx={IconDiv}>
                  <svg
                    viewBox="0 0 24 24"
                    aria-label="Twitter"
                    width={16}
                    role="img"
                    class="your-icon-class"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g>
                      <path
                        d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
                        fill="#bbbbbb"
                      ></path>
                    </g>
                  </svg>
                </Box>
              </a>

              <a href="https://www.linkedin.com/in/cityluxedxb" target="_blank">
                <Box sx={IconDiv}>
                  <LinkedIn sx={{ fontSize: "20px" }} />
                </Box>
              </a>

              <a
                href="https://www.youtube.com/channel/UC0L6TDApvFtl_F_bIlVtQNw"
                target="_blank"
              >
                <Box sx={IconDiv}>
                  <YouTube sx={{ fontSize: "20px" }} />
                </Box>
              </a>
              <a href="https://www.instagram.com/cityluxe.dxb/" target="_blank">
                <Box sx={IconDiv}>
                  <Instagram sx={{ fontSize: "20px" }} />
                </Box>
              </a>
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Newsletter;
