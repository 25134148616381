import React from "react";
import Slider from 'react-slick';
import Stack from '@mui/material/Stack';
import AgentCardView from "./agentCardView";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: 'red' }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "green", }}
            onClick={onClick}
        />
    );
}

var settings = {

    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    cssEase: "linear",
    responsive: [

        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },

        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false,
            }
        }
    ]
};

const AgentDetailsSlider = (props) => {
    // console.log(props.properties);
    return (
        <>

            <Slider {...settings}>
                {
                    props.agent.map((val, index) => {
                        return <div key={index}>
                            <Stack sx={{ mb: "0px", mx: "5px", }}>
                                <AgentCardView agentDetail={val} agentCarousel={true} />
                            </Stack>
                        </div>
                    })
                }
            </Slider>

        </>
    )
}

export default AgentDetailsSlider;