import React, { useState, useEffect } from "react";
import { cardstyle } from "../theme/theme";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import TopNavbar from "../components/top_navbar";
import ManManu from "../components/main_manu";
import { Box, Container, Card, Typography } from "@mui/material";
import home_pro_img from "../uploads/2020/07/house_nice_2-12.jpg";
import Breadcrumb from "../components/bread_crumb";
import CardView from "../components/card_view";
import Advance_search from "../components/advance_search";
import MainFooter from "../components/main_footer";
import BottomFooter from "../components/botttom_footer";
import Newsletter from "../components/newsletter";
import FooterButton from "../components/footer_button";
import Pagination from "@mui/material/Pagination";
import Client from "../components/axios";
import OurListings from "../components/our_listings";

const ListingUnitType = (props) => {
  const [totalRecords, setTotalRecords] = useState([]);
  const [queryParameters] = useSearchParams();
  let type = queryParameters.get("type");
  const [record, setRecord] = useState([]);
  const [selectcount, setselectcount] = useState(0);
  const [totalcount, settotalcount] = useState(0);
  const [refreshed, setRefreshed] = useState(false);

  useEffect(() => {
    Client.post("/cityluxedxb/listing_property_unit_type.php", {
      property_type: type,
    })
      .then((response) => {
        if (response.data.errorcode == 200) {
          let totallist = response.data.propertieslist;
          setTotalRecords(totallist);
          let totalRecord = totallist.length / 10;
          let decimal_value = totalRecord - Math.floor(totalRecord);
          // console.log(decimal_value);
          if (decimal_value > 0) {
            settotalcount(Math.floor(totalRecord) + 1);
          } else {
            settotalcount(totalRecord);
          }
        } else {
          console.log(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [type]);

 

  let allproperties = record.map((value, index) => (
    <CardView key={index} listitem={value} cardwidth={"49%"} />
  ));
  const updateRecordsFiltered = () => {
    window.scrollTo(0, 300);
    let index = selectcount * 10;
    let filterProperty = [];
    let endcondtion = 0;
    if (totalRecords.length >= index + 10) {
      endcondtion = index + 10;
    } else {
      endcondtion = totalRecords.length;
    }
    for (index; index < endcondtion; index++) {
      let element = totalRecords[index];
      // console.log(element);
      filterProperty.push(element);
    }
    setRecord(filterProperty);
    // console.warn("unit type filter property",filterProperty);
    // console.log(totalRecords.length);
  };

  useEffect(() => {
    updateRecordsFiltered();
  }, [totalRecords, selectcount]);

  let matches = props.matches;
  let manmanu = {};
  if (matches) {
    if (props.scroll) {
      manmanu = {
        width: "100%",
        py: "0px",
        mt: "-1px",
        bgcolor: "white",
        position: "fixed",
        top: 0,
        zIndex: 3,
        animation: "scrollmanu 0.3s linear",
        "@keyframes scrollmanu": {
          from: {
            py: "10px",
          },
          to: {
            py: "0px",
          },
        },
      };
    } else {
      manmanu = {
        width: "100%",
        py: "10px",
        mt: "-1px",
        bgcolor: "custom.white",
        position: "unset",
        zIndex: 3,
        animation: "scrollmanu2 0.3s linear",
        "@keyframes scrollmanu2": {
          from: {
            py: "0px",
          },
          to: {
            py: "10px",
          },
        },
      };
    }
  } else {
    manmanu = {
      width: "100%",
      bgcolor: "custom.dark4",
    };
  }
  let backgroundimg = {
    position: "fixed",
    zIndex: -1,
    top: 0,
    left: 0,
    width: "100vw",
    height: { xs: "60vh", md: "100vh" },
    backgroundImage: `url("${home_pro_img}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const heading = {
    textTransform: "capitalize",
    fontWeight: "550",
    fontSize: "20px",
    lineHeight: "25px",
    color: "cutome.dark",
    cursor: "pointer",
    transition: "0.5s",
    "&:hover": {
      color: "cutome.lightwheat",
    },
  };
  const subheading = {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "17px",
    textTransform: "uppercase",
    fontWeight: "700",
    color: "#434953",
    mb: "10px",
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          top: 0,
          left: 0,
          minHeight: { xs: "60vh", md: "65vh", lg: "62vh", xl: "50vh" },
        }}
      >
        <Box bgcolor={{ xs: "custom.dark", md: "custom.dark" }}>
          <Container maxWidth="lg">
            <TopNavbar matches={matches} />
          </Container>
        </Box>
        <Box sx={manmanu}>
          <Container maxWidth="lg">
            <ManManu scroll={props.scroll} color={"dark2"} />
          </Container>
        </Box>
        <Box sx={backgroundimg}></Box>
      </Box>
      <Box sx={{ backgroundColor: "#f7f7f7", border: "1px solid #fff" }}>
        <Container maxWidth="lg" sx={{ pt: "15px" }}>
          <Breadcrumb breadcrumb={["home", type]} />
        </Container>
        <Container
          maxWidth="lg"
          sx={{
            my: "27px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "70%" },
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              height: "fit-content",
            }}
          >
            {record.length > 0 ? (
              allproperties
            ) : (
              <>
                <Stack spacing={1}>
                  <Skeleton variant="rectangular" width={400} height={200} />
                  <Skeleton
                    variant="text"
                    width={300}
                    height={40}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={300}
                    height={30}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                </Stack>
                <Stack spacing={1}>
                  <Skeleton variant="rectangular" width={400} height={200} />
                  <Skeleton
                    variant="text"
                    width={300}
                    height={40}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={300}
                    height={30}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                </Stack>
              </>
            )}

            <Card sx={{ ...cardstyle, width: "100%", mb: "35px", py: "10px" }}>
              <Stack spacing={2} alignItems="center">
                {/* <Pagination count={10} shape="square" /> */}
                <Pagination
                  className="propertyPagination"
                  count={totalcount}
                  onChange={(e, value) => {
                    setRecord([]);  
                    setselectcount({ page: value - 1 }); 
                  }}
                  size="small"
                  variant="outlined"
                  shape="rounded"
                />
              </Stack>
            </Card>
          </Box>
          <Box sx={{ width: { xs: "100%", md: "28%", height: "fit-content" } }}>
            <Card sx={{ ...cardstyle, mb: "35px", p: "30px" }}>
              <Typography
                gutterBottom
                variant="subtitle1"
                component="div"
                sx={{ ...subheading }}
              >
                Advanced Search
              </Typography>
              <Advance_search />
            </Card>
            <Card sx={{ ...cardstyle, mb: "35px", p: "30px" }}>
              <Typography
                gutterBottom
                variant="subtitle1"
                component="div"
                sx={{ ...subheading }}
              >
                Our Listings
              </Typography>

              <OurListings />
            </Card>
          </Box>
        </Container>
      </Box>
      {/* main foooter  */}
      <Box sx={{ pt: "50px", backgroundColor: "#2c2c2c" }}>
        <Container maxWidth="lg">
          <Box>
            <MainFooter />
          </Box>
        </Container>
        <Box sx={{ borderTop: "0.5px solid #666666", py: "20px", mt: "25px" }}>
          <Container>
            <Newsletter />
          </Container>
        </Box>
      </Box>
      {/* bottom footer  */}
      <Box sx={{ backgroundColor: "#272727", py: "15px" }}>
        <Container maxWidth="lg">
          <BottomFooter />
        </Container>
      </Box>
      {/* footer top scroll button  */}
      <FooterButton scroll={props.scroll} />
    </>
  );
};
export default ListingUnitType;
