import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import Stack from '@mui/material/Stack';

const settings = {
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    centerMode: true,
    autoplaySpeed: 0,
    centerPadding: "60px",
    cssEase: "linear",
    pauseOnHover: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        }
    ]
};

const Affiliations = () => {
    const [affiliationImages, setAffiliationImages] = useState([]);

    useEffect(() => {
        let isMounted = true;
        axios.get('https://cityluxedxb.com/adminpanel/getAffiliation.php')
            .then(({ data }) => {
                // console.warn("API response", data);
                    if (isMounted) {
                    setAffiliationImages(data.affiliation);
                }
            })
            .catch(error => {
                console.error('Error fetching affiliation images', error);
            });
    
        return () => {
            isMounted = false;
        };
    }, []);
    return (
        <Slider {...settings}>
        {affiliationImages.map((val, index) => (
            <div key={index}>
                <Stack sx={{ mx: '20px' }}>
                    <img src={val} alt="developers" width='100%' className="react-item" />
                </Stack>
            </div>
        ))}
    </Slider>
    );
};

export default Affiliations;