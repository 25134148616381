import React, { useState, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { bedroom, Price } from "../../components/subcomponent/all_values_search";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import InputSearchKeywordList from "../../components/customHooks/inputSearchKeywordList";
import useKeywordData from "./useKeywordData";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { SearchContext } from "./SearchContext";
import { button } from "../../theme/theme";
const filter = createFilterOptions();
const AdvanceSearchProjects = (props) => {
  const navigate = useNavigate(); 
  const [serchProperty, setSearchProperty] = useState([]);
  const [catList, setCatList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState([]);
  const [category, setCategory] = useState("");
  const [developer, setDeveloper] = useState("");
  const [minBed, setMinBed] = useState("");
  const [maxBed, setMaxBed] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const { record, setRecord, searchStatus, setSearchStatus, totalCount, setTotalCount } = useContext(SearchContext);
  const [selectcount, setselectcount] = useState({ page: 0 });
  const [totalcount, settotalcount] = useState(0);
  const [highlow, setHighLow] = useState({ sorting: 'none' });
  const [advanceSearchKeywords, setAdvanceSearchKeywords] = useState({
    searchKeyword: [],
    category: '',
    developer: '',
    minBed: '',
    maxBed: '',
    minPrice: '',
    maxPrice: '',
  });
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const projectsKeyword = useKeywordData();
  useEffect(() => {
  setSearchProperty(projectsKeyword);
  }, [projectsKeyword]);


  const { vertical, horizontal, open } = state;
  const handleSearchClick = () => {
    if (searchKeyword.length === 0 && category === "" && developer=== "") {
      setAlertMessage("Please select at least one detail");
      handleClick({ vertical: "top", horizontal: "right" });
    } else {
      const searchValues = searchKeyword.map((item) => item.label);
      const newKeywords = {
        searchKeyword: searchValues,
        category: category,
        developer: developer,
        minBed: minBed,
        maxBed: maxBed,
        minPrice: minPrice,
        maxPrice: maxPrice,
      };
      props.sendKeywords(newKeywords);
    }
  };
  let handleClick = (newState) => {
    setState({ open: true, ...newState });
  };
  let handleClose = () => {
    setState({ ...state, open: false });
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
        sx={{ color: "red" }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

 

  let [data] = InputSearchKeywordList();

  useEffect(() => {
    window.scrollTo(2, 2);
    if (data !== null) {
      setSearchProperty([
        ...data.community,
        ...data.property_name,
        ...data.emirate,
      ]);
      setCatList(data.unit_type);
      setTypeList(data.ad_type);
    }
  }, [data]);
  const findMatchRecords = (event) => {
    event.preventDefault();
    setLoading(true);

    const searchValues = searchKeyword.map((item) => item.label);
    const advanceSearchKeywords = {
      searchKeyword: searchValues,
      category,
      developer,
      minBed,
      maxBed,
      minPrice,
      maxPrice,
    };

    axios.post('/cityluxedxb/advance_search_projects.php', {
      advanceSearchKeywords,
      highlow,
      selectcount,
    })
      .then((response) => {
        if (response.data.errorcode === '200' && response.data.totalRecords > 0) {
          const listOfProperty = response.data.propertieslist;
          const totalRecords = response.data.totalRecords;
          setRecord(listOfProperty);
          const totalRecord = totalRecords / 10;
          const decimal_value = totalRecord - Math.floor(totalRecord);
          setTotalCount(decimal_value > 0 ? Math.floor(totalRecord) + 1 : Math.floor(totalRecord));
          setSearchStatus('success');
          navigate('/search-result-projects');
        } else if (response.data.errorcode === '400') {
          setSearchStatus('not found');
          navigate('/search-result-projects');

        } else {
          setSearchStatus('error');
          navigate('/search-result-projects');

        }
      })
      .catch((err) => {
        console.log(err);
        setSearchStatus('error');
      })
      .finally(() => {
        setLoading(false);
      });
  };


 

  const selectitem = {
    backgroundColor: "#ffffff",
    color: "#8593a9",
    borderRadius: "0px",
    cursor: "pointer",
    "&:hover": {
      border: "0px",
    },
  };

  const Formcontrol = {
    minWidth: "100%",
    mb: "10px",
    padding: "10px",
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={alertMessage}
        key={vertical + horizontal}
        action={action}
      ></Snackbar>
      <Box>
        <Stack>
          <FormControl
            className="formAdvanceSearch"
            sx={{ ...Formcontrol }}
            size="small"
          >
            <Autocomplete
              disablePortal
              multiple
              size="small"
              id="combo-box-demo"
              value={searchKeyword}
              onChange={(event, newValue) => {
                setSearchKeyword(newValue);
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                const isExisting = options.some(
                  (option) => inputValue === option.label
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({ inputValue, label: `Add "${inputValue}"` });
                }
                return filtered;
              }}
              options={projectsKeyword.project_title.map((title) => ({
                label: title,
              }))}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.label;
              }}
              className="autocompliteInput search_property"
              renderOption={(props, option) => (
                <li {...props}>{option.label}</li>
              )}
              sx={{ ...selectitem }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Projects" />
              )}
            />
          </FormControl>
          <FormControl
            className="formAdvanceSearch"
            sx={{ ...Formcontrol }}
            size="small"
          >
            <Autocomplete
              size="small"
              id="combo-box-demo"
              className="autocompliteInput search_categories"
              onChange={(e, val) => setCategory(val ? val.label : "")}
              options={catList}
              sx={selectitem}
              renderInput={(params) => (
                <TextField {...params} placeholder="Categories" />
              )}
            />
          </FormControl>
          <FormControl
            className="formAdvanceSearch"
            sx={{ ...Formcontrol }}
            size="small"
          >
            <Autocomplete
              disablePortal
              size="small"
              id="combo-box-demo"
              className="autocompliteInput search_type"
              onChange={(e, val) =>
                val ? setDeveloper(val.label) : setDeveloper("")
              }
              options={projectsKeyword.developer_name.map((title) => ({
                label: title,
              }))}
              sx={selectitem}
              renderInput={(params) => (
                <TextField {...params} placeholder="Developer" />
              )}
            />
          </FormControl>

          <FormControl
            className="formAdvanceSearch"
            sx={{ ...Formcontrol }}
            size="small"
          >
            <Autocomplete
              disablePortal
              size="small"
              id="combo-box-demo"
              className="autocompliteInput search_min_bed"
              onChange={(e, val) =>
                val ? setMinBed(val.label) : setMinBed("")
              }
              options={bedroom}
              sx={selectitem}
              renderInput={(params) => (
                <TextField {...params} placeholder="Min Bed" />
              )}
            />
          </FormControl>
          <FormControl
            className="formAdvanceSearch"
            sx={{ ...Formcontrol }}
            size="small"
          >
            <Autocomplete
              disablePortal
              size="small"
              id="combo-box-demo"
              className="autocompliteInput search_max_bed"
              options={bedroom}
              onChange={(e, val) =>
                val ? setMaxBed(val.label) : setMaxBed("")
              }
              sx={selectitem}
              renderInput={(params) => (
                <TextField {...params} placeholder="Max Bed" />
              )}
            />
          </FormControl>
          <FormControl
            className="formAdvanceSearch"
            sx={{ ...Formcontrol }}
            size="small"
          >
            <Autocomplete
              disablePortal
              size="small"
              id="combo-box-demo"
              className="autocompliteInput search_min_price"
              onChange={(e, val) =>
                val ? setMinPrice(val.label) : setMinPrice("")
              }
              options={Price}
              sx={selectitem}
              renderInput={(params) => (
                <TextField {...params} placeholder="Min Price" />
              )}
            />
          </FormControl>
          <FormControl
            className="formAdvanceSearch"
            sx={{ ...Formcontrol }}
            size="small"
          >
            <Autocomplete
              disablePortal
              size="small"
              id="combo-box-demo"
              className="autocompliteInput search_max_price"
              onChange={(e, val) =>
                val ? setMaxPrice(val.label) : setMaxPrice("")
              }
              options={Price}
              sx={selectitem}
              renderInput={(params) => (
                <TextField {...params} placeholder="Max Price" />
              )}
            />
          </FormControl>

          <Button
            sx={button}
            onClick={findMatchRecords}
            loading={loading}
            loadingPosition="end"
          >
            Search
          </Button>
        </Stack>
      </Box>
    </>
  );
};
export default AdvanceSearchProjects;
