import React from "react";

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const FooterButton = (props) => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
       const IconDiv = {
        padding: '10px 10px',
        display: 'flex',
        justifyContent: 'center',
        alignItem: 'center',
        bgcolor: 'custom.white2',
        color: 'custom.dark',
        transition: '0.3s',
        cursor: 'pointer',
        '&:hover': {
            color: 'cutome.lightwheat',
        },
    }

    let mainfooterdiv = {};
    if (props.scroll) {
        mainfooterdiv = {
            width: '120px',
            position: 'fixed',
            bottom: '10px',
            right: 10,
            animation: "footerbuttonbox 0.2s linear",
            "@keyframes footerbuttonbox": {
                from: {
                    right: -200
                },
                to: {
                    right: 0,

                },
            }
        }

    } else {
        mainfooterdiv = {
            width: '140px',
            position: 'fixed',
            right: -200,
            bottom: '10px',
            animation: "footerbuttonbox2 0.2s linear",
            "@keyframes footerbuttonbox2": {
                from: {
                    right: 0
                },
                to: {
                    right: -200,
                },
            }
        }
    }
 
    const phoneNumber = '+971554235695';
    const handleWhatsaAppClick = () =>{
        window.open(`https://wa.me/${phoneNumber}`, '_blank');
    }


    return (

        <Box sx={mainfooterdiv}>
            <Stack direction="row" justifyContent='space-around'>
                <Box sx={IconDiv} onClick={handleWhatsaAppClick} >
                    <WhatsAppIcon sx={{ fontSize: '16px' }} />
                </Box> 
                <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=sales@cityluxedxb.com" target="_blank" style={{
                    textDecoration: "none", color: "transparent"
                }} >
                    <Box sx={IconDiv} >
                        <EmailIcon sx={{ fontSize: '16px' }} />
                    </Box>
                </a>
                
                <Box sx={IconDiv} onClick={scrollToTop} >
                    <ExpandLessIcon sx={{ fontSize: '16px' }} />
                </Box>
            </Stack>
        </Box>

    )
}

export default FooterButton;