import { createTheme } from "@mui/material/styles";

const colors = {
  dark: "#222222",
  dark2: "#333333",
  dark3: "#737373",
  dark4: "#282d33",
  // lightwheat: '#e1d3bc',
  lightwheat: "#d7c3a8",
  text: "#5c727d",
  white: "#ffffff",
  white2: "#E8E8E8",
  white3: "#D1D1D1",
  black: "#000000",
};

const Theme = createTheme({
  palette: {
    primary: {
      // light: '#757ce8',
      main: "#e1d3bc",
      // dark: '#002884',
      // contrastText: '#fff',
    },

    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },

    cutome: {
      dark: colors.dark,
      dark2: colors.dark2,

      lightwheat: colors.lightwheat,
      text: colors.text,
      white: colors.white,
      black: colors.black,
    },
    custom: {
      dark: colors.dark,
      dark2: colors.dark2,
      dark3: colors.dark3,
      dark4: colors.dark4,
      lightwheat: colors.lightwheat,
      text: colors.text,
      white: colors.white,
      white2: colors.white2,
      white3: colors.white3,
      black: colors.black,
    },
  },
});

const cardstyle = {
  borderRadius: "0px",
  border: "1px solid #f1f8ff",
  boxShadow: "0 10px 31px 0 rgb(7 152 255 / 9%)",
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
  },
};
const stickycardstyle = {
  borderRadius: "0px",
  border: "1px solid #f1f8ff",
  boxShadow: "0 10px 31px 0 rgb(7 152 255 / 9%)",
  backgroundColor: "#fff",
  position: "sticky",
  "&:hover": {
    backgroundColor: "#fff",
  },
};
const buttonStyle = {
  padding: "10px 40px",
  mt: 3,
  mr: 1,
  borderRadius: "0",
  backgroundColor: "#d7c3a8",
  textTransform: "uppercase",
  color: "#4c4f54",
  "&:hover": {
    color: "#000",
    backgroundColor: "#4c4f54",
  },
};
const heading = {
  textTransform: "uppercase",
  fontWeight: "600",
  fontFamily: "'Roboto', sans-serif",
  fontSize: "28px",
  color: "black",
  cursor: "pointer",
  lineHeight: "1em",
  marginBottom: "20px",
};

const subheading = {
  fontSize: "17px",
  fontFamily: "'Nunito Sans', sans-serif",
  lineHeight: "1.3em",
  textTransform: "uppercase",
  fontWeight: "700",
  color: "#434953",
  mb: "10px",
};

const button = {
  color: "#4c4f54",
  bgcolor: "custom.lightwheat",
  transition: "0.3s",
  fontFamily: "'Nunito Sans', sans-serif",
  fontWeight: "700",
  border: "1px solid #ffffff",
  px: "57px",
  borderRadius: "0px",
  fontSize: { xs: "9px", md: "13px" },
  py: "10px ",
  "&:hover": {
    bgcolor: "#4c4f54",
    color: "custom.lightwheat",
  },
};

const CardsContainer = {
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  gap: "8px",
  flexWrap: " row-wrap",
  marginRight: "10px",
  "@media (max-width: 768px)": {
    display: "block",
  },
};

const  breadcrumbContainer= {
    height: "auto",
    width: "100%",
    marginTop: "15px",
    marginBottom: "15px",
    paddingTop:"15px"
  } 
 const text= {
    textTransform: "uppercase",
    mt: "25%",
    maxWidth: "900px",
    fontSize: { xs: "27px", md: "60px" },
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "100",
    color: "rgb(255, 255, 255)",
  }

 const subtext= {
    mt: "0px",
    maxWidth: "700px",
    fontSize: { xs: "18px", md: "24px" },
    fontFamily: "'Roboto Flex', sans-serif",
    color: "rgb(255, 255, 255)",
    letterSpacing: "1px",
    fontWeight: "100",
  }

export {
  Theme,
  cardstyle,
  stickycardstyle,
  buttonStyle,
  heading,
  colors,
  subheading,
  button,
  CardsContainer, breadcrumbContainer, text, subtext
};
