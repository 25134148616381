import React from 'react';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { cardstyle } from "../../theme/theme";
import DefaultImage from "../../image/defaultImg.jpeg";
import SingleBlogDetails from './SingleBlogDetails';

const heading = {
  textTransform: "capitalize",
  fontFamily: "'Nunito Sans', sans-serif",
  fontWeight: "700",
  fontSize: "19px",
  lineHeight: "25px",
  color: "#222",
  cursor: "pointer",
  transition: "0.5s",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  lineClamp: 2,
  WebkitBoxOrient: "vertical",
};

const subheading = {
  fontFamily: "'Nunito Sans', sans-serif",
  fontWeight: "700",
  fontSize: "18px",
  textTransform: "uppercase",
  color: "cutome.lightwheat",
};

const BlogCard = ({ title, image, author, date, content, id, blogarea, blogcategory }) => {
  if (!image) {
    image = DefaultImage;
  }

  return (
    <Card
      sx={{
        ...cardstyle,
        maxWidth: { xs: "390px", sm:"390", lg: "340px", md: "340px" },
        height: "fit-content",
        mb: { xs: "20px", md: "20px" },
        height: "310px",
        overflow: "hidden",
      
      }}
    >
      <CardMedia component="img" alt={title} height="200" image={image}  sx={{
          '&:hover': {
            transform: 'scale(1.2)', 
            transition: 'transform 0.3s ease-in-out', 
          },
        }} />
      <CardContent>
        <Typography variant="h6" gutterBottom style={heading}>
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {date} {blogarea} {blogcategory}
        </Typography>
        {/* <Button variant="contained" color="primary" sx={{marginTop:"15px"}}>
          Read More
        </Button> */}
      </CardContent>
    </Card>
  );
};

export default BlogCard;
