

let bedroom = [
    { label: '1' },
    { label: '2' },
    { label: '3' },
    { label: '4' },
    { label: '5' },
    { label: '6' },
    { label: '7' },
    { label: '7+' },
]
let bathroom = [
    { label: '1' },
    { label: '2' },
    { label: '3' },
    { label: '4' },
    { label: '5' },
    { label: '6' },
    { label: '7' },
    { label: '7+' },
]

let Price = [
    { label: '20,000' },
    { label: '30,000' },
    { label: '40,000' },
    { label: '50,000' },
    { label: '60,000' },
    { label: '70,000' },
    { label: '80,000' },
    { label: '90,000' },
    { label: '100,000' },
    { label: '110,000' },
    { label: '120,000' },
    { label: '130,000' },
    { label: '140,000' },
    { label: '150,000' },
    { label: '160,000' },
    { label: '170,000' },
    { label: '180,000' },
    { label: '190,000' },
    { label: '200,000' },
    { label: '225,000' },
    { label: '250,000' },
    { label: '275,000' },
    { label: '300,000' },
    { label: '350,000' },
    { label: '400,000' },
    { label: '500,000' },
    { label: '750,000' },
    { label: '1,000,000' },
    { label: '2,000,000' },
    { label: '3,000,000' },
    { label: '4,000,000' },
    { label: '5,000,000' },
    { label: '6,000,000' },
    { label: '7,000,000' },
    { label: '8,000,000' },
    { label: '9,000,000' },
    { label: '10,000,000' },
    { label: '20,000,000' },
    { label: '30,000,000' },
    { label: '40,000,000' },
    { label: '50,000,000' },
]

let Area = [
    { label: '500' },
    { label: '600' },
    { label: '700' },
    { label: '800' },
    { label: '900' },
    { label: '1,000' },
    { label: '1,100' },
    { label: '1,200' },
    { label: '1,300' },
    { label: '1,400' },
    { label: '1,500' },
    { label: '1,600' },
    { label: '1,700' },
    { label: '1,800' },
    { label: '1,900' },
    { label: '2,000' },
    { label: '2,200' },
    { label: '2,400' },
    { label: '2,600' },
    { label: '2,800' },
    { label: '3,000' },
    { label: '3,200' },
    { label: '3,400' },
    { label: '3,600' },
    { label: '3,800' },
    { label: '4,200' },
    { label: '4,600' },
    { label: '5,000' },
    { label: '5,400' },
    { label: '5,800' },
    { label: '6,200' },
    { label: '6,600' },
    { label: '7,000' },
    { label: '7,400' },
    { label: '7,800' },
    { label: '8,200' },
    { label: '8,600' },
    { label: '9,000' },
    { label: '9,000+' },

]

module.exports = { bedroom, bathroom, Price, Area };