import React, { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputSearchKeywordList from './customHooks/inputSearchKeywordList';
import SortIcon from '@mui/icons-material/Sort';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

function Sorting(props) {

    const [type, setType] = useState('');
    const [category, setCategory] = useState('');
    const [location, setLocation] = useState('');
    const [heighlow, setHeighLow] = useState('');
    const [catList, setCatList] = useState([]);
    const [typeList, setTypeList] = useState([]);


    const typeChange = (event) => {
        setType(event.target.value);
    };

    const categoryChange = (event) => {
        setCategory(event.target.value);
    };

    const locationChange = (event) => {
        setLocation(event.target.value);
    };

    const heighlowChange = (event) => {
        setHeighLow(event.target.value);
    };


    useEffect(() => {
        let items = {
            "type": type,
            "category": category,
            "location": location,
            "price": heighlow,
        }
        props.sendFilterKeywords(items);

    }, [type, category, location, heighlow]);


    let [data] = InputSearchKeywordList();
    useEffect(() => {
        if (data !== null) {
            setCatList(data.unit_type);
            setTypeList(data.ad_type);

        }
        console.log(data);

    }, [data])


    return (

        <>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>

                <Stack sx={{ display: "flex", flexDirection: 'row', alignItems: "center" }}>

                    <FormControl sx={{ m: 1, minWidth: 150, }} size="small">
                        <InputLabel id="demo-simple-select-standard-label">Property Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={type}
                            onChange={typeChange}
                            label="Property Type"

                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {
                                typeList.map((val, index) => {
                                    return <MenuItem value={val.label} key={index}>{val.label}</MenuItem>
                                })
                            }

                        </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1, minWidth: 120, }} size="small">
                        <InputLabel id="demo-simple-select-standard-label">Category</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={category}
                            onChange={categoryChange}
                            label="Category"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {
                                catList.map((val, index) => {
                                    return <MenuItem value={val.label} key={index}>{val.label}</MenuItem>
                                })
                            }

                        </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1, minWidth: 120, }} size="small">
                        <InputLabel id="demo-simple-select-standard-label">Location</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={location}
                            onChange={locationChange}
                            label="Location"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={'Dubai'}>Dubai</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1, minWidth: 150, }} size="small">
                        <InputLabel id="demo-simple-select-standard-label">Price</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={heighlow}
                            onChange={heighlowChange}
                            label="Price heigh to low"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={'Price Low to heigh'}>Price low to high</MenuItem>
                            <MenuItem value={'Price high to low'}>Price high to low</MenuItem>

                        </Select>
                    </FormControl>

                </Stack>

                <Stack sx={{ flexDirection: 'row', alignItems: "center", px: 2, }}>
                    <SortIcon />
                </Stack>

            </Box>
        </>

    );
}


export default Sorting;