import React, { useRef, useState, } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./styles.css";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Typography, Button, Box } from "@mui/material";
import { Link } from "react-router-dom";
import zIndex from "@mui/material/styles/zIndex";
import { useNavigate } from "react-router-dom";

const heading = {
    // border: '1px solid red',
    textTransform: 'uppercase',
    mt: '25px',
    maxWidth: '900px',
    paddingRight: '25px',
    fontSize: { xs: '27px', md: '60px' },
    fontFamily: "'Roboto', sans-serif",
    fontWeight: '700',
    color: 'rgb(255, 255, 255)',
}

const subheading = {
    // border: '1px solid red',
    mt: '0px',
    maxWidth: '700px',
    // fontSize: { xs: '10px', md: '19px' },
    paddingRight: '25px',
    // fontFamily: "'Roboto', sans-serif;",
    // color: 'rgb(255, 255, 255)',
    color: "#fff",
    // letterSpacing: '1px',
    lineHeight: { xs: '25px', md: '27px' },
    // color: "red",
    fontSize: { xs: '15px', md: '18px' },
    fontFamily: "'Nunito Sans', sans-serif",
    fontWeight: '200',
    
}

const button = {
    mt: '10px',
    color: '#4c4f54',
    bgcolor: 'custom.lightwheat',
    transition: '0.3s',
    fontWeight: '600',
    fontFamily: "'Roboto', sans-serif",
    px: '35px ',
    borderRadius: '0px',
    
    fontSize: { xs: '9px', md: '14px' },
    py: '10px ',
    // border: '1px solid red',
    '&:hover': {
        bgcolor: 'custom.dark',
        color: 'custom.white'
    },
    


}



function Swipe() {


    return (
        <>
            <Swiper direction={"vertical"}
                autoplay={{delay: 9000, disableOnInteraction: false, }}
                loop={true}
                speed={3000}
                pagination={{clickable: true,}}
                mousewheel={true}
                // navigation={false}
                modules={[Autoplay, Pagination]}
                className="mySwiper">
                <SwiperSlide className="swiperItem" >
                    <Typography gutterBottom component="div" sx={heading} className="" >
                    List With Us
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={subheading} className="" >
                        Through our broad-based marketing opportunities and international presence, we provide maximum exposure for our listings, reaching qualified buyers.
                    </Typography>
                    <Link to='/list property' style={{ color: 'inherit', textDecoration: 'inherit' }}><Button sx={button} className="text_slider button">List With Us</Button></Link>
                </SwiperSlide>

                <SwiperSlide className="swiperItem">
                    <Typography gutterBottom component="div" sx={heading} className="" >
                        Quality Advice
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={subheading} className="" >
                        Our well-informed advisors provide comprehensive and up-to-the-minute market knowledge, allowing you to make sense of the vagaries and understand the trends.
                    </Typography>
                    <Link to="/contact" style={{ color: 'inherit', textDecoration: 'inherit' }}><Button sx={button} className="text_slider button">Get Free Consultation</Button></Link>

                </SwiperSlide>

                <SwiperSlide className="swiperItem">
                    <Typography gutterBottom component="div" sx={heading} className="" >
                        Specialised Expertise
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={subheading} className="">
                        Experts in mid to high end luxury properties, we are devoted to serving the needs of real estate tenants, buyers and sellers throughout dubai. get access to the most exclusive listings with us!
                    </Typography>
                    <Link to='/properties' style={{ color: 'inherit', textDecoration: 'inherit', zIndex:"111" }}>
                        
                        <Button sx={button} className="text_slider button"> View Properties</Button>
                        </Link>


                </SwiperSlide>

            </Swiper>
        </>
    );
}

export default Swipe;