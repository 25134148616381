import React, { useState, useEffect, useContext, createContext } from "react";
import { cardstyle } from "../../theme/theme";
import { Link } from "react-router-dom";
import TopNavbar from "../../components/top_navbar";
import ManManu from "../../components/main_manu";
import {
  Box,
  Container,
  Card,
  Typography,
  Stack,
  Skeleton,
} from "@mui/material";
import Axios from "axios";
import MainFooter from "../../components/main_footer";
import BottomFooter from "../../components/botttom_footer";
import Newsletter from "../../components/newsletter";
import FooterButton from "../../components/footer_button";
import BlogCardView from "./BlogCardView";
import Pagination from "@mui/material/Pagination";
import YoutubrSubscribeButton from "../../assests/logo_img/Youtube_SubscribeGrey.png";
import { CategoryContext } from "./CategoryProvider";
import Breadcrumb from "../../components/bread_crumb";

const BlogsByCategory = (props) => {
  const [allBlogs, setAllBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const { category } = useContext(CategoryContext);


  const blogsPerPage = 9;
  let matches = props.matches;
  let manmanu = {};
  if (matches) {
    if (props.scroll) {
      manmanu = {
        width: "100%",
        py: "0px",
        mt: "-1px",
        bgcolor: "white",
        position: "fixed",
        top: 0,
        zIndex: 100,
        animation: "scrollmanu 0.3s linear",
        "@keyframes scrollmanu": {
          from: {
            py: "10px",
          },
          to: {
            py: "0px",
          },
        },
      };
    } else {
      manmanu = {
        width: "100%",
        py: "10px",
        mt: "-1px",
        bgcolor: "custom.white",
        position: "unset",
        zIndex: 100,
        animation: "scrollmanu2 0.3s linear",
        "@keyframes scrollmanu2": {
          from: {
            py: "0px",
          },
          to: {
            py: "10px",
          },
        },
      };
    }
  } else {
    manmanu = {
      width: "100%",
      bgcolor: "custom.dark4",
    };
  }
  const styles = {
    breadcrumbContainer: {
      height: "auto",
      width: "100%",
      paddingTop: "15px",
      mt:"10px"
    },
  
  
  };
  const heading = {
    textTransform: "uppercase",
    mt: "25px",
    maxWidth: "900px",
    paddingRight: "25px",
    fontSize: { xs: "27px", md: "60px" },
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "700",
    color: "#48565c",
  };
  const subheading = {
    mt: "0px",
    maxWidth: "700px",
    fontSize: { xs: "10px", md: "19px" },
    paddingRight: "25px",
    fontFamily: "'Roboto Flex', sans-serif",
    color: "#48565c",
    letterSpacing: "1px",
    fontWeight: "unset",
  };
 

  useEffect(() => {
    Axios.post("https://cityluxedxb.com/cityluxedxb/all_blogs.php")
      .then((response) => {
        
        const filtered = category ? response.data.blogslist.filter(blog => blog.blogcategory === category) : response.data.blogslist;      setLoading(false);
      // Update the blogs state here
      setAllBlogs(filtered); 
  
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching blog data: ", error);
      });
  }, [category]);

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = allBlogs.slice(indexOfFirstBlog, indexOfLastBlog);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleSubscribeClick = () => {
    // Open the URL in a new window
    window.open(
      "https://www.youtube.com/channel/UC0L6TDApvFtl_F_bIlVtQNw",
      "_blank"
    );
  };
  return (
    <>

      <Box
        sx={{
          position: "relative",
          top: 0,
          left: 0,
          bgcolor: "#d3c6b3bd",
          minHeight: {
            xs: "40vh",
            sm: "40vh",
            md: "500px",
            // lg: "580px",
            // xl: "580px",
          },
        }}
      >
        {/* TopNavBar Section */}
        <Box bgcolor={{ xs: "custom.dark", md: "custom.dark" }}>
          <Container maxWidth="lg">
            <TopNavbar matches={matches} />
          </Container>
        </Box>
        {/* NavBar/main-menu section */}
        <Box sx={manmanu}>
          <Container maxWidth="lg">
            <ManManu scroll={props.scroll} color={"dark2"} />
          </Container>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: { xs: "30vh", sm: "30vh", md: "350px" },
          }}
        >
          <Typography sx={heading}>Real Estate News & Media </Typography>
          <Typography sx={subheading}>Stay Informed and Inspired </Typography>
          <Box
            sx={{
              display: "flex",
              marginTop: { xs: "0", lg: "15px" },
              "&:hover": {
                cursor: "pointer",
                transition: "transform 0.3s ease-in-out",
                transform: "scale(1.1)",
              },
            }}
          >
            <img
              src={YoutubrSubscribeButton}
              onClick={handleSubscribeClick}
              height={"40px"}
              marginRight={"5px"}
              alt="youtube logo"
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{ backgroundColor: "#F7F7F7", mt: "-10px" }}
        className="container-fluid"
      >
        {/* Blogs cards section */}

        <Container maxWidth="lg" sx={{ mb: "27px" }}>
        <Box sx={styles.breadcrumbContainer}>
            {" "}
            <Breadcrumb breadcrumb={["home", "blogs", `${category} blogs`]} />
          </Box>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            sx={{
              fontWeight: "700",
              fontSize: "25px",
              color: "#090909FC",
              my: "10px",
              fontFamily: "'Roboto', sans-serif",
            }}
          >
            Our Blogs
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginBottom: "-27px",
              backgroundColor: "#F7F7F7",
            }}
          >
            {loading
              ? // Show Skeleton while loading
                Array.from({ length: 6 }).map((_, index) => (
                  <Box key={index} width={300} marginRight={2} my={5}>
                    <Skeleton variant="rectangular" width="100%" height={200} />
                    <Skeleton width="60%" />
                    <Skeleton width="80%" />
                  </Box>
                ))
              : allBlogs.map((blog) => (
                  <Link to={`/blogs/${blog.id}`} className="no-underline">
                    <BlogCardView
                      key={blog.id}
                      title={blog.title}
                      image={blog.image1}
                      author={blog.author}
                      date={blog.posted_on}
                      content={blog.body}
                      blogarea ={blog.blogarea}
                      blogcategory ={blog.blogcategory}
                    />
                  </Link>
                ))}
          </Box>
        </Container>
        <Container>
          <Card
            sx={{
              ...cardstyle,
              width: { sm: "100%", md: "100%", lg: "100%" },
              mb: "20px",
              py: "10px",
            }}
          >
            <Stack alignItems="center">
              <Pagination
                count={Math.ceil(allBlogs.length / blogsPerPage)}
                page={currentPage}
                onChange={(event, value) => paginate(value)}
                size="small"
                variant="outlined"
                shape="rounded"
              />
            </Stack>
          </Card>
        </Container>
      </Box>

      {/* MainFooter Section */}
      <Box sx={{ pt: "50px", backgroundColor: "#2c2c2c" }}>
        <Container maxWidth="lg">
          <Box>
            <MainFooter />
          </Box>
        </Container>
        {/* NewsLetter subscription section */}
        <Box sx={{ borderTop: "0.5px solid #666666", py: "20px", mt: "25px" }}>
          <Container>
            <Newsletter />
          </Container>
        </Box>
      </Box>
      {/* Bottom footer section*/}
      <Box sx={{ backgroundColor: "#272727", py: "15px" }}>
        <Container maxWidth="lg">
          <BottomFooter />
        </Container>
      </Box>
      <FooterButton scroll={props.scroll} />

    </>
  );
};

export default BlogsByCategory;
