import { useState, useEffect } from 'react';
import Client from '../../components/axios';
const useKeywordData = () => {
  const [projectsKeyword, setProjectsKeyword] = useState({
    project_unit_type: [],
    developer_name: [],
    project_title: []
  });

  useEffect(() => {
    Client.post('/cityluxedxb/input_search_keyword.php')
      .then((result) => {
        if (result.data.errorcode === '200') {
          const { developer_name, project_unit_type, project_title } = result.data.projects_keyword;

          setProjectsKeyword({
            project_unit_type,
            developer_name,
            project_title
          });
        } else {
          console.error("Failed to fetch data:", result.data.message);
        }
      })
      .catch((error) => {
        console.error("API call failed", error);
      });
  }, []);

  return projectsKeyword;
};

export default useKeywordData;
