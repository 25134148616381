import React, { useEffect, useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, Stack, FormControl, InputLabel, Select, MenuItem, Icon } from '@mui/material';
import Client from '../../../../components/axios';
import EditIcon from '@mui/icons-material/Edit';
import './team_table.css';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import TextField from '@mui/material/TextField';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import LoadingButton from '@mui/lab/LoadingButton';




export default function DataTable() {
    const [teamlist, setTeamList] = useState([]);
    const [id, setid] = useState('');
    const [loading, setLoading] = useState(false);
    // for variables 
    const [name, setName] = useState('');
    const [position, setPosition] = useState('');
    const [department, setDepartment] = useState('');
    const [specialisation, setSpecialisation] = useState('');
    const [image, setImage] = useState('');
    const [socialMedia, setSocialMedia] = useState('');

    const [deleteOpen, setDeleteOpen] = useState(false);
    const deleteHandleClose = () => {
        setDeleteOpen(false);
    };

    const [updateOpen, setUpdateOpen] = useState(false);
    const upadateHandleClose = () => {
        setName('');
        setPosition('');
        setDepartment('');
        setSpecialisation('');
        setImage('');
        setSocialMedia('');
        setUpdateOpen(false);
    };

    const [addOpen, setAddOpen] = useState(false);
    const addHandleClose = () => {
        setName('');
        setPosition('');
        setDepartment('');
        setSpecialisation('');
        setImage('');
        setSocialMedia('');
        setAddOpen(false);
    };



    const handleMemberName = (event) => {
        if (event.target.value.length < 50) {
            setName(event.target.value);
        } else {
            alert("name can't be longer");
        }
    };

    const handleMemberPosition = (event) => {
        if (event.target.value.length < 50) {
            setPosition(event.target.value);
        } else {
            alert("Position can't be longer");
        }
    };

    const handlerMembeDeparment = (event) => {
        if (event.target.value.length < 50) {
            setDepartment(event.target.value);
        } else {
            alert("Department can't be longer");
        }
    };

    const handleMemberSpecialisation = (event) => {
        if (event.target.value.length < 100) {
            setSpecialisation(event.target.value);
        } else {
            alert("Specialisation can't be longer");
        }
    };

    const handleMemberImage = (event) => {
        if (event.target.value.length < 200) {
            setImage(event.target.value);
        } else {
            alert("Image URL can't be longer");
        }
    };

    const handleMemberSociaMedia = (event) => {
        if (event.target.value.length < 1000) {
            setSocialMedia(event.target.value);
        } else {
            alert("Social Media URL can't be longer");
        }
        setSocialMedia(event.target.value);
    };


    const GetPropetylist = () => {
        Client.post('/cityluxedxb/get_team_members.php')
            .then((response) => {
                if (response.data.errorcode == 200) {
                    // console.log(response.data);
                    setTeamList(response.data.propertieslist);

                } else {
                    console.log(response.data);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    useEffect(() => {
        GetPropetylist();
    }, [])


    const deleteEvent = (idclick) => {
        setid(idclick);
        setDeleteOpen(true);
    }

    const editEvent = (idclick) => {
        setid(idclick);
        let item = teamlist.find(item => item.id == idclick);
        setName(item.name);
        setPosition(item.position);
        setDepartment(item.department);
        setSpecialisation(item.specialisation);
        setImage(item.image);
        setSocialMedia(item.social_media);
        setUpdateOpen(true);
    }




    const Delete = () => {
        setLoading(true);
        Client.post('/cityluxedxb/delete_team_member.php', {
            "member_id": id
        })
            .then((response) => {
                if (response.data.errorcode == 200) {
                    // console.log(response.data);
                    GetPropetylist();
                    setLoading(false);
                    setDeleteOpen(false);
                } else {
                    console.log(response.data);
                    setLoading(false);
                    setDeleteOpen(false);
                }
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
                setDeleteOpen(false);
            });
    }

    const Update = () => {
        if (name == '') {
            alert('Please Enter Name');
        } else if (position == '') {
            alert('Please Enter Position');
        } else if (department == '') {
            alert('Please Enter Department');
        } else {
            setLoading(true);
            Client.put('/cityluxedxb/update_team_member.php', {
                "team_id": id,
                "name": name,
                "position": position,
                "department": department,
                "specialisation": specialisation,
                "image": image,
                "social_media": socialMedia,
            })
                .then((response) => {
                    if (response.data.errorcode == 200) {
                        GetPropetylist();
                        GetPropetylist();
                        setName('');
                        setPosition('');
                        setDepartment('');
                        setSpecialisation('');
                        setImage('');
                        setSocialMedia('');
                        setLoading(false);
                        setUpdateOpen(false);
                    } else {
                        console.log(response.data);
                        setLoading(false);
                        setUpdateOpen(false);
                    }
                })
                .catch(err => {
                    console.log(err);
                    setLoading(false);
                    setUpdateOpen(false);
                });
        }
    }

    const add = () => {
        if (name == '') {
            alert('Please Enter Name');
        } else if (position == '') {
            alert('Please Enter Position');
        } else if (department == '') {
            alert('Please Enter Department');
        } else {
            setLoading(true);
            Client.post('/cityluxedxb/add_team_member.php', {
                "name": name,
                "position": position,
                "department": department,
                "specialisation": specialisation,
                "image": image,
                "social_media": socialMedia,
            })
                .then((response) => {
                    if (response.data.errorcode == 200) {
                        // console.log(response.data);

                        GetPropetylist();
                        setName('');
                        setPosition('');
                        setDepartment('');
                        setSpecialisation('');
                        setImage('');
                        setSocialMedia('');
                        // setTypeProperty('');
                        // setPropertyRefNo('');

                        setLoading(false);
                        setAddOpen(false);

                    } else {
                        console.log(response.data);
                        setLoading(false);
                        setAddOpen(false);
                    }
                })
                .catch(err => {
                    console.log(err);
                    setLoading(false);
                    setAddOpen(false);
                });
        }

    }

    const button = {
        color: 'custom.white',
        bgcolor: 'custom.lightwheat',
        transition: '0.3s',
        fontWeight: '500',
        border: '1px solid #ffffff',
        px: '40px',
        borderRadius: '0px',
        fontSize: { xs: '9px', md: '13px' },
        py: '10px ',
        '&:hover': {
            bgcolor: 'transparent',
            color: 'custom.lightwheat',
            border: "1px solid #e1d3bc"
        }

    }

    // states for table 

    const [perpage, setperpage] = useState(10);
    const [minvalue, setminvalue] = useState(1);
    const [maxvalue, setmaxvalue] = useState(10);
    const [showrecord, setshowrecord] = useState([]);


    useEffect(() => {
        if (minvalue == 1) {
            setshowrecord(teamlist.slice(minvalue - 1, maxvalue));
        } else {
            setshowrecord(teamlist.slice(minvalue, maxvalue));
        }
        // console.log(teamlist);

    }, [teamlist]);

    const perPageFun = (event) => {
        setperpage(event.target.value);
        setminvalue(1);
        setmaxvalue(event.target.value);
        setshowrecord(teamlist.slice((0), (event.target.value)));

    }

    const increment = () => {

        if (teamlist.length > 10) {
            if (maxvalue !== teamlist.length) {
                if (maxvalue + perpage <= teamlist.length) {
                    setminvalue(maxvalue);
                    setmaxvalue(maxvalue + perpage);
                    setshowrecord(teamlist.slice(maxvalue, maxvalue + perpage));
                } else {
                    setminvalue(maxvalue);
                    setmaxvalue(teamlist.length);
                    setshowrecord(teamlist.slice(maxvalue, maxvalue + perpage));
                }
            }
        }
    }
    const decrement = () => {
        if (minvalue !== 1) {
            if (minvalue - perpage >= 1) {
                setminvalue(minvalue - perpage);
                setmaxvalue(minvalue);
                setshowrecord(teamlist.slice(minvalue - perpage, minvalue));
                console.log(minvalue - perpage);
                console.log(minvalue);
            } else {
                setminvalue((minvalue - perpage) + 1);
                setmaxvalue(minvalue);
                setshowrecord(teamlist.slice(minvalue - perpage, minvalue));
            }
        }
    }

    return (
        <>

            <Box className='main_section_type_properties'>
                <Box sx={{ margin: "15px 0px", padding: "0px 20px", backgroundColor: "#fff", width: "100%", display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Stack sx={{ paddingLeft: "20px", width: "75%" }}>

                    </Stack>
                    <Stack sx={{ paddingLeft: "20px", width: "15%", display: 'flex', flexDirection: "row", justifyContent: 'space-around' }}>

                    </Stack>
                    <Stack sx={{ paddingLeft: "20px", display: "flex", flexDirection: 'row', justifyContent: 'flex-end', width: "10%" }}>

                        <Tooltip title="Add Property Type">
                            <IconButton onClick={() => setAddOpen('true')}> <AddIcon sx={{ cursor: 'pointer' }} /></IconButton>
                        </Tooltip>
                    </Stack>
                </Box>
                <div className='table_box'>
                    <table className="table">
                        <tr className='table_head'>
                            <th style={{ width: "3%" }}>SR.NO</th>
                            <th style={{ width: "20%" }}>Name</th>
                            <th style={{ width: "15%" }}>Position</th>
                            <th style={{ width: "19%" }}>Department</th>
                            <th style={{ width: "25%" }}>Specialisation</th>
                            <th style={{ width: "10%" }}>Photo</th>
                            {/* <th style={{ width: "3%" }}>View</th> */}
                            <th style={{ width: "4%" }}>Edit</th>
                            <th style={{ width: "4%" }}>Delete</th>
                        </tr>

                        {showrecord.length > 0 ?

                            showrecord.map((val, index) => {
                                let count = 0;
                                if (minvalue == 1) {
                                    count = minvalue + index;
                                } else {
                                    count = minvalue + (index + 1);
                                }

                                return <>
                                    <tr className='table_body' style={{ textTransform: "capitalize" }}>
                                        <td>{count}</td>
                                        <td>{val.name}</td>
                                        <td>{val.position}</td>
                                        <td>{val.department}</td>
                                        <td>{val.specialisation}</td>
                                        <td style={{ width: "100px", height: "100px" }}><img src={val.image} alt="" style={{ height: "100%" }} /></td>

                                        {/* <td className='icon'>
                                            <Tooltip title="View">
                                                <IconButton>
                                                    <VisibilityOffOutlinedIcon fontSize='medium' onClick={() => viewEvent(val.id)} />
                                                </IconButton>
                                            </Tooltip>
                                        </td> */}
                                        <td className='icon'>
                                            <Tooltip title="Edit">
                                                <IconButton>
                                                    <EditIcon fontSize='medium' onClick={() => editEvent(val.id)} />
                                                </IconButton>
                                            </Tooltip>
                                        </td>
                                        <td className='icon'>
                                            <Tooltip title="Delete">
                                                <IconButton>
                                                    <DeleteForeverIcon fontSize='medium' sx={{ color: 'red' }} onClick={() => deleteEvent(val.id)} />
                                                </IconButton>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                </>

                            })

                            :

                            <Box>
                                <Stack>
                                    Loding.....
                                </Stack>
                            </Box>

                        }

                    </table>
                </div>
                <Box sx={{ margin: "15px 0px", padding: "0px 20px", backgroundColor: "#fff", width: "100%", display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    {/* <Stack sx={{ paddingLeft: "20px", width: "50%" }}>

                    </Stack> */}
                    <Stack sx={{ paddingLeft: "20px", width: "25%", display: 'flex', justifyContent: "flex-end", flexDirection: "row", alignItems: 'center' }}>
                        <span> Rows per page:</span>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={perpage}
                            onChange={(e) => perPageFun(e)}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={15}>15</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={25}>25</MenuItem>
                        </Select>
                    </Stack>

                    <Stack sx={{ paddingLeft: "20px", width: "150px", display: 'flex', flexDirection: "row", justifyContent: 'space-around' }}>
                        {/* <p>1–10</p>
                        <p>of</p>
                        <p>15</p> */}
                        <span>{minvalue}-{maxvalue} </span> <span> of </span> <span> {teamlist.length} </span>

                    </Stack>
                    <Stack sx={{ paddingLeft: "20px", display: "flex", flexDirection: 'row', justifyContent: 'space-between', width: "100px" }}>

                        <IconButton onClick={() => decrement()}> <KeyboardArrowLeftIcon sx={{ cursor: 'pointer' }} /></IconButton>

                        <IconButton onClick={() => increment()}>  <KeyboardArrowRightIcon sx={{ cursor: 'pointer' }} /></IconButton>
                    </Stack>
                </Box>

            </Box>
            {/* code for dialog box of delete properties  */}
            <div>
                <Dialog
                    open={deleteOpen}
                    onClose={deleteHandleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Are you sure want to delete?"}
                    </DialogTitle>
                    {/* <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Let Google help apps determine location. This means sending anonymous
                            location data to Google, even when no apps are running.
                        </DialogContentText>
                    </DialogContent> */}
                    <DialogActions sx={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Button onClick={deleteHandleClose} sx={{ ...button }}>Cancel</Button>
                        {/* <Button onClick={Delete} sx={{ color: 'red' }} autoFocus>
                            Delete
                        </Button> */}
                        <LoadingButton
                            sx={button}
                            onClick={Delete}
                            loading={loading}
                            loadingPosition="end"
                        >
                            DELETE
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </div>
            {/* code for dialog box update Properties */}
            <div>
                <Dialog
                    open={updateOpen}
                    onClose={upadateHandleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Update Team Meber Details"}
                    </DialogTitle>
                    <DialogContent sx={{ p: 3, minWidth: "25vw" }}>
                        <Box sx={{ my: "10px" }}>
                            <img src={image} alt="" style={{ height: "50%", width: "50%" }} />
                        </Box>

                        <TextField id="outlined-basic" label="Name" value={name} onChange={handleMemberName} variant="standard" sx={{ mt: "10px", width: "100%" }} />
                        <TextField id="outlined-basic" label="Position" value={position} onChange={handleMemberPosition} variant="standard" sx={{ mt: "10px", width: "100%" }} />
                        <TextField id="outlined-basic" label="Department" value={department} onChange={handlerMembeDeparment} variant="standard" sx={{ mt: "10px", width: "100%" }} />
                        <TextField id="outlined-basic" label="Specialisation" value={specialisation} onChange={handleMemberSpecialisation} variant="standard" sx={{ mt: "10px", width: "100%" }} />
                        <TextField id="outlined-basic" label="Image URL" value={image} onChange={handleMemberImage} variant="standard" sx={{ mt: "10px", width: "100%" }} />
                        <TextField id="outlined-basic" label="Social Media URL" value={socialMedia} onChange={handleMemberSociaMedia} variant="standard" sx={{ mt: "10px", width: "100%" }} />
                    </DialogContent>
                    <DialogActions >
                        <Button onClick={upadateHandleClose} sx={{ ...button }}>Cancel</Button>
                        {/* <Button onClick={Update} sx={{ color: 'red' }} autoFocus>
                            Update
                        </Button> */}
                        <LoadingButton
                            sx={button}
                            onClick={Update}
                            loading={loading}
                            loadingPosition="end"
                        >
                            UPDATE
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </div>
            {/* code for dialog box add Properties */}
            <div>
                <Dialog
                    open={addOpen}
                    onClose={addHandleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Add Team Member"}
                    </DialogTitle>
                    <DialogContent sx={{ p: 3, minWidth: "25vw" }}>
                        <TextField id="outlined-basic" label="Name" value={name} onChange={handleMemberName} variant="standard" sx={{ mt: "10px", width: "100%" }} />
                        <TextField id="outlined-basic" label="Position" value={position} onChange={handleMemberPosition} variant="standard" sx={{ mt: "10px", width: "100%" }} />
                        <TextField id="outlined-basic" label="Department" value={department} onChange={handlerMembeDeparment} variant="standard" sx={{ mt: "10px", width: "100%" }} />
                        <TextField id="outlined-basic" label="Specialisation" value={specialisation} onChange={handleMemberSpecialisation} variant="standard" sx={{ mt: "10px", width: "100%" }} />
                        <TextField id="outlined-basic" label="Image URL" value={image} onChange={handleMemberImage} variant="standard" sx={{ mt: "10px", width: "100%" }} />
                        <TextField id="outlined-basic" label="Social Media URL" value={socialMedia} onChange={handleMemberSociaMedia} variant="standard" sx={{ mt: "10px", width: "100%" }} />
                    </DialogContent>
                    <DialogActions >
                        <Button onClick={addHandleClose} sx={{ ...button }}>Cancel</Button>
                        {/* <Button onClick={add} sx={{ color: 'red' }} autoFocus>
                            Add
                        </Button> */}
                        <LoadingButton
                            sx={button}
                            onClick={add}
                            loading={loading}
                            loadingPosition="end"
                        >
                            ADD
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </div>

        </>

    );
}