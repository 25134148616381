import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import Sidebar from "../components/sidebar";
import Dashnavbar from "../components/dashnavbar";
// import DataTable from "../components/propertyType/property_type_table";
// import UserContext from "../auth/auth";
import UserContextCurrency from "../../../context/currencyChange";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import LoginHook from "../../../components/customHooks/loginHook";

const heading = {
    textTransform: 'capitalize',
    // fontFamily: "'Roboto', sans-serif",
    fontFamily: "'Nunito Sans', sans-serif",
    fontWeight: '700',
    fontSize: '25px',
    lineHeight: '25px',
    color: '#222',
    textAlign: 'center'
    // cursor: 'pointer',
    // transition: '0.5s',
    // '&:hover': {
    //     color: 'cutome.lightwheat',
    // },
}
const textfield = {
    backgroundColor: '#ffffff',
    color: '#8593a9',
    borderRadius: '0px',
    border: '0.5px solid #e6e6e6',
    mb: '10px',
}
const button = {
    color: 'custom.white',
    bgcolor: 'custom.lightwheat',
    transition: '0.3s',
    fontWeight: '500',
    border: '1px solid #ffffff',
    px: '40px',
    borderRadius: '0px',
    fontSize: { xs: '9px', md: '13px' },
    py: '10px ',
    '&:hover': {
        bgcolor: 'transparent',
        color: 'custom.lightwheat',
        border: "1px solid #e1d3bc"
    }

}

const PropertyType = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [conformPassword, setConformPassword] = useState('');
    const [loading, setLoading] = useState(false);

    // const { value, setValue } = useContext(UserContext);
    const { currencySign, setCurrencySign, value, setValue } = useContext(UserContextCurrency);
    let navigate = useNavigate();
    let cookie = LoginHook();

    if (cookie == false) {
        navigate("/adminpanel/login/login");
    }

    return (
        <>
            {
                cookie &&

                <Box sx={{ display: 'flex', minHeight: '100vh', }}>
                    <Box sx={{ minHeight: "100vh", width: "20%", px: "10px", boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}>
                        <Sidebar />
                    </Box>
                    <Box sx={{ minHeight: "100vh", width: "80%", backgroundColor: "" }}>
                        <Box sx={{ boxShadow: "rgba(0, 0, 0, 0.1) -4px 9px 25px -6px" }}>
                            <Dashnavbar />
                        </Box>
                        <Box>
                            <Box sx={{ display: 'flex', justifyContent: "space-around", alignItems: 'center', }}>

                            </Box>
                            <Box>
                                <Stack sx={{ width: "50%", my: "10px", mx: "auto", border: "1px solid #e6e6e6", py: '25px', px: "25px", mt: "50px" }}>
                                    <Typography gutterBottom variant="h6" component="div" sx={{ ...heading, mb: "20px" }} >
                                        Update Password
                                    </Typography>
                                    <TextField id="standard-basic" type='' label="Current Password" variant="standard" sx={{ my: "10px" }} onChange={(e) => setCurrentPassword(e.target.value)} />

                                    <TextField id="standard-basic" type='password' label="New Password" variant="standard" sx={{ my: "10px" }} onChange={(e) => setNewPassword(e.target.value)} />

                                    <TextField id="standard-basic" type='password' label="Confirm New Password" variant="standard" sx={{ my: "10px" }} onChange={(e) => setConformPassword(e.target.value)} />

                                    <Stack>


                                        <LoadingButton
                                            // size="small"
                                            sx={{ ...button }}
                                            // onClick={checkLoginValue}
                                            // endIcon={<SaveIcon />}
                                            // loading={loading}
                                            loadingPosition="end"
                                        // variant="contained"
                                        >
                                            Update Password
                                        </LoadingButton>

                                    </Stack>
                                </Stack>
                            </Box>

                        </Box>
                    </Box>
                </Box >
            }
        </>
    )
}


export default PropertyType;