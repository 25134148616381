import React, { createContext, useState } from 'react';

export const FilteredRecordsContext = createContext();

export const RecordsProvider = ({ children }) => {
  const [filteredRecord, setFilteredRecord] = useState([]);
  return (
    <FilteredRecordsContext.Provider value={{ filteredRecord, setFilteredRecord }}>
      {children}
    </FilteredRecordsContext.Provider>
  );
};
