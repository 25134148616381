import React,{useEffect} from 'react'
import { Box, Grid, Typography, List, ListItem, ListItemIcon } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import ReactHtmlParser from 'react-html-parser';
const AboutProject = ({ projectDetails }) => {
  
  const paraStyle = {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    fontWeight: "500",
    color: "#5c727d",
    lineHeight: "1.6em",
    wordBreak: "word-break",
    paddingRight: "5px", letterSpacing: ".5px"
  }
  useEffect(() => {
    AOS.init();
  }, []);
  
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid container spacing={2}>
          {/* First column with image */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <img src={projectDetails.project.img1} alt="Project" style={{ maxWidth: '100%', height: 'auto' }}   />
            </Box>
          </Grid>
          {/* Second column with text and lists */}
          <Grid item xs={12} md={6}>
            <Box sx={{ p: 0, m: 0 }}>
              <Typography variant="h5" sx={{ fontWeight: 'bold', textTransform: "uppercase", mb:"10px" }}>
                {/* {projectDetails.project.title}  */}
                about the project
              </Typography>
              <Typography variant="body1" sx={paraStyle} >
              {ReactHtmlParser(projectDetails.project.description1)}          
              </Typography>
              {projectDetails.project.amenities && projectDetails.project.amenities.length > 0 && (
              <List>
                {projectDetails.project.features.map((amenity) => (
                  <ListItem key={amenity}>
                    <ListItemIcon>
                      <CheckBoxIcon />
                    </ListItemIcon>
                    <Typography variant="body2">{amenity}</Typography>
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
   </>
  )
}

export default AboutProject