import React, { useState, useEffect } from "react";
import Client from "../components/axios";
import TopNavbar from "../components/top_navbar";
import ManManu from "../components/main_manu";
import Box from "@mui/material/Box";
import HomeSlider from "../components/home_slider";
import Container from "@mui/material/Container";
import Heading from "../components/heading";
import CardView from "../components/card_view";
import CustomerReview from "../components/cutomer_review";
import Affiliations from "../components/affiliations";
import MainFooter from "../components/main_footer";
import BottomFooter from "../components/botttom_footer";
import SearchFilter from "../components/search_filter";
import FooterButton from "../components/footer_button";
import Newsletter from "../components/newsletter";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Swipe from "../components/swiper";
import { Link } from "react-router-dom";

const Home = (props) => {
  const [addfilter, setaddfilter] = useState(false);
  const [premium, setpremium] = useState([]);
  const [superhot, setsuperhot] = useState([]);
  const [bestoffplan, setbestoffplan] = useState([]);
  const [featured, setfeatured] = useState([]);

  useEffect(() => {

    Client.post('/cityluxedxb/premium_properties.php')
        .then((response) => {
            if (response.data.errorcode == 200) {
                setpremium(response.data.propertieslist);
                // console.log(response.data);
            } else {
                console.log(response.data);
            }
        })
        .catch(err => {
            console.log(err);
        });

    Client.post('/cityluxedxb/super_hot_properties.php')
        .then((response) => {
            if (response.data.errorcode == 200) {
                setsuperhot(response.data.propertieslist);

                // console.log(response.data);
            } else {
                console.log(response.data);
            }
        })
        .catch(err => {
            console.log(err);
        });

    Client.post('/cityluxedxb/best_off_plan.php')
        .then((response) => {
            if (response.data.errorcode == 200) {
                setbestoffplan(response.data.propertieslist);
            } else {
                // console.log(response.data);
            }
        })
        .catch(err => {
            console.log(err);
        });

    Client.post('/cityluxedxb/featured.php')
        .then((response) => {
            if (response.data.errorcode == 200) {
                setfeatured(response.data.propertieslist);

                // console.log(response.data);
            } else {
                console.log(response.data);
            }
        })
        .catch(err => {
            console.log(err);
        });

}, []);
  let premiumProperties = premium.map((value, index) => (
    <CardView key={index} listitem={value} cardwidth={"32%"} />
  ));

  let superProperties = superhot.map((value, index) => (
    <CardView key={index} listitem={value} cardwidth={"32%"} />
  ));

  let bestProperties = bestoffplan.map((value, index) => (
    <CardView key={index} listitem={value} cardwidth={"32%"} />
  ));

  // let featuredProperties = featured.map((value, index) => (
  //   <CardView key={index} listitem={value} cardwidth={"32%"} />
  // ));
  const featuredProperties = featured.slice(0, 3).map((value, index) => (
    <CardView key={index} listitem={value} cardwidth={"32%"} />
  ));
  
  let matches = props.matches;
  let manmanu = {};

  let border = {};
  if (matches) {
    border = {
      border: "0.5px solid rgba(206, 206, 206, 0.3)",
    };
    if (props.scroll) {
      manmanu = {
        width: "100%",
        py: "0px",
        mt: "-1px",
        bgcolor: "#ffffff",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 100,
        animation: "scrollmanu 0.4s linear",
        "@keyframes scrollmanu": {
          from: {
            py: "10px",
            bgcolor: "tranparent",
          },
          to: {
            py: "0px",
            bgcolor: "white",
          },
        },
      };
    } else {
      manmanu = {
        width: "100%",
        py: "10px",
        mt: "-1px",
        bgcolor: "transparent",
        position: "relative",
        zIndex: 100,
        animation: "scrollmanu2 0.4s linear",
        "@keyframes scrollmanu2": {
          from: {
            py: "0px",
            bgcolor: "white",
          },
          to: {
            py: "10px",
            bgcolor: "transparent",
          },
        },
      };
    }
  } else {
    border = {
      border: "none",
    };
    manmanu = {
      width: "100%",
      bgcolor: "custom.dark4",
    };
  }

  const getClickedFilter = (value) => {
    setaddfilter(value);
  };

  let addfilterbox = {};
  if (addfilter) {
    addfilterbox = {
      top: "65vh",
      transition: "0.4s ease top",
    };
  } else {
    addfilterbox = {
      top: "80vh",
      transition: "0.4s ease top",
    };
  }

  document.title = "CITY LUXE - Real Estate";

  return (
    <>
      {/* home video and text slider  */}

      <Box
        sx={{
          position: "relative",
          left: "0px",
          top: "0px",
          height: { xs: "60vh", sm: "100vh" },
          background: "rgba(0, 0, 0, 0.254)",
        }}
      >
        <Box
          sx={{
            height: { xs: "20vh", sm: "25vh" },
            // border:"1px solid yellow"
          }}
        >
          <HomeSlider />

          <Box
            sx={{ ...border }}
            bgcolor={{ xs: "custom.dark", md: "transparent" }}
          >
            <Container maxWidth="lg">
              <TopNavbar matches={matches} />
            </Container>
          </Box>

          <Box sx={{ ...manmanu }}>
            <Container maxWidth="lg">
              <ManManu scroll={props.scroll} />
            </Container>
          </Box>
        </Box>

        <Box sx={{height: { xs: "40vh", sm: "75vh" },}}>
          <Swipe />
        </Box>

        {matches && (
          <Box
            sx={{
              position: "absolute",
              left: 0,
              width: "100%",
              ...addfilterbox,
              zIndex: 10,
            }}
          >
            <Container maxWidth="lg">
              <SearchFilter getclicked={getClickedFilter} />
            </Container>
          </Box>
        )}
      </Box>
      {/* top navbar main manu and filter search bar  */}

      {/* premium car properties  */}
      <Box sx={{ pt: "0px", mt: { xs: "0px", sm: "75px" } }}>
        <Container maxWidth="lg" sx={{ pt: { xs: "20px", md: "0px" } }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Heading title={"Premium Properties"} />
            <Button
              sx={{
                fontSize: { xs: "12px", sm: "15px" },
                color: "#222",
                fontFamily: "'Nunito Sans', sans-serif",
                fontWeight: "600",
              }}
            >
              <Link
                to="/premiumsuperhotfeatured?type=Premium"
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                {" "}
                Show All{" "}
              </Link>{" "}
            </Button>
          </Box>

          <Box
            sx={{
              display: { xs: "", md: "flex" },
              justifyContent: "space-between",
              mt: "30px",
            }}
          >
            {premium.length > 0 ? (
              premiumProperties
            ) : (
              <>
                <Stack spacing={1}>
                  <Skeleton variant="rectangular" width={250} height={200} />
                  <Skeleton
                    variant="text"
                    width={200}
                    height={40}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={150}
                    height={30}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                </Stack>
                <Stack spacing={1}>
                  <Skeleton variant="rectangular" width={250} height={200} />
                  <Skeleton
                    variant="text"
                    width={200}
                    height={40}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={150}
                    height={30}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                </Stack>
                <Stack spacing={1}>
                  <Skeleton variant="rectangular" width={250} height={200} />
                  <Skeleton
                    variant="text"
                    width={200}
                    height={40}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={150}
                    height={30}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                </Stack>
              </>
            )}
          </Box>
        </Container>
      </Box>
      {/* super hot deal properties  */}
      <Box sx={{ pt: "0px", mt: "50px" }}>
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Heading title={"Super Hot Deals"} />
            <Button
              sx={{
                fontSize: { xs: "12px", sm: "15px" },
                color: "#222",
                fontFamily: "'Nunito Sans', sans-serif",
                fontWeight: "600",
              }}
            >
              <Link
                to="/premiumsuperhotfeatured?type=Super Hot"
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                {" "}
                Show All{" "}
              </Link>{" "}
            </Button>
          </Box>
          <Box
            sx={{
              display: { xs: "", md: "flex" },
              justifyContent: "space-between",
              my: "30px",
            }}
          >
            {superhot.length > 0 ? (
              superProperties
            ) : (
              <>
                <Stack spacing={1}>
                  <Skeleton variant="rectangular" width={250} height={200} />
                  <Skeleton
                    variant="text"
                    width={200}
                    height={40}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={150}
                    height={30}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                </Stack>
                <Stack spacing={1}>
                  <Skeleton variant="rectangular" width={250} height={200} />
                  <Skeleton
                    variant="text"
                    width={200}
                    height={40}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={150}
                    height={30}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                </Stack>
                <Stack spacing={1}>
                  <Skeleton variant="rectangular" width={250} height={200} />
                  <Skeleton
                    variant="text"
                    width={200}
                    height={40}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={150}
                    height={30}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                </Stack>
              </>
            )}
          </Box>
        </Container>
      </Box>
      {/* best of plan properties  */}
      <Box sx={{ pt: "0px", mt: "50px" }}>
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Heading title={"BEST OFF PLAN Properties"} />
            <Button
              sx={{
                fontSize: { xs: "12px", sm: "15px" },
                color: "#222",
                fontFamily: "'Nunito Sans', sans-serif",
                fontWeight: "600",
              }}
            >
              <Link
                to="/premiumsuperhotfeatured?type=Best Off Plan"
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                {" "}
                Show All{" "}
              </Link>{" "}
            </Button>
          </Box>
          <Box
            sx={{
              display: { xs: "", md: "flex" },
              justifyContent: "space-between",
              my: "30px",
            }}
          >
            {bestoffplan.length > 0 ? (
              bestProperties
            ) : (
              <>
                <Stack spacing={1}>
                  <Skeleton variant="rectangular" width={250} height={200} />
                  <Skeleton
                    variant="text"
                    width={200}
                    height={40}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={150}
                    height={30}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                </Stack>
                <Stack spacing={1}>
                  <Skeleton variant="rectangular" width={250} height={200} />
                  <Skeleton
                    variant="text"
                    width={200}
                    height={40}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={150}
                    height={30}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                </Stack>
                <Stack spacing={1}>
                  <Skeleton variant="rectangular" width={250} height={200} />
                  <Skeleton
                    variant="text"
                    width={200}
                    height={40}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={150}
                    height={30}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                </Stack>
              </>
            )}
          </Box>
        </Container>
      </Box>

      {/* featured properties  */}
      <Box sx={{ pt: "0px", mt: "50px" }}>
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Heading title={"Featured Properties"} />
            <Button
              sx={{
                fontSize: { xs: "12px", sm: "15px" },
                color: "#222",
                fontFamily: "'Nunito Sans', sans-serif",
                fontWeight: "600",
              }}
            >
              <Link
                to="/premiumsuperhotfeatured?type=Featured"
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                {" "}
                Show All{" "}
              </Link>{" "}
            </Button>
          </Box>
          <Box
            sx={{
              display: { xs: "", md: "flex" },
              justifyContent: "space-between",
              my: "30px",
            }}
          >
            {featured.length > 0 ? (
              featuredProperties
            ) : (
              <>
                <Stack spacing={1}>
                  <Skeleton variant="rectangular" width={250} height={200} />
                  <Skeleton
                    variant="text"
                    width={200}
                    height={40}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={150}
                    height={30}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                </Stack>
                <Stack spacing={1}>
                  <Skeleton variant="rectangular" width={250} height={200} />
                  <Skeleton
                    variant="text"
                    width={200}
                    height={40}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={150}
                    height={30}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                </Stack>
                <Stack spacing={1}>
                  <Skeleton variant="rectangular" width={250} height={200} />
                  <Skeleton
                    variant="text"
                    width={200}
                    height={40}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton
                    variant="text"
                    width={150}
                    height={30}
                    sx={{ fontSize: "1rem" }}
                  />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                </Stack>
              </>
            )}
          </Box>
        </Container>
      </Box>

      {/* customer reviews  */}
      <Box sx={{ pt: "0px", mt: "50px" }}>
        <Container maxWidth="lg">
          <Heading title={"Customer Reviews"} />

          {/* <Box sx={{ display: { xs: '', md: 'flex', }, justifyContent: "space-between", my: '30px', }}> */}
          <Box sx={{ my: "30px" }}>
            {
              <CustomerReview />
              // reviewlist
            }
          </Box>
        </Container>
      </Box>

      {/* our affiliation  */}
      <Box sx={{ pt: "0px", mt: "50px" }}>
        <Container maxWidth="lg">
          <Heading title={"Our affiliations"} />
          <Box sx={{ my: "30px", px: "10px" }}>
            <Affiliations />
          </Box>
        </Container>
      </Box>

      {/* main foooter  */}
      <Box sx={{ pt: "50px", mt: "50px", backgroundColor: "#2c2c2c" }}>
        <Container maxWidth="lg">
          <Box>
            <MainFooter />
          </Box>
        </Container>
        <Box sx={{ borderTop: "0.5px solid #666666", py: "20px", mt: "25px" }}>
          {/* <Box sx={{ border: "1px solid red" }}> */}
          <Container>
            <Newsletter />
          </Container>
          {/* </Box> */}
        </Box>
      </Box>

      {/* bottom footer  */}
      <Box sx={{ backgroundColor: "#272727", py: "15px" }}>
        <Container maxWidth="lg">
          <BottomFooter />
        </Container>
      </Box>
      {/* footer top scroll button  */}
      <FooterButton scroll={props.scroll} />
    </>
  );
};

export default Home;
