import React, { useState, useEffect } from "react";
import { Box, Container, Stack } from "@mui/system";
import { Button, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import cityluxeLogo from "../assests/logo_img/cl-logo.png";

const Page_404 = () => {
    const [redirect, setRedirect] = useState(true);
    const [cancelClicked, setCancelClicked] = useState(false);
    const [countdown, setCountdown] = useState(20);
    const navigate = useNavigate();
  
    useEffect(() => {
      const timer = setTimeout(() => {
        if (redirect && !cancelClicked) {
          navigate("/");
        }
      }, countdown * 1000);
  
      return () => clearTimeout(timer);
    }, [redirect, navigate, cancelClicked, countdown]);
  
    useEffect(() => {
      if (countdown > 0 && !cancelClicked) {
        const interval = setInterval(() => {
          setCountdown(prevCountdown => prevCountdown - 1);
        }, 1000);
        return () => clearInterval(interval);
      }
    }, [countdown, cancelClicked]);
  
    const cancelRedirection = () => {
      setRedirect(false);
      setCancelClicked(true);
    };
  
    return (
      <>
        <Box sx={{}}>
          <Container>
            <Box sx={{ marginY: "15%" }}>
              <Stack>
                <center>
                  <img
                    src={cityluxeLogo}
                    style={{ width: "45%" }}
                    alt="Logo of cityluxe"
                  />
                  <h3
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      marginTop: "40px",
                      marginBottom: "20px",
                    }}
                  >
                    404 Page Not Found
                  </h3>
  
                  {!cancelClicked && (
                    <>
                      <Typography>
                        You will be redirected to the home page after {countdown} seconds.
                        If you want to stay on this page, click the cancel button.
                      </Typography>
                      <Button
                        onClick={cancelRedirection}
                        variant="contained"
                        sx={{
                          paddingX: "19px",
                          marginY: "20px",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                        size="small"
                        color="primary"
                      >
                        Cancel Redirection
                      </Button>
                    </>
                  )}
  
                  {cancelClicked && (
                    <Typography sx={{ marginBottom: "10px" }}>
                      Redirection is cancelled. You can continue to stay on this
                      page.
                    </Typography>
                  )}
                  <br />
                  <Button
                    variant="contained"
                    sx={{
                      paddingX: "82px",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                    size="small"
                    color="primary"
                    component={Link}
                    to="/"
                  >
                    Home
                  </Button>
                </center>
              </Stack>
            </Box>
          </Container>
        </Box>
      </>
    );
  };
  
  export default Page_404;