import React, { useState, useEffect, useContext } from "react";
import TopNavbar from "../../components/top_navbar";
import ManManu from "../../components/main_manu";
import { useParams } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  Stack,
  Paper,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import parse from "html-react-parser";
import Breadcrumb from "../../components/bread_crumb";
import MainFooter from "../../components/main_footer";
import BottomFooter from "../../components/botttom_footer";
import Newsletter from "../../components/newsletter";
import FooterButton from "../../components/footer_button";
import Skeleton from "@mui/material/Skeleton";
import BlogCardView from "./BlogCardView";
import Axios from "axios";
import BlogsContactForm from "./BlogsContactForm";


const SingleBlogDetails = (props) => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
 
 
   let matches = props.matches;
  let manmanu = {};
  if (matches) {
    if (props.scroll) {
      manmanu = {
        width: "100%",
        py: "0px",
        mt: "-1px",
        bgcolor: "white",
        position: "fixed",
        top: 0,
        zIndex: 100,
        animation: "scrollmanu 0.3s linear",
        "@keyframes scrollmanu": {
          from: {
            py: "10px",
          },
          to: {
            py: "0px",
          },
        },
      };
    } else {
      manmanu = {
        width: "100%",
        py: "10px",
        mt: "-1px",
        bgcolor: "custom.white",
        position: "unset",
        zIndex: 100,
        animation: "scrollmanu2 0.3s linear",
        "@keyframes scrollmanu2": {
          from: {
            py: "0px",
          },
          to: {
            py: "10px",
          },
        },
      };
    }
  } else {
    manmanu = {
      width: "100%",
      bgcolor: "custom.dark4",
    };
  }
  
  useEffect(() => {
    // Fetch blog data by 'id' and update the state
    Axios.post(`https://cityluxedxb.com/cityluxedxb/all_blogs.php?id=${id}`)
      .then((response) => {
        const blogPost = response.data.blogslist.find((post) => post.id === Number(id));

      if (blogPost) {
        setBlog(blogPost);
      } else {
        console.warn("Blog post with id not found");
      }
    })
    .catch((error) => {
      console.error("Error fetching blog data: ", error);
    });
  }, [id]);

  return (
    <>
      <Box
        sx={{ position: "relative", top: 0, left: 0,}}>
        
        {/* TopNavBar Section */}
        <Box bgcolor={{ xs: "custom.dark", md: "custom.dark" }}>
          <Container maxWidth="lg">
            <TopNavbar matches={matches} />
          </Container>
        </Box>
        {/* NavBar/main-menu section */}
        <Box sx={manmanu}>
          <Container maxWidth="lg">
            <ManManu scroll={props.scroll} color={"dark2"} />
          </Container>
        </Box>
      </Box>
      {/* main content section after Headers */}
      <Container maxWidth="lg">
      <Stack sx={{padding:"16px 5px"}}>
                    <Breadcrumb breadcrumb={["home", "Blogs"  ]} />
                  </Stack>
        <Box display="flex" justifyContent="center">
          <Grid container spacing={3} sx={{  display: { xs: 'block', sm: 'block', md: 'flex', lg:"flex" } }}>
            {/* Main Blog Content */}
            <Grid item xs={12} md={8}>
              {blog ? (
                <Paper elevation={3}>
                <Typography variant="h4" style={{ padding: "16px 5px" }}>
                  {blog.title}
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between", padding: "5px" }}>
                  <Typography variant="subtitle2">
                    Last Updated- {blog.posted_on}
                  </Typography>
                </Box>
  
                {Array.from({ length: 12 }).map((_, index) => (
  <div key={index}>
    {blog[`image${index + 1}`] && (
      <img 
        src={blog[`image${index + 1}`]}
        alt={`Blog ${index + 1}`}
        style={{ width: "100%", maxHeight: "400px", objectFit: "cover" }}
      />
    )}

    {blog[`text${index + 1}`] && (  // Check if text exists before rendering
      <Typography variant="body1" style={{ padding: "16px", fontFamily: "'Nunito Sans', sans-serif", fontSize: "16px", fontWeight: "500", lineHeight: "1.6", marginBottom: "16px" }}>
        {parse(blog[`text${index + 1}`])}
      </Typography>
    )}
  </div>
))}
            </Paper>
              ) : (
                <Skeleton variant="rectangular" width="100%" height={400} />
              )}
             
            </Grid>

            {/* Contact Form */}
            <Grid item sm={12} xs={12} md={4}>
            <BlogsContactForm />
            </Grid>
          </Grid>
        </Box>
      </Container>

      {/* MainFooter Section */}
      <Box sx={{ pt: "50px", backgroundColor: "#2c2c2c" }}>
        <Container maxWidth="lg">
          <Box>
            <MainFooter />
          </Box>
        </Container>
        {/* NewsLetter subscription section */}
        <Box sx={{ borderTop: "0.5px solid #666666", py: "20px", mt: "25px" }}>
          <Container>
            <Newsletter />
          </Container>
        </Box>
      </Box>
      {/* Bottom footer section*/}
      <Box sx={{ backgroundColor: "#272727", py: "15px" }}>
        <Container maxWidth="lg">
          <BottomFooter />
        </Container>
      </Box>
      <FooterButton scroll={props.scroll} />
    </>
  );
};

export default SingleBlogDetails;
