import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import TopNavbar from "../components/top_navbar";
import ManManu from "../components/main_manu";
import {
  Box,
  Container,
  Card,
  Typography,
  Stack,
  Button,
  TextField,
} from "@mui/material";
import Pro_img from "../uploads/2020/07/100720221657461790.jpeg";
import parse from "html-react-parser";
import Breadcrumb from "../components/bread_crumb";
import Advance_search from "../components/advance_search";
import MainFooter from "../components/main_footer";
import BottomFooter from "../components/botttom_footer";
import Newsletter from "../components/newsletter";
import FooterButton from "../components/footer_button";
import SliderWithPriview from "../components/img_slider_priview";
import Delar_img from "../assests/logo_img/delar.png";
import OurListings from "../components/our_listings";
import Client from "../components/axios";
import Snackbar from "@mui/material/Snackbar";
import { useLocation, useNavigate } from "react-router-dom";
import SquareFootOutlinedIcon from "@mui/icons-material/SquareFootOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import Skeleton from "@mui/material/Skeleton";
import { numberWithCommas } from "../components/subcomponent/global_functions";
import { cardstyle } from "../theme/theme";
import UserContextCurrency from "../context/currencyChange";
import CardView from "../components/card_view";
import SimilarPropertiesSlider from "../components/similar_properties_slider";
import Modal from "@mui/material/Modal";
import PhoneInput from "react-phone-input-2";
import { FilteredRecordsContext } from "./FilteredRecordsContext";

const agentModalSendMail = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const SinglePropertyDetails = (props) => {
  const [ record, setRecord ] = useState([]);
  const { filteredRecord, setFilteredRecord } = useContext(FilteredRecordsContext);
  const [readMore, setReamMore] = useState(false);
  const [permitNumber, setPermitNumber] = useState("");
  const [heroBackgroundImage, setHeroBackgroundImage] = useState(Pro_img);
  const [firstImageUrl, setFirstImageUrl] = useState("")
  const [formData, setFormData] = useState({
    countryCode: 'ae',
    phone: "",
  });
  const [advanceSearchKeywords, setAdvanceSearchKeywords] = useState({
    searchKeyword: [],
    category: "",
    type: "",
    minBed: "",
    maxBed: "",
    minPrice: "",
    maxPrice: "",
    minArea: "",
    maxArea: "",
  });
  const [totalcount, setTotalCount] = useState(0);
  const [searchStatus, setSearchStatus] = useState('loading');
  const [highlow, setHighLow] = useState({ sorting: "none" });
  const [selectcount, setselectcount] = useState({ page: 0 });

  const handleMobileNumberChange = (value, country) => {
    const sanitizedValue = '+' + value.replace(/\D/g, "").slice(0, 15); // Sanitize input to 15 digits

    setFormData((prevFormData) => ({
      ...prevFormData,
      countryCode: country.countryCode,
      phone: sanitizedValue,
    }));
  };
  const handleCardClick = () => {
    if(firstImageUrl){
      setHeroBackgroundImage(firstImageUrl)
    }
  };
  const location = useLocation();
  const {
    currencySign,
    setCurrencySign,
    value,
    setValue,
    areaType,
    setAreaType,
  } = useContext(UserContextCurrency);

  let readDiv = {
    backgroundColor: "#FFF",
    position: "absolute",
    width: "100%",
    padding: "12px 0px",
    bottom: "0px",
  };

  let styleReadMore = {};
  if (readMore) {
    styleReadMore = {
      height: "content-fit",
    };
  } else {
    styleReadMore = {
      height: "400px",
    };
  }

  //open modal for  agent contact
  const [agentOpenModal, setAgentOpenModal] = useState(false);
  const AgentHandleOpen = () => setAgentOpenModal(true);
  const AgentHandleClose = () => setAgentOpenModal(false);

  let state = location.state.item;
let newImage = state.Images[0];
  let member = location.state.member;
  let date = state.Last_Updated.split(" ");
  let facilities = state.Facilities.slice(0, -1);
 
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = state.Property_Title;
  }, []);

  useEffect(() => {
    setHeroBackgroundImage(newImage);
    
    // Fetch similar properties
    Client.post("/cityluxedxb/similar_properties.php", {
      ad_type: state.Ad_Type,
      emirate: state.Emirate,
      unit_tpye: state.Unit_Type,
      Unit_Reference_No: state.Unit_Reference_No,
      QR_Code: state.QR_Code,
      permit_number: state.permit_number,
      Images: state.Images,
      Frequency :state.Frequency,
      Community :state.Community,
    })
      .then((response) => {
        setPermitNumber(response.data.permit_number);
        if (response.data.errorcode === 200) {
          setRecord(response.data.propertieslist);
          // Extract the first image URL from the API response
          if (response.data.propertieslist.length > 0) {
            const firstImage = response.data.propertieslist[0].Images[0];
            setFirstImageUrl(firstImage);
          }
  
          window.scrollTo(0, 0);
        } else {
          setRecord(response.data.propertieslist);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [state]);
  
  const formattedFrequency = state.Frequency.replace("Per Year", "/Year");


  let areaSize = "";
  let TypeArea = "";
  if (areaType == "SQFT") {
    // 0.25
    TypeArea = "sqft";
    areaSize = numberWithCommas(
      Math.round(parseFloat(state.Unit_Builtup_Area))
    );
  } else if (areaType == "SQM") {
    // 0.22
    TypeArea = "sqm";
    areaSize = numberWithCommas(
      Math.round(parseFloat(state.Unit_Builtup_Area) * 0.09290304)
    );
  }

  let price = 0;
  let priceCurrency = "";

  if (currencySign == "EUR") {
    // 0.25
    priceCurrency = "EUR";
    price = numberWithCommas(Math.round(parseFloat(state.Price) * 0.25));
  } else if (currencySign == "GBP") {
    // 0.22
    priceCurrency = "GBP";
    price = numberWithCommas(Math.round(parseFloat(state.Price) * 0.22));
  } else if (currencySign == "USD") {
    // 0.27
    priceCurrency = "USD";
    price = numberWithCommas(Math.round(parseFloat(state.Price) * 0.27));
  } else if (currencySign == "INR") {
    // 22.26
    priceCurrency = "INR";
    price = numberWithCommas(Math.round(parseFloat(state.Price) * 22.35));
  } else {
    priceCurrency = "AED";
    price = numberWithCommas(state.Price);
  }

  let matches = props.matches;
  let manmanu = {};
  if (matches) {
    if (props.scroll) {
      manmanu = {
        width: "100%",
        py: "0px",
        mt: "-1px",
        bgcolor: "white",
        position: "fixed",
        top: 0,
        zIndex: 100,
        animation: "scrollmanu 0.3s linear",
        "@keyframes scrollmanu": {
          from: {
            py: "10px",
          },
          to: {
            py: "0px",
          },
        },
      };
    } else {
      manmanu = {
        width: "100%",
        py: "10px",
        mt: "-1px",
        bgcolor: "custom.white",
        position: "unset",
        zIndex: 100,
        animation: "scrollmanu2 0.3s linear",
        "@keyframes scrollmanu2": {
          from: {
            py: "0px",
          },
          to: {
            py: "10px",
          },
        },
      };
    }
  } else {
    manmanu = {
      width: "100%",
      bgcolor: "custom.dark4",
    };
  }

  let backgroundimg = {
    position: "fixed",
    zIndex: -1,
    top: 0,
    left: 0,
    width: "100vw",
    height: { xs: "60vh", sm: "600px", md: "100vh" },
    backgroundImage: `url("${heroBackgroundImage}")`, 

    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  const heading = {
    fontFamily: "'Nunito Sans', sans-serif",
    textTransform: "capitalize",
    fontWeight: "700",
    fontSize: "38px",
    lineHeight: "1.1",
    color: "#222222",
    cursor: "pointer",
  };
  const subheading = {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "16px",
    textTransform: "capitalize",
    fontWeight: "700",
    lineHeight: "1.3em",
    color: "#222",
    mb: "10px",
  };
  const textfield = {
    backgroundColor: "#ffffff",
    color: "#8593a9",
    borderRadius: "0px",
    border: "0.5px solid #e6e6e6",
    mb: "10px",
  };
  const text = {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "14px",
    fontWeight: "500",
    color: "#5c727d",
    lineHeight: "1.6em",
    wordBreak: "word-break",
  };

  const buttons = {
    m: 0,
    px: "25px",
    backgroundColor: "custom.lightwheat",
    color: "#4c4f54",
    fontSize: "12px",
    borderRadius: "0px",
    "&:hover": { backgroundColor: "#4c4f54", color: "custom.lightwheat" },
  };
  const [alertMessage, setAlertMessage] = useState("");
  const [state2, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = state2;
  let handleClick = (newState) => {
    setState({ open: true, ...newState });
  };
  let handleClose = () => {
    setState({ ...state2, open: false });
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
        sx={{ color: "red" }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  let [phone, setPhone] = useState("");
  let [message, setMessage] = useState("");

  const SendDetailsToAgent = () => {
    if (name !== "") {
      if (email !== "") {
        Client.post("/cityluxedxb/contact.php", {
          name: name,
          email: email,
          phone: formData.phone,
          message: message,
          property_ref_no: state.Property_Ref_No,
          agentId: member.id,
          agentName: member.name,
          agentEmail: state.Listing_Agent_Email,
          page_name:"Single property page",
          page_link:`https://www.pscityluxe.com/properties${state.Property_Title}`
        })
          .then((response) => {
            if (response.data.errorcode == 200) {
              setEmail("");
              setPhone("");
              setMessage("");
              setAlertMessage("Submited Successfully!");
              handleClick({ vertical: "top", horizontal: "right" });
              AgentHandleClose();
            } else {
              setAlertMessage("Submited Successfully!");
              handleClick({ vertical: "top", horizontal: "right" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setAlertMessage("Please Enter Email Id");
        handleClick({ vertical: "top", horizontal: "right" });
      }
    } else {
      setAlertMessage("Please Enter Name");
      handleClick({ vertical: "top", horizontal: "right" });
    }
  };

  const navigate = useNavigate();
  let single_team_member_details = () => {
    navigate(`/team-member-details/${member.id}`);
  };

  const handleCallClick = () => {
    const phoneNumber = "1234567890";
    window.open(`tel:${phoneNumber}`);
  };

  let allproperties = record.map((value, index) => (
    <CardView key={index} listitem={value} cardwidth={"49%"} onDataFromChild={handleCardClick} />
  ));

  const phoneNumber = "+971554235695"; 

  const handleWhatsAppClick = () => {
    window.open(`https://wa.me/${phoneNumber}`, "_blank");
  };

  const propertyName = state.Property_Name.toUpperCase();
  const emirate = state.Emirate.toUpperCase();
  const community = state.Community.toUpperCase();
  
  let get_search_keyword_from_advance_search = (value) => { setAdvanceSearchKeywords(value); };
  useEffect(() => {
    document.title = "Properties-city luxe"
   
    Client.post("/cityluxedxb/advance_search.php", {
      advanceSearchKeywords,
      highlow,
      selectcount,
    })
      .then((response) => {
        if (response.data.errorcode === "200" && response.data.totalRecords > 0) {
          let listOfProperty = response.data.propertieslist;
          let totalRecords = response.data.totalRecords; 
          setRecord([]);
          setRecord(listOfProperty);
          setFilteredRecord(listOfProperty);
          // console.warn("filtered properties from single", filteredRecord);
          let totalRecord = totalRecords / 10;
          let decimal_value = totalRecord - Math.floor(totalRecord);
          if (decimal_value > 0) {
            setTotalCount(Math.floor(totalRecord) + 1);
          } else {
            setTotalCount(totalRecord);
          }
          setSearchStatus('success'); 
        } else if (response.data.errorcode === "400") {
          setSearchStatus('not found'); 
        } else {
          setSearchStatus('error'); 
        }
      })
      .catch((err) => {
        console.log(err);
        setSearchStatus('error');
      });
    }, [advanceSearchKeywords, highlow, selectcount, setRecord]);   

  return (
    <>
      {" "}
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={alertMessage}
        key={vertical + horizontal}
        action={action}
      ></Snackbar>
      <Box
        sx={{
          position: "relative",
          top: 0,
          left: 0,
          minHeight: {
            xs: "60vh",
            sm: "600px",
            md: "580px",
            lg: "580px",
            xl: "580px",
          },
        }}
      >
        <Box bgcolor={{ xs: "custom.dark", md: "custom.dark" }}>
          <Container maxWidth="lg">
            <TopNavbar matches={matches} />
          </Container>
        </Box>
        <Box sx={manmanu}>
          <Container maxWidth="lg">
            <ManManu scroll={props.scroll} color={"dark2"} />
          </Container>
        </Box>
        <Box sx={backgroundimg}></Box>
        
      </Box>
      <Box sx={{ backgroundColor: "#f7f7f7", border: "1px solid #fff" }}>
        <Container maxWidth="lg" sx={{ pt: "15px" }}>
          <Breadcrumb
            breadcrumb={["home", "properties", state.Property_Title]}
          />
        </Container>
        <Container
          maxWidth="lg"
          sx={{
            my: "27px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: "67%" },
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              height: " fit-content",
            }}
          >
            <Stack>
              <Box sx={{ my: "10px" }}>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  sx={{ ...heading, width:"-webkit-fill-available" }}
                >
                  {state.Property_Title}
                </Typography>
              </Box>

              <Box sx={{ width: "100%", mb: "35px" }}>
                <SliderWithPriview allimage={state.Images} />
              </Box>
            </Stack>

            <Card
              sx={{
                ...cardstyle,
                mb: "35px",
                padding: "30px 30px 10px 30px",
                width: "100%",
                justifyContent: "space-around",
              }}
            >
              <Box sx={{ mb: "10px", display: "flex" }}>
                <Stack>
                  <Typography
                    variant="body2"
                    sx={{ ...subheading, fontWeight: "bold", fontSize: "16px" }}
                  >
                    {propertyName},{" "}{community},{" "}
                    {emirate}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ ...subheading, fontWeight: "bold", fontSize: "16px" }}
                  >
                    {priceCurrency} {price} 
                    <span style={{fontSize:"11px", fontWeight:"bold"}}> {formattedFrequency}</span> 
                  </Typography>

                  <Stack
                    flexDirection="row"
                    flexWrap="wrap"
                    display={"flex"}
                    justifyContent={"space-between"}
                    marginRight={"25px"}
                  >
                    {state.Bedrooms > 0 && (
                      <Box
                        sx={{
                          minWidth: "118px",
                          paddingRight: "10px",
                          display: "contents",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            ...subheading,
                            marginRight: "10px",
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                        >
                          <BedOutlinedIcon />
                          {state.Bedrooms} Bedrooms
                        </Typography>
                      </Box>
                    )}

                    {state.No_of_Bathroom > 0 && (
                      <Box sx={{ minWidth: "118px", display: "contents" }}>
                        <Typography
                          variant="body2"
                          sx={{
                            ...subheading,
                            fontWeight: "600",
                            marginRight: "10px",
                            fontSize: "14px",
                          }}
                        >
                          <BathtubOutlinedIcon /> {state.No_of_Bathroom}{" "}
                          Bathrooms
                        </Typography>
                      </Box>
                    )}

                    <Box sx={{}}>
                      <Box sx={{ minWidth: "118px", display: "contents" }}>
                        <Typography
                          variant="body2"
                          sx={{
                            ...subheading,
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                        >
                          <SquareFootOutlinedIcon /> {areaSize} {TypeArea}
                        </Typography>
                      </Box>
                      {/* )} */}
                    </Box>
                  </Stack>
                  <Box sx={{ minWidth: "118px", display: "contents" }}>
                    <Typography
                      variant="body2"
                      sx={{
                        ...subheading,
                        fontWeight: "500",
                        fontSize: "12px",
                      }}
                    >
                      Reference-{state.Unit_Reference_No}
                    </Typography>
                  </Box>
                </Stack>
                <Stack
                  height={"8rem"}
                  borderRight={".5px solid"}
                  marginTop={"13px"}
                  marginLeft={"auto"}
                ></Stack>
                <Stack
                  sx={{
                    display: {
                      xs: "contents",
                      sm: "contents",
                      lg: "flex",
                      xl: "flex",
                    },
                    minWidth: "122px",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      marginLeft: {
                        xs: "1rem",
                        sm: "1rem",
                        md: "1rem",
                        lg: "1.9rem",
                      },}}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: "500",
                        fontSize: "12px",
                        marginLeft: "3rem",
                      }}>Permit</Typography>

                    {/* QR code and permit section */}
                    <Typography
                      variant="body2"
                      sx={{
                        ...subheading,
                        fontWeight: "500",
                        fontSize: "12px",
                        marginLeft: "11px",
                        marginRight: "11px",
                      }}>
                      {state.permit_number && (
                        <Box sx={{ textAlign: "center" }}>
                          <img
                            src={`https://cityluxedxb.com/permit_no_qr/${state.permit_number}-original.png`}
                            alt={`QR Code - ${permitNumber}`}
                            style={{
                              width: "120px",
                              height: "120px",
                              marginBottom: "3px",
                              marginRight:"10px"
                            }}/>

                          <br></br>
                          {state.permit_number}
                        </Box>
                      )}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Card>

            <Card
              sx={{
                ...cardstyle,
                mb: "35px",
                p: "15px",
                width: "100%",
                position: "relative",
              }}
            >
              <Typography
                gutterBottom
                variant="subtitle1"
                component="div"
                sx={{ ...subheading, textTransform: "uppercase" }}>Description</Typography>

              <Box sx={{ ...text, ...styleReadMore }}>
                {parse(`${state.Web_Remarks}`)}
              </Box>

              <Box sx={{ ...readDiv, mt: "10px" }}>
                <Stack onClick={() => setReamMore(!readMore)} sx={{...subheading, fontSize: "14px",textTransform: "capitalize", cursor: "pointer", color: "#d7c3a8",}}>
                  {readMore ? "Read less" : "Read more"}
                </Stack>
              </Box>
            </Card>
            <Card sx={{ ...cardstyle, mb: "35px", p: "30px", width: "100%" }}>
              <Typography
                gutterBottom
                variant="subtitle1"
                component="div"
                sx={{ ...subheading, textTransform: "uppercase" }}
              >
                Amenities
              </Typography>

              <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px 0px" }}>
                {facilities.map((val, index) => {
                  // ,
                  return (
                    <Stack
                      sx={{
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexBasis: { xs: "100%", sm: "50%" },
                      }}
                      key={index}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          ...subheading,
                          fontWeight: "600",
                          fontSize: "14px",
                          mb: "0px",
                        }}
                      >
                        <ArrowRightOutlinedIcon /> {val}
                      </Typography>
                    </Stack>
                  );
                })}
              </Box>
            </Card>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                gutterBottom
                variant="subtitle1"
                component="div"
                sx={{
                  ...subheading,
                  fontSize: "24px",
                  textTransform: "uppercase",
                }}
              >
                Similar Listings
              </Typography>

              <Button
                sx={{
                  fontSize: { xs: "12px", sm: "15px" },
                  color: "#222",
                  fontFamily: "'Nunito Sans', sans-serif",
                  fontWeight: "600",
                }}
              >
                <Link
                  to="/premiumsuperhotfeatured?type=Super Hot"
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  {" "}
                  Show All{" "}
                </Link>{" "}
              </Button>
            </Box>

            <Box sx={{ width: "100%" }}>
              {record.length > 0 ? (
                <SimilarPropertiesSlider properties={record} />
              ) : (
                <>
                  <Stack spacing={1}>
                    <Skeleton variant="rectangular" width={400} height={200} />
                    <Skeleton
                      variant="text"
                      width={300}
                      height={40}
                      sx={{ fontSize: "1rem" }}
                    />
                    <Skeleton
                      variant="text"
                      width={300}
                      height={30}
                      sx={{ fontSize: "1rem" }}
                    />
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  </Stack>
                  <Stack spacing={1}>
                    <Skeleton variant="rectangular" width={400} height={200} />
                    <Skeleton
                      variant="text"
                      width={300}
                      height={40}
                      sx={{ fontSize: "1rem" }}
                    />
                    <Skeleton
                      variant="text"
                      width={300}
                      height={30}
                      sx={{ fontSize: "1rem" }}
                    />
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  </Stack>
                </>
              )}
            </Box>
          </Box>

          <Box sx={{ width: { xs: "100%", md: "28%" } }}>
            {/* Advance search section */}
            <Card sx={{ ...cardstyle, mb: "35px", p: "30px" }}>
              <Typography
                gutterBottom
                variant="subtitle1"
                component="div"
                sx={{ ...subheading, textTransform: "uppercase" }}
              >
                Advanced Search
              </Typography>
              <Advance_search sendKeywords={get_search_keyword_from_advance_search} />
            </Card>

            {/* Our listings Section */}
            <Card sx={{ ...cardstyle, mb: "35px", p: "30px" }}>
              <Typography
                gutterBottom
                variant="subtitle1"
                component="div"
                sx={{ ...subheading, textTransform: "uppercase" }}
              >
                Our Listings
              </Typography>
              <OurListings />
            </Card>

            <Card
              sx={{
                ...cardstyle,
                mb: "35px",
                p: "30px",
                width: "100%",
                top: "100px",
                position:"sticky",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "column" },
                  justifyContent: "flex-start",
                }}
              >
                <Stack
                  marginBottom="20px"
                  sx={{ cursor: "pointer" }}
                  onClick={() => single_team_member_details()}
                >
                  <img
                    src={member.image ? member.image : Delar_img}
                    alt=""
                    width="100%"
                    height="auto"
                  />
                </Stack>
              </Box>

              <Stack
                flexDirection={{ xs: "row", sm: "row" }}
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                marginBottom="0px"
              >
                <Button
                  sx={{ ...buttons, my: { xs: "10px", sm: "0px" } }}
                  onClick={() => {
                    AgentHandleOpen();}}>
                  {/* Email */}
                  <EmailIcon />
                </Button>
                <Button
                  sx={{ ...buttons, my: { xs: "10px", sm: "0px" } }}
                  onClick={() => handleCallClick()}
                >
                  <CallIcon />
                </Button>
                <Button
                  sx={{ ...buttons, my: { xs: "10px", sm: "0px" } }}
                  onClick={handleWhatsAppClick}
                >
                  {/* WhatsApp */}
                  <WhatsAppIcon />
                </Button>
              </Stack>

              <Modal
                open={agentOpenModal}
                onClose={AgentHandleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={{ ...agentModalSendMail }}>
                  <Stack flexDirection={{ xs: "column" }} alignItems="start">
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      component="div"
                      sx={{ ...subheading, textTransform: "uppercase" }}
                    >
                      Connect to {member.name ? member.name : "Agent"}
                    </Typography>

                    <TextField
                      size="small"
                      // label="Type Keywords"
                      type="text"
                      placeholder="Enter Name"
                      sx={{ ...textfield, width: { xs: "100%", md: "100%" } }}
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                    <TextField
                      size="small"
                      // label="Type Keywords"
                      type="email"
                      placeholder="Enter Email"
                      sx={{ ...textfield, width: { xs: "100%", md: "100%" } }}
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                    {/* <TextField
                      size="small"
                      type="number"
                      placeholder="Mobile Number"
                      sx={{ ...textfield, width: { xs: "100%", md: "100%" } }}
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                    /> */}
                    <PhoneInput
  country={formData.countryCode}
  value={formData.phone}
  onChange={(value, country) => handleMobileNumberChange(value, country)}
  countryCodeEditable={false}
  inputProps={{
    name: "phone",
    required: true,
    style: {
      width: "335px",
      height: "42px",
      borderRadius: "0px",
      border: "1px solid #e3e0e0",
      marginBottom: "20px",
    },
  }}
/>
                  </Stack>
                  <Stack>
                    <TextField
                      size="small"
                      multiline
                      rows={6}
                      maxRows={8}
                      placeholder="Please Enter The Message"
                      sx={{ ...textfield, width: "100%", height: "100px", marginTop:"10px" }}
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                    />
                  </Stack>

                  <Stack
                    flexDirection={{ xs: "column", sm: "row" }}
                    justifyContent="space-between"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    marginBottom="0px"
                  >
                    <Button sx={{ ...buttons }} onClick={SendDetailsToAgent}>
                      Send
                    </Button>
                    <Button sx={{ ...buttons }} onClick={AgentHandleClose}>
                      Cancel
                    </Button>
                  </Stack>
                </Box>
              </Modal>
            </Card>
          </Box>
        </Container>
      </Box>
      {/* main foooter  */}
      <Box sx={{ pt: "50px", backgroundColor: "#2c2c2c" }}>
        <Container maxWidth="lg">
          <Box>
            <MainFooter />
          </Box>
        </Container>
        <Box sx={{ borderTop: "0.5px solid #666666", py: "20px", mt: "25px" }}>
          <Container>
            <Newsletter />
          </Container>
        </Box>
      </Box>
      {/* bottom footer  */}
      <Box sx={{ backgroundColor: "#272727", py: "15px" }}>
        <Container maxWidth="lg">
          <BottomFooter />
        </Container>
      </Box>
      {/* footer top scroll button  */}
      <FooterButton scroll={props.scroll} />
    </>
  );
};

export default SinglePropertyDetails;