
import React, { useState, useEffect } from "react";
import { cardstyle } from "../theme/theme";
import TopNavbar from "../components/top_navbar";
import ManManu from "../components/main_manu";
import { Box, Container, Card, Typography, Stack, ListItem, Button, TextField } from "@mui/material";
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';

import Project_img from '../uploads/2022/07/100720221657436249-768x523.jpeg';
import parse from 'html-react-parser';

import Breadcrumb from "../components/bread_crumb";
import MainFooter from "../components/main_footer";
import BottomFooter from "../components/botttom_footer";
import Newsletter from "../components/newsletter";
import FooterButton from "../components/footer_button";


import IconButton from '@mui/material/IconButton';
import LinkedIn from "@mui/icons-material/LinkedIn";
import { border, display } from "@mui/system";



const PrivacyPolicy = (props) => {

    let matches = props.matches;
    let manmanu = {}
    if (matches) {
        if (props.scroll) {
            manmanu = {
                width: '100%',
                py: '0px',
                mt: '-1px',
                bgcolor: 'white',
                position: 'fixed',
                top: 0,
                zIndex: 100,
                animation: "scrollmanu 0.3s linear",
                "@keyframes scrollmanu": {
                    from: {
                        py: '10px',
                    },
                    to: {
                        py: '0px',

                    },
                }
            }
        } else {
            manmanu = {
                width: '100%',
                py: '10px',
                mt: '-1px',
                bgcolor: 'custom.white',
                position: 'unset',
                zIndex: 100,
                animation: "scrollmanu2 0.3s linear",
                "@keyframes scrollmanu2": {
                    from: {
                        py: '0px',
                    },
                    to: {
                        py: '10px',
                    },
                }
            }
        }

    } else {
        manmanu = {
            width: '100%',
            bgcolor: 'custom.dark4'
        }
    }

    let backgroundimg = {
        position: 'fixed',
        zIndex: -1,
        top: 0,
        left: 0,
        width: '100vw',
        height: { xs: "60vh", md: '100vh' },
        // backgroundColor :'blue'
        backgroundImage: `url("${Project_img}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'

    }

    const heading = {
        fontWeight: '700',
        fontSize: '28px',
        color: '#222',
        fontFamily: "'Nunito Sans', sans-serif !important",
        my: '10px'
    }

    const subheading = {
        fontWeight: '600',
        fontSize: '14px',
        color: '#5c727d',
        fontFamily: "'Nunito Sans', sans-serif !important",
        my: '15px',
        // border:"1px solid red",
        display: "block"
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'privacy-city luxe'; 
    }, []);

    return (
        <>

            <Box sx={{ position: 'relative', top: 0, left: 0 }}>
                <Box bgcolor={{ xs: 'custom.dark', md: 'custom.dark' }}>
                    <Container maxWidth="lg" >
                        <TopNavbar matches={matches} />
                    </Container>
                </Box>
                <Box sx={{ ...manmanu }}>
                    <Container maxWidth="lg">
                        <ManManu scroll={props.scroll} color={'dark2'} />
                    </Container>
                </Box>
                <Box sx={backgroundimg}>

                </Box>
            </Box>

            <Box sx={{ backgroundColor: '#f7f7f7'}}>

                <Container maxWidth="lg" sx={{ pt: '15px' }}>
                    <Breadcrumb breadcrumb={['Home', 'Privacy Policy']} />
                </Container>

                <Container maxWidth="lg" sx={{ my: '27px' }}>

                    <Typography gutterBottom variant="h6" component="div" sx={{ ...heading, fontSize: "34px" }}>
                        Privacy Policy
                    </Typography>
                    <Box>
                        <Typography gutterBottom variant="h6" component="div" sx={{ ...heading }}  >
                            Who we are
                        </Typography>
                        <Typography component="body2" sx={{ ...subheading }}  >
                            Our website address is: https://www.cityluxedxb.com.
                        </Typography>

                        <Typography gutterBottom variant="h6" component="div" sx={{ ...heading }}  >
                            Comments
                        </Typography>
                        <Typography component="body2" sx={{ ...subheading }}>
                            When visitors leave comments on the site we collect the data shown in the comments form, and also the visitor’s IP address and browser user agent string to help spam detection.
                        </Typography>

                        <Typography component="body2" sx={{ ...subheading }}  >
                            An anonymized string created from your email address (also called a hash) may be provided to the Gravatar service to see if you are using it. The Gravatar service privacy policy is available here: https://automattic.com/privacy/. After approval of your comment, your profile picture is visible to the public in the context of your comment.
                        </Typography>

                        <Typography gutterBottom variant="h6" component="div" sx={{ ...heading }}  >
                            Media
                        </Typography>
                        <Typography component="body2" sx={{ ...subheading }}  >
                            If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website.
                        </Typography>
                        <Typography gutterBottom variant="h6" component="div" sx={{ ...heading }}  >
                            Cookies
                        </Typography>

                        <Typography component="body2" sx={{ ...subheading }}  >
                            If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year.
                        </Typography>
                        <Typography component="body2" sx={{ ...subheading }}  >
                            If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.
                        </Typography>
                        <Typography component="body2" sx={{ ...subheading }}  >
                            When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select “Remember Me”, your login will persist for two weeks. If you log out of your account, the login cookies will be removed.
                        </Typography>
                        <Typography component="body2" sx={{ ...subheading }}  >
                            If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no personal data and simply indicates the post ID of the article you just edited. It expires after 1 day.
                        </Typography>

                        <Typography gutterBottom variant="h6" component="div" sx={{ ...heading }}  >
                            Embedded content from other websites
                        </Typography>
                        <Typography component="body2" sx={{ ...subheading }}  >
                            Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.
                        </Typography>
                        <Typography component="body2" sx={{ ...subheading }}  >
                            These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.
                        </Typography>

                    </Box>

                </Container>
            </Box >

            {/* main foooter  */}
            <Box sx={{ pt: '50px', backgroundColor: '#2c2c2c', }}>
                <Container maxWidth="lg">
                    <Box>
                        <MainFooter />
                    </Box>
                </Container>
                <Box sx={{ borderTop: "0.5px solid #666666", py: "20px", mt: "25px", }}>
                    {/* <Box sx={{ border: "1px solid red" }}> */}
                    <Container>
                        <Newsletter />
                    </Container>
                    {/* </Box> */}
                </Box>
            </Box>
            {/* bottom footer  */}
            < Box sx={{ backgroundColor: '#272727', py: '15px' }}>
                <Container maxWidth="lg">
                    <BottomFooter />
                </Container>
            </Box >
            {/* footer top scroll button  */}
            < FooterButton scroll={props.scroll} />
        </>

    )
};

export default PrivacyPolicy;
