
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { cardstyle } from "../theme/theme";
import TopNavbar from "../components/top_navbar";
import ManManu from "../components/main_manu";
import { Box, Container, Card, Typography, Stack, Button } from "@mui/material";
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import Project_img from '../uploads/2022/07/100720221657436249-768x523.jpeg';
import Breadcrumb from "../components/bread_crumb";
import MainFooter from "../components/main_footer";
import BottomFooter from "../components/botttom_footer";
import Newsletter from "../components/newsletter";
import FooterButton from "../components/footer_button";
import IconButton from '@mui/material/IconButton';
import Twitter from '@mui/icons-material/Twitter';
import LinkedIn from "@mui/icons-material/LinkedIn";
import Heading from "../components/heading";
import { Skeleton } from "@mui/material";
import CardView from "../components/card_view";
import Client from "../components/axios";

const LatestProjects = (props) => {
    const [featured, setfeatured] = useState([]);


    let matches = props.matches;
    let manmanu = {}
    if (matches) {
        if (props.scroll) {
            manmanu = {
                width: '100%',
                py: '0px',
                mt: '-1px',
                bgcolor: 'white',
                position: 'fixed',
                top: 0,
                zIndex: 100,
                animation: "scrollmanu 0.3s linear",
                "@keyframes scrollmanu": {
                    from: {
                        py: '10px',
                    },
                    to: {
                        py: '0px',

                    },
                }
            }
        } else {
            manmanu = {
                width: '100%',
                py: '10px',
                mt: '-1px',
                bgcolor: 'custom.white',
                position: 'unset',
                zIndex: 100,
                animation: "scrollmanu2 0.3s linear",
                "@keyframes scrollmanu2": {
                    from: {
                        py: '0px',
                    },
                    to: {
                        py: '10px',
                    },
                }
            }
        }

    } else {
        manmanu = {
            width: '100%',
            bgcolor: 'custom.dark4'
        }

    }

    let backgroundimg = {
        position: 'fixed',
        zIndex: -1,
        top: 0,
        left: 0,
        width: '100vw',
        height: { xs: "60vh", md: '100vh' },
        backgroundImage: `url("${Project_img}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'

    }

    const heading = {
        textTransform: 'capitalize',
        fontWeight: '550',
        fontSize: '20px',
        color: 'cutome.lightwheat',
        cursor: 'pointer',
        transition: '0.5s',
        '&:hover': {
            color: 'cutome.dark2',
        },
    }



    let featuredProperties = featured.map((value, index) =>
        <CardView key={index} listitem={value} cardwidth={'32%'} />
    );

    let managment_team = [1, 2, 3];
    useEffect(() => {
        window.scrollTo(0, 0);
        Client.post('/cityluxedxb/featured.php')
            .then((response) => {
                if (response.data.errorcode == 200) {
                    setfeatured(response.data.propertieslist);
                    console.warn("response.data",response.data);
                    console.warn("this have all data", featured)
                } else {
                    console.log(response.data);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }, [])

    return (
        <>

            <Box sx={{ position: 'relative', top: 0, left: 0, minHeight: { xs: "60vh", md: '65vh', lg: "62vh", xl: "50vh" } }}>
                <Box bgcolor={{ xs: 'custom.dark', md: 'custom.dark' }}>
                    <Container maxWidth="lg">
                        <TopNavbar matches={matches} />
                    </Container>
                </Box>
                <Box sx={manmanu} >
                    <Container maxWidth="lg">
                        <ManManu scroll={props.scroll} color={'dark2'} />
                    </Container>
                </Box>
                <Box sx={backgroundimg}>

                </Box>
            </Box>

            <Box sx={{ backgroundColor: 'custom.white', border: "1px solid #fff" }}>

                <Container maxWidth="lg" sx={{ pt: '15px' }}>
                    <Breadcrumb breadcrumb={['home', 'projects']} />
                </Container>

                <Container maxWidth="lg" sx={{ my: '27px' }}>
                    <Box sx={{ pt: '0px', mt: '50px' }}>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Heading title={'Latest Project'} />
                            <Button sx={{ fontSize: { xs: "12px", sm: "15px" }, color: '#222', fontFamily: "'Nunito Sans', sans-serif", fontWeight: "600" }} ><Link to='/premiumsuperhotfeatured?type=Featured' style={{ color: 'inherit', textDecoration: 'inherit' }}> Show All  </Link> </Button>
                        </Box>

                        <Box sx={{ display: { xs: '', md: 'flex' }, justifyContent: "space-between", my: '30px' }}>
                            {
                                featured.length > 0 ? (
                                    featuredProperties
                                ) : (
                                    <>
                                        <Stack spacing={1}>
                                            <Skeleton variant="rectangular" width={250} height={200} />
                                            <Skeleton variant="text" width={200} height={40} sx={{ fontSize: '1rem' }} />
                                            <Skeleton variant="text" width={150} height={30} sx={{ fontSize: '1rem' }} />
                                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                        </Stack>
                                        <Stack spacing={1}>
                                            <Skeleton variant="rectangular" width={250} height={200} />
                                            <Skeleton variant="text" width={200} height={40} sx={{ fontSize: '1rem' }} />
                                            <Skeleton variant="text" width={150} height={30} sx={{ fontSize: '1rem' }} />
                                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                        </Stack>
                                        <Stack spacing={1}>
                                            <Skeleton variant="rectangular" width={250} height={200} />
                                            <Skeleton variant="text" width={200} height={40} sx={{ fontSize: '1rem' }} />
                                            <Skeleton variant="text" width={150} height={30} sx={{ fontSize: '1rem' }} />
                                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                        </Stack>
                                    </>

                                )
                            }

                        </Box>
                    </Box >
                </Container>

                <Container maxWidth="lg" sx={{ my: '27px' }}>
                    <Typography gutterBottom variant="h6" component="div" sx={{ fontWeight: '550', fontSize: '25px', color: 'cutome.dark2', my: '10px' }}  >
                        CLIENT ADVISORY TEAM
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        {
                            managment_team.map((val, index) => {
                                return <Card sx={{ ...cardstyle, maxWidth: { xs: '100vw', md: '360px' }, minHeight: '300px', mb: { xs: '20px' } }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="250"
                                            // image={Pro_img}
                                            alt="green iguana"
                                            sx={{ px: '10px', mt: '5px', width: '100%' }}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h6" component="div" sx={{ ...heading, my: '0px' }}  >
                                                Kinshuk S. Kulshreshtha
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" sx={{ mb: '10px' }}>
                                                Chief Investment Advisor
                                            </Typography>

                                            <Typography variant="body2" color="text.secondary">
                                                Specialisation - Land Plots, Luxury Residential
                                            </Typography>
                                        </CardContent>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: '10px', px: '10px' }}>
                                            <IconButton >
                                                <Twitter fontSize='small' sx={{ color: 'custom.dark2' }} />
                                            </IconButton>
                                            <IconButton >
                                                <LinkedIn fontSize='small' sx={{ color: 'custom.dark2' }} />
                                            </IconButton>
                                        </Box>
                                    </CardActionArea>
                                </Card>
                            })
                        }
                    </Box>
                </Container>
            </Box>


            {/* main foooter  */}
            <Box sx={{ pt: '50px', backgroundColor: '#2c2c2c', }}>
                <Container maxWidth="lg">
                    <Box>
                        <MainFooter />
                    </Box>
                </Container>
                <Box sx={{ borderTop: "0.5px solid #666666", py: "20px", mt: "25px", }}>
                    {/* <Box sx={{ border: "1px solid red" }}> */}
                    <Container>
                        <Newsletter />
                    </Container>
                    {/* </Box> */}
                </Box>
            </Box>
            {/* bottom footer  */}
            < Box sx={{ backgroundColor: '#272727', py: '15px' }}>
                <Container maxWidth="lg">
                    <BottomFooter />
                </Container>
            </Box >
            {/* footer top scroll button  */}
            < FooterButton scroll={props.scroll} />
        </>

    )

}

export default LatestProjects;