import React, { createContext, useState } from 'react';


const ProjectDetailsContext = createContext();

export const ProjectDetailsProvider = ({ children }) => {
  const [projectDetails, setProjectDetails] = useState(null);

  return (
    <ProjectDetailsContext.Provider value={{ projectDetails, setProjectDetails }}>
      {children}
    </ProjectDetailsContext.Provider>
  );
};

export const useProjectDetails = () => React.useContext(ProjectDetailsContext);
