import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Container} from "@mui/material";
import EnquireModal from "./EnquireModal";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import "swiper/css"
import "../../components/styles.css";
const useStyles = makeStyles((theme) => ({
  slideContent: {
    position: "relative",
    width: "100%",
    height: "60vh",
  },
  textOverlay: {
    position: "absolute",
    top: "30%",
    color: "white",
    fontWeight: "bolder",
    fontSize: "55px",
    marginBottom: "10px",
    maxWidth: "lg", 
    
  },
  smalltextOverlay: {
    textAlign: "left",
    color: "white",
    letterSpacing: "1px",
    fontSize: "18px",
  },
  enquireBtn:{
    backgroundColor:"#d7c3a8",
    borderRadius:"0", 
    padding:"12px 40px",
    mt:4,
    textAlign: "left",
    fontSize: "18px",
   
  },
  swiperPagination: {
    '& .swiper-horizontal>.swiper-pagination-bullets, & .swiper-pagination-bullets.swiper-pagination-horizontal, & .swiper-pagination-custom, & .swiper-pagination-fraction': {
      position: 'relative !important',
    },
  }


}));

export default function HeroSlider({bannerData}) {
  const classes = useStyles();


  return (
    <Swiper direction={"horizontal"}
                autoplay={{ delay: 2000, disableOnInteraction: false }}
                // autoplay={false}
                loop={true}
                speed={2000}
                pagination={{ clickable: true }}
                mousewheel={true}
                modules={[Autoplay, Pagination]}
                className={`mySwiper ${classes.swiperPagination}`}
                style={{width:"100%"}}
                >
      {bannerData.map((banner) => (
        <SwiperSlide key={banner.id} style={{padding:"0px"}}>
          <div className={classes.slideContent}>
            <img src={banner.banner_link} alt={banner.banner_title} style={{ width: '100%', height: '110%' }} />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Container className={classes.textOverlay}>
                <div>{banner.banner_title}</div>
                <p className={classes.smalltextOverlay}>{banner.banner_text}</p>
                <EnquireModal />
              </Container>
            </Box>
          </div>
        </SwiperSlide>
      ))}
            </Swiper>
  );
}

