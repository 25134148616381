import axios from "axios";

const Client = axios.create({
  baseURL: "",
});
export default Client;

// https://cityluxedxb.globaltechsoft.com
  //  "proxy": "http://localhost:80",
  // http://localhost:3000
