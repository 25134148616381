import React,{useState} from 'react'
import {Typography, Button,TextField, Card, Stack, } from "@mui/material";
  import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    page_name: "contact_page",
    agentName: "",
    agentEmail: "sales@cityluxedxb.com ",
    page_link: "https://www.pscityluxe.com/projects"
  });


  const handleMobileNumberChange = (value) => {
    let sanitizedValue = value.replace(/\D/g, "");
    if (sanitizedValue && sanitizedValue[0] !== "+") {
      sanitizedValue = `+${sanitizedValue}`;
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      phone: sanitizedValue,
    }));
  };
  

  const textfield = {
    backgroundColor: "#ffffff",
    color: "#8593a9",
    borderRadius: "0px",
    border: "0.5px solid #e6e6e6",
    mb: "10px",
  };
  const subheading = {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "17px",
    textTransform: "uppercase",
    fontWeight: "700",
    color: "#434953",
    mb: "10px",
  };
  const buttons = {
    m: 0,
    marginRight: "20px",
    px: "25px",
    backgroundColor: "custom.lightwheat",
    color: "#4c4f54",
    fontSize: "12px",
    borderRadius: "0px",
    "&:hover": { backgroundColor: "#4c4f54", color: "#fff" },
  };

  const sendFormDataToAPI = () => {
    fetch('/cityluxedxb/contact.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
    .then(response => {
      if (response.ok) {
        console.log('Form data sent successfully!');
      } else {
        console.error('Failed to send form data');
      }
    })
    .catch(error => {
      console.error('Error sending form data:', error);
    });
  };

  const handleWhatsAppClick = () => {
    if (!isFormValid()) {
      alert('Please fill all input fields first.');
      return;
    }
    sendFormDataToAPI();
  };

  const handleEmailClick = () => {
    if (!isFormValid()) {
      alert('Please fill all input fields first.');
      return;
    }
    sendFormDataToAPI();
  };

  const handleCallClick = () => {
    if (!isFormValid()) {
      alert('Please fill all input fields first.');
      return;
    }
    sendFormDataToAPI();
    const phoneNumber = "+971554235695";
    const callURL = `tel:${phoneNumber}`;
    window.location.href = callURL;
  };

  const isFormValid = () => {
    return (
      formData.name &&
      formData.email &&
      formData.phone 
    );
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  
  return (
   <>

<Card sx={{mb: "35px", p: "20px", marginTop:"-15px" }}>
                    <Typography gutterBottom variant="subtitle1" component="div" sx={{ ...subheading }} >
                      Contact Us</Typography>
                    <Stack flexDirection={{ xs: "column", md: "column" }} justifyContent="flex-start" alignItems="center" marginBottom="0px" >
                      <TextField size="small" name="name" value={formData.name} onChange={handleInputChange} type="text" placeholder="Enter Name" id='nameTextField' sx={{ ...textfield, width: "100%" }} />
                      <TextField size="small" type="email" name="email" value={formData.email} onChange={handleInputChange} placeholder="Enter Email" id='emailTextField' sx={{ ...textfield, width: "100%" }}/>
                      {/* <TextField size="small" type="number" placeholder="Mobile Number" sx={{ ...textfield, width: "100%" }}/> */}
                      <PhoneInput country={"ae"} value={formData.phone} id="phoneNumberTextField"
            onChange={handleMobileNumberChange}
            countryCodeEditable={false}
            inputProps={{
              name: "phone",
              required: true,
              style: {
                width: "inherit",
                height: "auto",
                borderRadius: "0px",
                border: "1px solid #e3e0e0",
              },
            }}
          />
          
                    </Stack>
                    <Stack sx={{marginTop:"10px"}}>
                      <TextField size="small" name="message"  value={formData.message} onChange={handleInputChange} multiline rows={3} maxRows={3} placeholder="Enter Query..." id="messageTextField" sx={{ ...textfield, width: "100%" }}/>
                    </Stack>
                    <Stack style={{display:"flex", flexDirection:"row", justifyContent:"space-between", width:"107%"}}  >
                      <Button sx={{ ...buttons, my: { xs: "10px", sm: "0px" } }} onClick={handleEmailClick} ><EmailIcon /></Button>
                      <Button sx={{ ...buttons, my: { xs: "10px", sm: "0px" } }} onClick={handleCallClick} > <CallIcon /></Button>
                      <Button sx={{ ...buttons, my: { xs: "10px", sm: "0px" } }} onClick={handleWhatsAppClick} > <WhatsAppIcon /> </Button>
                    </Stack>
                  </Card>
   </>
  )
}

export default ContactUs
