import React from 'react';
import video_carousel from '../assests/videos/site-video-clre.mp4';

function HomeSlider() {
    return (


        <video autoPlay loop muted playsInline className="back_video" >
            <source src={video_carousel} type='video/mp4'></source>
        </video>

    );
}

export default HomeSlider;