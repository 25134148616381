import React, { useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Link,
  useParams,
} from "react-router-dom";
import Client from "../components/axios";
import TopNavbar from "../components/top_navbar";
import ManManu from "../components/main_manu";
import {
  Box,
  Container,
  Card,
  Typography,
  Stack,
  Button,
  TextField,
} from "@mui/material";
import MainFooter from "../components/main_footer";
import BottomFooter from "../components/botttom_footer";
import Newsletter from "../components/newsletter";
import FooterButton from "../components/footer_button";
import IconButton from "@mui/material/IconButton";
import LinkedIn from "@mui/icons-material/LinkedIn";
import { cardstyle } from "../theme/theme";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import Skeleton from "@mui/material/Skeleton";
import SimilarPropertiesSlider from "../components/similar_properties_slider";
import AgentReview from "../components/agent_review";
import AgentDetailsSlider from "../components/agentDetailsSlider";
import { useApiDataContext } from '../App'; 
import PhoneInput from "react-phone-input-2";


const textfield = {
  backgroundColor: "#ffffff",
  color: "#8593a9",
  borderRadius: "0px",
  border: "0.5px solid #e6e6e6",
  mb: "10px",
};

const buttons = {
  m: 0,
  marginRight: "20px",
  px: "25px",
  backgroundColor: "custom.lightwheat",
  color: "#4c4f54",
  fontSize: "12px",
  borderRadius: "0px",
  "&:hover": { backgroundColor: "#4c4f54", color: "#fff" },
};

const SingleTeamMember = (props) => {
  const { updateApiData } = useApiDataContext(); 
  const [data, setData] = useState(props);
  const [memberDetails, setMemberDetails] = useState({});
  const [socialMedia, setSocialMedia] = useState([]);
  const [record, setRecord] = useState([]);
  const [agenList, setAgentList] = useState([]);
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [validationError, setValidationError] = useState('');
  const [agentId, setAgentId] = useState('');
  const [agentName, setAgentName] = useState('');
  const [formData, setFormData] = useState({
    countryCode: 'ae',
    phone: "",
  });
  let { agentID } = useParams();

  let matches = props.matches;
  let manmanu = {};
  if (matches) {
    if (props.scroll) {
      manmanu = {
        width: "100%",
        py: "0px",
        mt: "-1px",
        bgcolor: "white",
        position: "fixed",
        top: 0,
        zIndex: 100,
        animation: "scrollmanu 0.3s linear",
        "@keyframes scrollmanu": {
          from: {
            py: "10px",
          },
          to: {
            py: "0px",
          },
        },
      };
    } else {
      manmanu = {
        width: "100%",
        py: "10px",
        mt: "-1px",
        bgcolor: "custom.white",
        position: "unset",
        zIndex: 100,
        animation: "scrollmanu2 0.3s linear",
        "@keyframes scrollmanu2": {
          from: {
            py: "0px",
          },
          to: {
            py: "10px",
          },
        },
      };
    }
  } else {
    manmanu = {
      width: "100%",
      bgcolor: "custom.dark4",
    };
  }

  const subheading = {
    fontWeight: "600",
    fontSize: "14px",
    color: "#5c727d",
    fontFamily: "'Nunito Sans', sans-serif !important",
    my: "15px",
    display: "block",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setMemberDetails([]);
    Client.post("/cityluxedxb/get_single_team_member.php", {
      id: agentID,
    })
      .then((response) => {
        if (response.data.errorcode == 200) {
          setMemberDetails(response.data.teamList);
          console.warn("member details", response.data.teamList);
          setAgentId(response.data.teamList.id);
          setAgentName(response.data.teamList.name);
          if (Object.keys(response.data.teamList).length > 0) {
            let socialUrl = response.data.teamList.social_media.split("##");
            // console.log(socialUrl);
            setSocialMedia(socialUrl);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [agentID]);

  useEffect(() => {
    if (memberDetails.name !== "") {
      Client.post("/cityluxedxb/get_agent_all_properties.php", {
        agent_name: memberDetails.name,
      })
        .then((response) => {
          if (response.data.errorcode == 200) {
            setRecord(response.data.propertieslist);
            // console.log(response.data);
          } else {
            console.log(response.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setRecord([]);
    }
  }, [memberDetails]);

  useEffect(() => {
    Client.post("/cityluxedxb/get_team_members.php")
      .then((response) => {
        if (response.data.errorcode == 200) {
          // console.log(response.data);
          let teamMemberList = response.data.propertieslist;
          // console.log(teamMemberList);
          setAgentList(teamMemberList);
          
        } else {
          console.log(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

//  show all properties by agent name wise
const handleShowAllClick = async () => {
  try {
    const agent_name = memberDetails.name;
    const response = await fetch("https://cityluxedxb.com/cityluxedxb/agent_wise_properties.php", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        agent_name: agent_name,
      }),
    });

    const result = await response.json();

    if (result.errorcode && result.status) {
      console.warn("API Error:", result.propertieslist);
      setData(result.propertieslist); 
      updateApiData(result.propertieslist); 
      // console.warn("agent's all properties", data);
      navigate("/agent_wise_properties", { state: { pageName: "AgentPage" } });
    } else {
     
      console.log("Agent wise properties:");
      // navigate.push("/properties", { data });
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};


  const textHeading = {
    fontSize: "30px",
    color: "#222",
    fontFamily: "'Nunito Sans', sans-serif",
    fontWeight: "900",
    textTransform: "uppercase",
    lineHeight: "1em",
  };
  const subHeading = {
    fontSize: "14px",
    color: "#5C727D",
    fontFamily: "'Nunito Sans', sans-serif",
    fontWeight: "400",
    textTransform: "capitalize",
  };

  document.title = memberDetails.name;
  
  const validateForm = () => {
    if (name.length < 3) {
      setValidationError('Name should contain at least 3 letters.');
      return false;
    }
    if (!email.includes('@')) {
      setValidationError('Email should contain "@" symbol.');
      return false;
    }
    if (!name || !email || !phone || !message) {
      setValidationError('All fields are mandatory.');
      return false;
    }
    setValidationError('');
    return true;
  };

  const saveFormData = async () => {
    try {
      await axios.post("/cityluxedxb/contact.php", {
        name: name,
        email: email,
        phone: formData.phone,
        message: message,
        page_name:"single_agent_page",
        agentId: agentId,
        agentName : agentName,
        agentEmail:'sales@cityluxedxb.com'
        
      });
      console.log('Form data saved successfully');
      alert('Query submitted successfully!');
    } catch (error) {
      console.error('Error saving form data', error);
      alert('Failed to submit query. Please try again.');
    }
  };
  const handleMobileNumberChange = (value, country) => {
    const sanitizedValue = '+' + value.replace(/\D/g, "").slice(0, 15); // Sanitize input to 15 digits
  
    setFormData((prevFormData) => ({
      ...prevFormData,
      countryCode: country.countryCode,
      phone: sanitizedValue,
    }));
  };
  const handleWhatsAppClick = () => {
    saveFormData();
    const phoneNumber = '+971 55 423 5695';
    const message = encodeURIComponent('Hello, I have a query.');
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.location.href = whatsappURL;
  };
  
  const handleEmailClick = () => {
    saveFormData();  
  };
  
  const handleCallClick = () => {
    saveFormData();
    const phoneNumber = '+971 55 423 5695';  
    const callURL = `tel:${phoneNumber}`;
    window.location.href = callURL;
  };
  return (
    <>
      <Box sx={{ position: "relative", top: 0, left: 0 }}>
        <Box bgcolor={{ xs: "custom.dark", md: "custom.dark" }}>
          <Container maxWidth="lg">
            <TopNavbar matches={matches} />
          </Container>
        </Box>

        <Box sx={manmanu}>
          <Container maxWidth="lg">
            <ManManu scroll={props.scroll} color={"dark2"} />
          </Container>
        </Box>
        
      </Box>

      <Box sx={{ backgroundColor: "#f7f7f7", border: "1px solid #fff" }}>
       
        <Container
          maxWidth="lg"
          sx={{
            my: "27px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box sx={{ width: { xs: "100%", md: "70%" } }}>
            <Card sx={{ ...cardstyle, mb: "35px", p: "30px" }}>
              <Box
                sx={{
                  display: { xs: "column", sm: "flex" },
                  justifyContent: "space-between",
                }}
              >
                <Stack
                  sx={{
                    position: "relative",
                    width: { xs: "100%", sm: "35%" },
                  }}
                >
                  <img src={memberDetails.image} alt="" />
                  <Box
                    sx={{
                      position: "absolute",
                      left: "0px",
                      bottom: "0px",
                      zIndex: 1,
                      my: "5px",
                      px: "5px",
                    }}
                  >
                    {socialMedia.length > 0 ? (
                      socialMedia.map((val, index) => {
                        // console.log(val + 'this is index of arrayF' + index);
                        if (val.includes("twitter") == true) {
                          return (
                            <a href={val} target="_blank">
                              <IconButton
                                sx={{ backgroundColor: "#efefef", mr: "8px" }}
                              >
                                <svg
                                  className="forHover"
                                  viewBox="0 0 24 24"
                                  aria-label="Twitter"
                                  width={12.5}
                                  role="img"
                                  class="your-icon-class"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g>
                                    <path
                                      d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
                                      fill="#777"
                                    ></path>
                                  </g>
                                </svg>
                              </IconButton>
                            </a>
                          );
                        } else if (val.includes("linkedin.com") == true) {
                          return (
                            <a href={val} target="_blank">
                              <IconButton
                                sx={{ backgroundColor: "#efefef", mr: "8px" }}
                              >
                                <LinkedIn
                                  fontSize="small"
                                  sx={{
                                    fontSize: "14.5px",
                                    color: "#777",
                                    "&:hover": { color: "#d7c3a8" },
                                  }}
                                />
                              </IconButton>
                            </a>
                          );
                        }
                      })
                    ) : (
                      <></>
                    )}
                  </Box>
                </Stack>
                <Stack
                  sx={{
                    width: { xs: "100%", sm: "60%" },
                    mt: { xs: "15px", md: "0px" },
                  }}
                >
                  <Typography variant="body2" sx={{ ...textHeading }}>
                    {memberDetails.name}
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{ ...subHeading, fontWeight: "900", my: "5px" }}
                  >
                    {memberDetails.position}
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{ ...subHeading, fontWeight: "600", my: "5px" }}
                  >
                    Specialisation - {memberDetails.specialisation}
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{ ...subHeading, fontWeight: "600", my: "5px" }}
                  >
                    Languages - {memberDetails.languages}
                  </Typography>
                </Stack>
              </Box>

              <Box>
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  component="div"
                  sx={{
                    ...subheading,
                    fontSize: "18px",
                    textTransform: "uppercase",
                    my: "0px",
                    mt: "20px",
                    mb: "5px",
                    fontSize: "200",
                    fontWeight: "800",
                  }}
                >
                  About Me
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    ...subHeading,
                    my: "0px",
                    lineHeight: "1.65em",
                    textTransform: "",
                  }}
                >
                </Typography>
              </Box>
                  {memberDetails.about}{" "}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  mt: "30px",
                }}
              >
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  component="div"
                  sx={{
                    ...subheading,
                    fontSize: "200",
                    fontWeight: "800",
                    textTransform: "uppercase",
                  }}
                >
                  Agent Listings
                </Typography>
                <Button
                  sx={{
                    fontSize: { xs: "12px", sm: "15px" },
                    color: "#222",
                    fontFamily: "'Nunito Sans', sans-serif",
                    fontWeight: "600",
                  }}
                >
                  <Link
                    to=""
                    style={{ color: "inherit", textDecoration: "inherit" }}
                    onClick={handleShowAllClick}
                  >
                    {" "}
                    Show All{" "}
                  </Link>{" "}

                </Button>
              </Box>

              <Box sx={{ width: "100%" }}>
                {record.length > 0 ? (
                  <SimilarPropertiesSlider properties={record} />
                ) : (
                  <>
                    <Stack spacing={1}>
                      <Skeleton
                        variant="rectangular"
                        width={400}
                        height={200}
                      />
                      <Skeleton
                        variant="text"
                        width={300}
                        height={40}
                        sx={{ fontSize: "1rem" }}
                      />
                      <Skeleton
                        variant="text"
                        width={300}
                        height={30}
                        sx={{ fontSize: "1rem" }}
                      />
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                    </Stack>
                    <Stack spacing={1}>
                      <Skeleton
                        variant="rectangular"
                        width={400}
                        height={200}
                      />
                      <Skeleton
                        variant="text"
                        width={300}
                        height={40}
                        sx={{ fontSize: "1rem" }}
                      />
                      <Skeleton
                        variant="text"
                        width={300}
                        height={30}
                        sx={{ fontSize: "1rem" }}
                      />
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                    </Stack>
                  </>
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  mt: "30px",
                  
                }}
              >
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  component="div"
                  sx={{
                    ...subheading,
                    fontSize: "200",
                    fontWeight: "800",
                    textTransform: "uppercase",
                  }}
                >
                  Agent Reviews
                </Typography>
                <Button
                  sx={{
                    fontSize: { xs: "12px", sm: "15px" },
                    color: "#222",
                    fontFamily: "'Nunito Sans', sans-serif",
                    fontWeight: "600",
                  }}
                >
                </Button>
              </Box>

              <Box sx={{ width: "100%" }}>
                <AgentReview agentName={memberDetails.name} />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  mt: "30px",
                }}
              >
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  component="div"
                  sx={{
                    ...subheading,
                    fontSize: "200",
                    fontWeight: "800",
                    textTransform: "uppercase",
                  }}
                >
                  Other Agents
                </Typography>
                <Button
                  sx={{
                    fontSize: { xs: "12px", sm: "15px" },
                    color: "#222",
                    fontFamily: "'Nunito Sans', sans-serif",
                    fontWeight: "600",
                  }}
                >
                </Button>
              </Box>

              <Box sx={{ width: "100%" }}>
                {agenList.length > 0 ? (
                  <AgentDetailsSlider agent={agenList} />
                ) : (
                  <>
                    <Stack spacing={1}>
                      <Skeleton
                        variant="rectangular"
                        width={400}
                        height={200}
                      />
                      <Skeleton
                        variant="text"
                        width={300}
                        height={40}
                        sx={{ fontSize: "1rem" }}
                      />
                      <Skeleton
                        variant="text"
                        width={300}
                        height={30}
                        sx={{ fontSize: "1rem" }}
                      />
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                    </Stack>
                    <Stack spacing={1}>
                      <Skeleton
                        variant="rectangular"
                        width={400}
                        height={200}
                      />
                      <Skeleton
                        variant="text"
                        width={300}
                        height={40}
                        sx={{ fontSize: "1rem" }}
                      />
                      <Skeleton
                        variant="text"
                        width={300}
                        height={30}
                        sx={{ fontSize: "1rem" }}
                      />
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                    </Stack>
                  </>
                )}
              </Box>

              <Box sx={{ width: "100%" }}>
                {record.length > 0 ? (
                  <></>
                ) : (
                  <>
                    <Stack spacing={1}>
                      <Skeleton
                        variant="rectangular"
                        width={400}
                        height={200}
                      />
                      <Skeleton
                        variant="text"
                        width={300}
                        height={40}
                        sx={{ fontSize: "1rem" }}
                      />
                      <Skeleton
                        variant="text"
                        width={300}
                        height={30}
                        sx={{ fontSize: "1rem" }}
                      />
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                    </Stack>
                    <Stack spacing={1}>
                      <Skeleton
                        variant="rectangular"
                        width={400}
                        height={200}
                      />
                      <Skeleton
                        variant="text"
                        width={300}
                        height={40}
                        sx={{ fontSize: "1rem" }}
                      />
                      <Skeleton
                        variant="text"
                        width={300}
                        height={30}
                        sx={{ fontSize: "1rem" }}
                      />
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                    </Stack>
                  </>
                )}
              </Box>
            </Card>
          </Box>

          <Box
            sx={{
              width: {
                xs: "100%",
                md: "28%",
                height: "fit-content",
                position: "sticky",
                top: "85px",
              },
            }}
          >
            <Card sx={{ ...cardstyle, mb: "35px", p: "30px" }}>
              <Box>
                <Stack sx={{}}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "18px",
                      fontWeight: "700",
                      lineHeight: "1.3em",
                      color: "#222",
                      fontFamily: "font-family: 'Nunito Sans', sans-serif",
                    }}
                  >
                    Contact Me 
                  </Typography>
                </Stack>

                <Stack
                  flexDirection={{ xs: "column", md: "column" }}
                  justifyContent="flex-start"
                  alignItems="center"
                  marginBottom="0px"
                >
                  <TextField
                    size="small"
                    type="text"
                    placeholder="Enter Name"
                    sx={{ ...textfield, width: "100%" }}
                    value={name}
                    error={validationError && validationError.includes('Name')}
        helperText={validationError && validationError.includes('Name') && validationError}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <TextField
                    size="small"
                    type="email"
                    placeholder="Enter Email"
                    sx={{ ...textfield, width: "100%" }}
                    value={email}
                    error={validationError && validationError.includes('Email')}
                    helperText={validationError && validationError.includes('Email') && validationError}            
                    onChange={(e) => setEmail(e.target.value)}
                  />
                
                    <PhoneInput
                    size="small"
                    type="number"
                    placeholder="Mobile Number"
  country={formData.countryCode}
  value={formData.phone}
  onChange={(value, country) => handleMobileNumberChange(value, country)}
  countryCodeEditable={false}
  inputProps={{
    name: "phone",
    required: true,
    style: {
      width: "inherit",
      height: "42px",
      borderRadius: "0px",
      border: "1px solid #e3e0e0",
      marginBottom: "10px",
    },
  }}
/>
                </Stack>

                <Stack marginBottom="0px">
                  <TextField
                    size="small"
                    multiline
                    rows={3}
                    maxRows={3}
                    placeholder="Enter Query..."
                    sx={{ ...textfield, width: "100%" }}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                   {validationError && (
        <p style={{ color: 'red' }}>{validationError}</p>
      )}
                </Stack>

                <Stack
                  flexDirection={{ xs: "row", sm: "row" }}
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  marginBottom="0px"
                >
                  <Button
                    sx={{ ...buttons, my: { xs: "10px", sm: "0px" } }} onClick={handleEmailClick}>
                    <EmailIcon />
                  </Button>
                  <Button
                    sx={{ ...buttons, my: { xs: "10px", sm: "0px" } }}
                    onClick={handleCallClick}
                  >
                    <CallIcon />
                  </Button>

                  <Button
                    sx={{ ...buttons, my: { xs: "10px", sm: "0px" } }}
                    onClick={handleWhatsAppClick}
                  >
                    <WhatsAppIcon />
                  </Button>
                </Stack>
              </Box>
            </Card>
          </Box>
        </Container>
      </Box>

      {/* main foooter  */}
      <Box sx={{ pt: "50px", backgroundColor: "#2c2c2c" }}>
        <Container maxWidth="lg">
          <Box>
            <MainFooter />
          </Box>
        </Container>
        <Box sx={{ borderTop: "0.5px solid #666666", py: "20px", mt: "25px" }}>
          {/* <Box sx={{ border: "1px solid red" }}> */}
          <Container>
            <Newsletter />
          </Container>
          {/* </Box> */}
        </Box>
      </Box>
      {/* bottom footer  */}
      <Box sx={{ backgroundColor: "#272727", py: "15px" }}>
        <Container maxWidth="lg">
          <BottomFooter />
        </Container>
      </Box>
      {/* footer top scroll button  */}
      <FooterButton scroll={props.scroll} />
    </>
  );
};

export default SingleTeamMember;
