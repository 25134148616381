import React, { createContext, useState } from 'react';

export const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [record, setRecord] = useState([]);
  const [searchStatus, setSearchStatus] = useState('loading');
  const [totalCount, setTotalCount] = useState(0);

  return (
    <SearchContext.Provider value={{ record, setRecord, searchStatus, setSearchStatus, totalCount, setTotalCount }}>
      {children}
    </SearchContext.Provider>
  );
};
