import React,{useState, useEffect} from 'react';
import { Box, Typography, Button, Modal, TextField, Snackbar, Alert } from '@mui/material';
import { buttonStyle, heading } from '../../theme/theme';
import AnimatedNumber from 'animated-number-react';
import CloseIcon from '@mui/icons-material/Close';
import PhoneInput from "react-phone-input-2";
const Neighbourhood = ({ projectDetails }) => {
  const [nearByContents, setNearByContents] = useState([]);
  const [ projectTitle, setProjectTitle ] = useState('');
  useEffect(() => {
    if (projectDetails && projectDetails.nearBy) {
      setNearByContents(parseNearBy(projectDetails.nearBy));
    }
    setProjectTitle(projectDetails.project.title);
  }, [projectDetails, projectTitle]);

  const parseNearBy = (nearByArray) => {
    return nearByArray.map((item) => {
      const parts = item.split(' '); 
      return {
        number: parts[0],
        unit: parts[1], 
        location: parts.slice(2).join(' '), 
      };
    });
  };    
   
      const distanceBoxesContainer = {
        display: { xs: "block", sm: "flex", md: "flex" },
       justifyContent:"space-between",
       textAlign: { xs: "center", sm: "initial" },
      };
      const distanceBox = {
        width: { xs: "100%", sm: "75%", md: "18%" },
        paddingY: "20px",
        height: "auto",
        backgroundColor: "#f0f0f0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "5px",
      };
      const distanceNumber={
        fontSize:"32px",
        color:"black"
      }
  return (
    <Box sx={{ my: "25px" }}>
      <Box
        sx={{ height: "1px", border: "1px solid #e1d3bc", marginY: "15px" }}
      ></Box>
      {/* Main Heading */}
      <Typography sx={heading} gutterBottom>
        Neighbourhood
      </Typography>

      {/* Square boxes in a row */}
      <Box sx={distanceBoxesContainer}>
        {nearByContents.map((content, index) => (
          <Box key={index} sx={distanceBox}>
            <AnimatedNumber
              value={parseInt(content.number, 10)}
              duration={3000}
              formatValue={(value) => value.toFixed(0)}
              sx={distanceNumber}
            >
              {(animatedValue) => (
                <Typography sx={distanceNumber}>
                  {animatedValue}
                </Typography>
              )}
            </AnimatedNumber>

            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ textTransform: "capitalize" }}
            >
              {content.unit}
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{ textTransform: "uppercase", textAlign: "center" }}
            >
              {content.location}
            </Typography>
          </Box>
        ))}
      </Box>

      {/* Button */}
      <Box sx={{ textAlign: "center", marginTop: 2 }}>
        {/* <Button variant="contained" color="primary" sx={buttonStyle}>
          Know More
        </Button> */}
        <KnowMoreModal projectTitle={projectTitle}/>
      </Box>
      <Box
        sx={{ height: "1px", border: "1px solid #e1d3bc", marginY: "15px" }}
      ></Box>
    </Box>
  );
}

export default Neighbourhood;
export const KnowMoreModal = ({projectTitle}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [formData, setFormData] = useState({
      name: "",
      email: "",
      phone: "",
      message: "",
      page_name: "single_project_page",
      agentName: "",
      agentEmail: "sales@cityluxedxb.com ",
      button_type:"know_more_button"
    });
    const handleMobileNumberChange = (value) => {
      let sanitizedValue = value.replace(/\D/g, "");
      if (sanitizedValue && sanitizedValue[0] !== "+") {
        sanitizedValue = `+${sanitizedValue}`;
      }
    
      setFormData((prevFormData) => ({
        ...prevFormData,
        phone: sanitizedValue,
      }));
    };
    const sendFormDataToAPI = () => {
      const dataToSend = {
        ...formData,
        page_link: `https://www.pscityluxe.com/projects/single-project-details/${projectTitle}`
      };
      fetch('/cityluxedxb/contact.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      })
      .then(response => {
        if (response.ok) {
          setSnackbarMessage("Thanks For Your Interest! We Will Get Back To You Shortly!");
          setSnackbarOpen(true);  
                 } else {
          console.error('Failed to send form data');
        }
      })
      .catch(error => {
        console.error('Error sending form data:', error);
      });
    };
    const handleSubmitClick = () => {
      if (!isFormValid()) {
        setSnackbarMessage("Please fill all input fields first.");
          setSnackbarOpen(true);  
        return;
      }
  
      sendFormDataToAPI();
    };
    const isFormValid = () => {
      return (
        formData.name &&
        formData.email &&
        formData.phone 
        // formData.message
      );
    };
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
    };
    const handleClick = () => {
      handleClose();
      handleSubmitClick();
    };
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: "2px solid #e1d3bc",
      boxShadow: 24,
      p: 3,
      display:"flex",
      flexDirection:"column",
      justifyContent:"center"
    };
    const CloseIconStyle = {
       position: "absolute",
      top: "5px",
      right:"3px",
      cursor:"pointer",
      '&:hover': {
        color: "red",
        backgroundColor:"#4c4f54"
    } 
     }
     const textfield = {
      backgroundColor: "#ffffff",
      color: "#8593a9",
      borderRadius: "0px",
      border: "0.5px solid #e6e6e6",
      mb: "10px",
    };
    const subheading = {
          mt: '0px',
          mb:"10px",
          maxWidth: '700px',
          paddingRight: '25px',
          color: "#6a6363",
          lineHeight: { xs: '25px', md: '27px' },
          fontSize: { xs: '15px', md: '18px' },
          fontFamily: "'Nunito Sans', sans-serif",
          fontWeight: '500',
         marginLeft:"21px"
      }
      const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbarOpen(false);
      };
  return (
    <div>
      <Button variant="contained" sx={buttonStyle} onClick={handleOpen}>
        Know More
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`animate__animated ${
          open ? "animate__fadeIn" : "animate__fadeOut"
        }`}
      >
        <Box sx={style}>
          <CloseIcon sx={CloseIconStyle} onClick={handleClose} />
          <Typography variant="h5" component="h2" sx={subheading}>
            KNOW MORE ABOUT THE PROJECT
          </Typography>
          <TextField
            size="small"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            type="text"
            placeholder="Enter Name"
            id="nameTextField"
            sx={{ ...textfield, width: "100%" }}
          />
          <TextField
            size="small"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="Enter Email"
            id="emailTextField"
            sx={{ ...textfield, width: "100%" }}
          />
          <PhoneInput
            country={"ae"}
            value={formData.phone}
            id="phoneNumberTextField"
            onChange={handleMobileNumberChange}
            countryCodeEditable={false}
            inputProps={{
              name: "phone",
              required: true,
              style: {
                width: "inherit",
                height: "auto",
                borderRadius: "0px",
                border: "1px solid #e3e0e0",
              },
            }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={buttonStyle}
            onClick={handleClick}
          >
            Know More
          </Button>
          <Typography
            variant="body2"
            sx={{ fontSize: "10px", padding: "2px", marginTop: "10px" }}
          >
            We value your privacy. Your contact information is never shared with
            any third party and will remain internal where you can opt out at
            any time.
          </Typography>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};