import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { bedroom, Price, Area } from "./subcomponent/all_values_search";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import InputSearchKeywordList from "./customHooks/inputSearchKeywordList";

const filter = createFilterOptions();
const Advance_search = (props) => {
  const [serchProperty, setSearchProperty] = useState([]);
  const [catList, setCatList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState([]);
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  const [minBed, setMinBed] = useState("");
  const [maxBed, setMaxBed] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [minArea, setMinArea] = useState("");
  const [maxArea, setMaxArea] = useState("");
  const [searchClicked, setSearchClicked] = useState(false);
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = state;
  const handleSearchClick = () => {
    if (searchKeyword.length === 0 && category === "" && type === "") {
      setAlertMessage("Please select at least one detail");
      handleClick({ vertical: "top", horizontal: "right" });
    } else {
      const searchValues = searchKeyword.map((item) => item.label);
      const newKeywords = {
        searchKeyword: searchValues,
        category: category,
        type: type,
        minBed: minBed,
        maxBed: maxBed,
        minPrice: minPrice,
        maxPrice: maxPrice,
        minArea: minArea,
        maxArea: maxArea,
      };
      props.sendKeywords(newKeywords);
    }
  };
  let handleClick = (newState) => {
    setState({ open: true, ...newState });
  };
  let handleClose = () => {
    setState({ ...state, open: false });
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
        sx={{ color: "red" }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const button = {
    color: "#4c4f54",
    bgcolor: "custom.lightwheat",
    transition: "0.3s",
    fontFamily: "'Nunito Sans', sans-serif",
    fontWeight: "700",
    border: "1px solid #ffffff",
    px: "40px",
    borderRadius: "0px",
    fontSize: { xs: "9px", md: "13px" },
    py: "10px ",
    "&:hover": {
      bgcolor: "#4c4f54",
      color: "custom.lightwheat",
    },
  };

  const selectitem = {
    backgroundColor: "#ffffff",
    color: "#8593a9",
    borderRadius: "0px",
    cursor: "pointer",
    "&:hover": {
      border: "0px",
    },
  };

  const Formcontrol = {
    minWidth: "100%",
    mb: "10px",
    padding: "10px",
  };

  let [data] = InputSearchKeywordList();

  useEffect(() => {
    window.scrollTo(2, 2);
    if (data !== null) {
      setSearchProperty([
        ...data.community,
        ...data.property_name,
        ...data.emirate,
      ]);
      setCatList(data.unit_type);
      setTypeList(data.ad_type);
    }
  }, [data]);
  const findMatchRecords = (event) => {
    event.preventDefault(); 
    const scrollPosition = window.scrollY;
    setSearchClicked(true);
    const searchValues = searchKeyword.map((item) => item.label);
    let item = {
      searchKeyword: searchValues, 
      category: category,
      type: type,
      minBed: minBed,
      maxBed: maxBed,
      minPrice: minPrice,
      maxPrice: maxPrice,
      minArea: minArea,
      maxArea: maxArea,
    };
      window.scrollTo(1, scrollPosition);
      props.sendKeywords(item);
      const middlePosition = window.innerHeight / 2;
      window.scrollTo(1, middlePosition);
    
    const newSearchParams = new URLSearchParams();
    const newPath = `/search?${newSearchParams.toString()}`;
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={alertMessage}
        key={vertical + horizontal}
        action={action}
      ></Snackbar>
      <Box>
        <Stack>
        <FormControl
  className="formAdvanceSearch"
  sx={{ ...Formcontrol }}
  size="small">
  <Autocomplete
    disablePortal
    multiple 
    size="small"
    id="combo-box-demo"
    value={searchKeyword} 
    onChange={(event, newValue) => {
      setSearchKeyword(newValue); 
    }}
    filterOptions={(options, params) => {
      const filtered = filter(options, params);

      const { inputValue } = params;
      const isExisting = options.some(
        (option) => inputValue === option.label
      );
      if (inputValue !== "" && !isExisting) {
        filtered.push({
          inputValue,
          label: `Add "${inputValue}"`,
        });
      }
      return filtered;
    }}
    options={serchProperty}
    getOptionLabel={(option) => {
      if (typeof option === "string") {
        return option;
      }
      if (option.inputValue) {
        return option.inputValue;
      }
      return option.label;
    }}
    className="autocompliteInput search_property"
    renderOption={(props, option) => (
      <li {...props}>{option.label}</li>
    )}
    sx={{ ...selectitem }}
    renderInput={(params) => (
      <TextField {...params} placeholder="Search Property" />
    )}
  />
</FormControl>
          <FormControl
            className="formAdvanceSearch"
            sx={{ ...Formcontrol }}
            size="small"
          >
            <Autocomplete
              // disablePortal multiple
              size="small"
              id="combo-box-demo"
              className="autocompliteInput search_categories"
              onChange={(e, val) => setCategory(val ? val.label : "")}

              options={catList}
              sx={selectitem}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Categories"
                />
              )}
            />
          </FormControl>
          <FormControl
            className="formAdvanceSearch"
            sx={{ ...Formcontrol }}
            size="small"
          >
            <Autocomplete
              disablePortal
              size="small"
              id="combo-box-demo"
              className="autocompliteInput search_type"
              onChange={(e, val) => (val ? setType(val.label) : setType(""))}
              options={typeList}
              sx={selectitem}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Type"
                />
              )}
            />
          </FormControl>

          <FormControl
            className="formAdvanceSearch"
            sx={{ ...Formcontrol }}
            size="small"
          >
            <Autocomplete
              disablePortal
              size="small"
              id="combo-box-demo"
              className="autocompliteInput search_min_bed"
              onChange={(e, val) =>
                val ? setMinBed(val.label) : setMinBed("")
              }
              options={bedroom}
              sx={selectitem}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Min Bed"
                />
              )}
            />
          </FormControl>
          <FormControl
            className="formAdvanceSearch"
            sx={{ ...Formcontrol }}
            size="small"
          >
            <Autocomplete
              disablePortal
              size="small"
              id="combo-box-demo"
              className="autocompliteInput search_max_bed"
              options={bedroom}
              onChange={(e, val) =>
                val ? setMaxBed(val.label) : setMaxBed("")
              }
              sx={selectitem}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Max Bed"
                />
              )}
            />
          </FormControl>
          <FormControl
            className="formAdvanceSearch"
            sx={{ ...Formcontrol }}
            size="small"
          >
            <Autocomplete
              disablePortal
              size="small"
              id="combo-box-demo"
              className="autocompliteInput search_min_price"
              onChange={(e, val) =>
                val ? setMinPrice(val.label) : setMinPrice("")
              }
              options={Price}
              sx={selectitem}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Min Price"
                />
              )}
            />
          </FormControl>
          <FormControl
            className="formAdvanceSearch"
            sx={{ ...Formcontrol }}
            size="small"
          >
            <Autocomplete
              disablePortal
              size="small"
              id="combo-box-demo"
              className="autocompliteInput search_max_price"
              onChange={(e, val) =>
                val ? setMaxPrice(val.label) : setMaxPrice("")
              }
              options={Price}
              sx={selectitem}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Max Price"
                />
              )}
            />
          </FormControl>
          <FormControl
            className="formAdvanceSearch"
            sx={{ ...Formcontrol }}
            size="small"
          >
            <Autocomplete
              disablePortal
              size="small"
              id="combo-box-demo"
              className="autocompliteInput search_min_area"
              onChange={(e, val) =>
                val ? setMinArea(val.label) : setMinArea("")
              }
              options={Area}
              sx={selectitem}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Min Area"
                />
              )}
            />
          </FormControl>
          <FormControl
            className="formAdvanceSearch"
            sx={{ ...Formcontrol }}
            size="small"
          >
            <Autocomplete
              disablePortal
              size="small"
              id="combo-box-demo"
              className="autocompliteInput search_max_area"
              onChange={(e, val) =>
                val ? setMaxArea(val.label) : setMaxArea("")
              }
              options={Area}
              sx={selectitem}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Max Area"
                />
              )}
            />
          </FormControl>
            <Button
              sx={button}
              onClick={findMatchRecords}
              loading={loading}
              loadingPosition="end"
            >
              Search
            </Button>
        </Stack>
      </Box>
    </>
  );
};
export default Advance_search;
