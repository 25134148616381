import React, { useContext, useEffect, useState } from "react";
import { cardstyle, stickycardstyle } from "../theme/theme";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import TopNavbar from "../components/top_navbar";
import ManManu from "../components/main_manu";
import {
  Box,
  Container,
  Card,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  Button,
  TextField,
} from "@mui/material";
import home_pro_img from "../uploads/2020/07/house_nice_2-12.jpg";
import Breadcrumb from "../components/bread_crumb";
import CardView from "../components/card_view";
import Advance_search from "../components/advance_search";
import MainFooter from "../components/main_footer";
import BottomFooter from "../components/botttom_footer";
import Newsletter from "../components/newsletter";
import FooterButton from "../components/footer_button";
import OurListings from "../components/our_listings";
import Client from "../components/axios";
import Pagination from "@mui/material/Pagination";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import PhoneInput from "react-phone-input-2";
import { FilteredRecordsContext } from "./FilteredRecordsContext";
import axios from 'axios';
const textfield = {
  backgroundColor: "#ffffff",
  color: "#8593a9",
  borderRadius: "0px",
  border: "0.5px solid #e6e6e6",
  mb: "10px",
};

const buttons = {
  m: 0,
  marginRight: "20px",
  px: "25px",
  backgroundColor: "custom.lightwheat",
  color: "#4c4f54",
  fontSize: "12px",
  borderRadius: "0px",
  "&:hover": { backgroundColor: "#4c4f54", color: "#fff" },
};
const Properties = (props) => {
  const [record, setRecord] = useState([]);
  const { filteredRecord } = useContext(FilteredRecordsContext);

  useEffect(() => {
    setRecord(filteredRecord);
  }, [filteredRecord]);

  const [totalcount, setTotalCount] = useState(0);
  const [searchStatus, setSearchStatus] = useState('loading');
  const [advanceSearchKeywords, setAdvanceSearchKeywords] = useState({
    searchKeyword: [],
    category: "",
    type: "",
    minBed: "",
    maxBed: "",
    minPrice: "",
    maxPrice: "",
    minArea: "",
    maxArea: "",
  });
  
  const handleImageSelection = (imageUrl) => {
    setBackgroundImage(imageUrl);
  };
  const [backgroundImage, setBackgroundImage] = useState(home_pro_img);
  const [selectcount, setselectcount] = useState({ page: 0 });
  const [highlow, setHighLow] = useState({ sorting: "none" });
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [formData, setFormData] = useState({
    countryCode: 'ae',
    phone: "",
  });

  let get_search_keyword_from_advance_search = (value) => { setAdvanceSearchKeywords(value); };
  let allproperties = record.map((value, index) => (
    <CardView key={index} listitem={value} cardwidth={"49%"} handleImageSelection={handleImageSelection} /> ));

  let matches = props.matches;
  let manmanu = {};
  if (matches) {
    if (props.scroll) {
      manmanu = {
        width: "100%",
        py: "0px",
        mt: "-1px",
        bgcolor: "white",
        position: "fixed",
        top: 0,
        zIndex: 3,
        animation: "scrollmanu 0.3s linear",
        "@keyframes scrollmanu": {
          from: {
            py: "10px",
          },
          to: {
            py: "0px",
          },
        },
      };
    } else {
      manmanu = {
        width: "100%",
        py: "10px",
        mt: "-1px",
        bgcolor: "custom.white",
        position: "unset",
        zIndex: 3,
        animation: "scrollmanu2 0.3s linear",
        "@keyframes scrollmanu2": {
          from: {
            py: "0px",
          },
          to: {
            py: "10px",
          },
        },
      };
    }
  } else {
    manmanu = {
      width: "100%",
      bgcolor: "custom.dark4",
    };
  }
 

  const subheading = {
    fontFamily: "'Nunito Sans', sans-serif",
    fontSize: "17px",
    textTransform: "uppercase",
    fontWeight: "700",
    color: "#434953",
    mb: "10px",
  };

  useEffect(() => {
    document.title = "Properties-city luxe"
    Client.post("/cityluxedxb/advance_search.php", {
      advanceSearchKeywords,
      highlow,
      selectcount,
    })
      .then((response) => {
        if (response.data.errorcode === "200" && response.data.totalRecords > 0) {
          let listOfProperty = response.data.propertieslist;
          let totalRecords = response.data.totalRecords; 
          setRecord([]);
          setRecord(listOfProperty);

          let totalRecord = totalRecords / 10;
          let decimal_value = totalRecord - Math.floor(totalRecord);
          if (decimal_value > 0) {
            setTotalCount(Math.floor(totalRecord) + 1);
          } else {
            setTotalCount(totalRecord);
          }
          setSearchStatus('success'); 
        } else if (response.data.errorcode === "400") {
          setSearchStatus('not found'); 
        } else {
          setSearchStatus('error'); 
        }
      })
      .catch((err) => {
        console.log(err);
        setSearchStatus('error');
      });
  }, [advanceSearchKeywords, highlow, selectcount]);   

  const saveFormData = async () => {
    try {
      await axios.post("/cityluxedxb/contact.php", {
        name: name,
        email: email,
        phone: formData.phone,
        message: message,
        page_name: "property listing page",
        agentEmail: "sales@cityluxedxb.com",
        page_link: "https://www.pscityluxe.com/properties"

      });
      alert('Query submitted successfully!');
    } catch (error) {
      console.error('Error saving form data', error);
      alert('Failed to submit query. Please try again.');
    }
  };

  const handleWhatsAppClick = () => {
    saveFormData();
   
  };
  
  const handleEmailClick = () => {
    saveFormData();
  };
  
  const handleCallClick = () => {
    saveFormData();
    const phoneNumber = '+971 55 423 5695';  
    const callURL = `tel:${phoneNumber}`;
    window.location.href = callURL;
  }; const handleMobileNumberChange = (value, country) => {
    const sanitizedValue = '+' + value.replace(/\D/g, "").slice(0, 15); 
  
    setFormData((prevFormData) => ({
      ...prevFormData,
      countryCode: country.countryCode,
      phone: sanitizedValue,
    }));
  };
  
  
  return (
    <>
      <Box sx={{ width: "100vw" }}>
        <Box
          sx={{
            position: "relative",
            top: 0,
            left: 0,
            minHeight: {
              xs: "60vh",
              sm: "600px",
              md: "580px",
              lg: "580px",
              xl: "580px",
            },
          }}
        >
          {/* TopNavBar... */}
          <Box bgcolor={{ xs: "custom.dark", md: "custom.dark" }}>
            <Container maxWidth="lg">
              <TopNavbar matches={matches} />
            </Container>
          </Box>

          {/* NavBar */}
          <Box sx={manmanu}>
            <Container maxWidth="lg">
              <ManManu scroll={props.scroll} color={"dark2"} />
            </Container>
          </Box>
          <Box
            sx={{
              position: "fixed",
              zIndex: -1,
              top: 0,
              left: 0,
              width: "100vw",
              height: { xs: "60vh", sm: "600px", md: "100vh" },
              backgroundImage: ` url("${backgroundImage}")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></Box>
        </Box>

        <Box sx={{ backgroundColor: "#f7f7f7", border: "1px solid #fff" }}>
          <Container
            maxWidth="lg"
            sx={{
              my: "27px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box sx={{ width: { xs: "100%", md: "70%" } }}>
              <Box sx={{ mb: "15px" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Stack>
                    <Breadcrumb breadcrumb={["home", "properties"]} />
                  </Stack>
                  <Stack sx={{ flexDirection: "row", alignItems: "center" }}>
                    <InputLabel
                      id="demo-simple-select-standard-label"
                      sx={{ fontSize: "12px" }}
                    >
                      SORT BY{" "}
                      <NavigateNextIcon
                        fontSize="small"
                        sx={{ marginTop: "-2px" }}
                      />
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={highlow.sorting}
                      sx={{ fontSize: "12px", color: "#D7C3A8", height: "0px" }}
                      onChange={(e) => setHighLow({ sorting: e.target.value })}
                    >
                      <MenuItem
                        value={"none"}
                        sx={{ fontSize: "12px", color: "#D7C3A8" }}
                      >
                        SELECT
                      </MenuItem>
                      <MenuItem
                        value={"high to low"}
                        sx={{ fontSize: "12px", color: "#D7C3A8" }}
                      >
                        {" "}
                        PRICE HIGH
                      </MenuItem>
                      <MenuItem
                        value={"low to high"}
                        sx={{ fontSize: "12px", color: "#D7C3A8" }}
                      >
                        PRICE LOW
                      </MenuItem>
                      <MenuItem
                        value={"BEDS MOST"}
                        sx={{ fontSize: "12px", color: "#D7C3A8" }}
                      >
                        {" "}
                        BEDS MOST
                      </MenuItem>
                      <MenuItem
                        value={"BEDS LEAST"}
                        sx={{ fontSize: "12px", color: "#D7C3A8" }}
                      >
                        BEDS LEAST
                      </MenuItem>
                    </Select>
                  </Stack>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                {searchStatus === "loading" && (
                  <>
                    <Stack spacing={1}>
                      <Skeleton
                        variant="rectangular"
                        width={400}
                        height={200}
                      />
                      <Skeleton
                        variant="text"
                        width={300}
                        height={40}
                        sx={{ fontSize: "1rem" }}
                      />
                      <Skeleton
                        variant="text"
                        width={300}
                        height={30}
                        sx={{ fontSize: "1rem" }}
                      />
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                    </Stack>
                    <Stack spacing={1}>
                      <Skeleton
                        variant="rectangular"
                        width={400}
                        height={200}
                      />
                      <Skeleton
                        variant="text"
                        width={300}
                        height={40}
                        sx={{ fontSize: "1rem" }}
                      />
                      <Skeleton
                        variant="text"
                        width={300}
                        height={30}
                        sx={{ fontSize: "1rem" }}
                      />
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                    </Stack>
                  </>
                )}
                {searchStatus === "success" &&
                  record.length > 0 &&
                  allproperties}
                {searchStatus === "not found" && (
                  <p>Search result is not found</p>
                )}
                {searchStatus === "error" && (
                  <p>An error occurred while searching</p>
                )}

                <Card sx={{ width: "100%", mb: "35px", py: "10px" }}>
                  <Stack spacing={2} alignItems="center">
                    <Pagination
                      className="propertyPagination"
                      count={totalcount}
                      defaultPage={1}
                      siblingCount={3}
                      onChange={(e, value) => {
                        setRecord([]); 
                        setselectcount({ page: value - 1 }); 
                      }}
                      size="small"
                      variant="outlined"
                      shape="rounded"
                    />
                  </Stack>
                </Card>
              </Box>
            </Box>

            <Box
              sx={{ width: { xs: "100%", md: "28%", position: "relative" } }}
            >
              {" "}
              <Card
                sx={{
                  ...cardstyle,
                  mb: "35px",
                  p: "30px",
                  position: "stdeicky",
                  right: "0px",
                  top: "100px",
                }}
              >
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  component="div"
                  sx={{ ...subheading }}
                >
                  Advanced Search
                </Typography>
                <Advance_search
                  sendKeywords={get_search_keyword_from_advance_search}
                />
                {/* sendRecords={get_records_pagination} */}
              </Card>
              <Card sx={{ ...cardstyle, mb: "35px", p: "30px" }}>
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  component="div"
                  sx={{ ...subheading }}
                >
                  Our Listings
                </Typography>
                <OurListings />
              </Card>
              {/* contact us form */}
              <Card
                sx={{
                  ...stickycardstyle,
                  mb: "35px",
                  p: "30px",
                  position: "sticky",
                  top: "85px",
                }}
              >
                <Typography
                  gutterBottom
                  variant="subtitle1"
                  component="div"
                  sx={{ ...subheading }}
                >
                  {" "}
                  Contact Us{" "}
                </Typography>
                <Stack
                  flexDirection={{ xs: "column", md: "column" }}
                  justifyContent="flex-start"
                  alignItems="center"
                  marginBottom="0px"
                >
                  <TextField
                    size="small"
                    type="text"
                    placeholder="Enter Name"
                    sx={{ ...textfield, width: "100%" }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <TextField
                    size="small"
                    type="email"
                    placeholder="Enter Email"
                    sx={{ ...textfield, width: "100%" }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  <PhoneInput
                    placeholder="Mobile Number"
                    size="small"
                    type="number"
                    country={formData.countryCode}
                    value={formData.phone}
                    onChange={(value, country) =>
                      handleMobileNumberChange(value, country)
                    }
                    countryCodeEditable={false}
                    inputProps={{
                      name: "phone",
                      required: true,
                      style: {
                        width: "100%",
                        height: "40px",
                        borderRadius: "0px",
                        border: "1px solid #e3e0e0",
                        marginBottom: "10px",
                      },
                    }}
                  />
                </Stack>
                <Stack marginBottom="0px">
                  <TextField
                    size="small"
                    // label="Type Keywords"
                    multiline
                    rows={3}
                    maxRows={3}
                    placeholder="Enter Query..."
                    sx={{ ...textfield, width: "100%" }}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Stack>
                <Stack
                  flexDirection={{ xs: "row", sm: "row" }}
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  marginBottom="0px"
                >
                  <Button
                    sx={{ ...buttons, my: { xs: "10px", sm: "0px" } }}
                    onClick={handleEmailClick}
                  >
                    <EmailIcon />
                  </Button>
                  <Button
                    sx={{ ...buttons, my: { xs: "10px", sm: "0px" } }}
                    onClick={handleCallClick}
                  >
                    <CallIcon />
                  </Button>

                  <Button
                    sx={{ ...buttons, my: { xs: "10px", sm: "0px" } }}
                    onClick={handleWhatsAppClick}
                  >
                    <WhatsAppIcon />
                  </Button>
                </Stack>
              </Card>
            </Box>
          </Container>
        </Box>

        {/* main foooter  */}
        <Box sx={{ pt: "50px", backgroundColor: "#2c2c2c" }}>
          <Container maxWidth="lg">
            <Box>
              <MainFooter />
            </Box>
          </Container>
          <Box
            sx={{ borderTop: "0.5px solid #666666", py: "20px", mt: "25px" }}
          >
            <Container>
              <Newsletter />
            </Container>
            {/* </Box> */}
          </Box>
        </Box>
        {/* bottom footer  */}
        <Box sx={{ backgroundColor: "#272727", py: "15px" }}>
          <Container maxWidth="lg">
            <BottomFooter />
          </Container>
        </Box>
        {/* footer top scroll button  */}
        <FooterButton scroll={props.scroll} />
      </Box>
    </>
  );
};
export default Properties;