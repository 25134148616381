import React,{useState, useEffect} from 'react';
import { Box, Typography, Button, Modal, TextField, Snackbar, Alert } from '@mui/material';
import { buttonStyle, heading, subheading } from '../../theme/theme';
import moneyImg from '../../assests/money.png'
import constructionImg from '../../assests/construction.png'
import handoverImg from "../../assests/hand-over.png"
import BookAUnit from './BookAUnit';
import 'aos/dist/aos.css'; 
import CloseIcon from '@mui/icons-material/Close';
import PhoneInput from "react-phone-input-2";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: "2px solid #e1d3bc",
  boxShadow: 24,
  p: 3,
};
const CloseIconStyle = {
   position: "absolute",
  top: "5px",
  right:"3px",
  cursor:"pointer",
  '&:hover': {
    color: "red",
    backgroundColor:"#4c4f54"
} 
 } 
 const textfield = {
  backgroundColor: "#ffffff",
  color: "#8593a9",
  borderRadius: "0px",
  border: "0.5px solid #e6e6e6",
  mb: "10px",
};
const priceBoxes ={
  fontSize:{xs:"1rem", sm:"1.5rem", md:"3.75rem"}

}
const Pricing = ({projectDetails}) => {
  const [open, setOpen] = useState(false);
const [ paymentPlan, setPaymentPlan ] = useState('');
const [ projectTitle, setProjectTitle ] = useState('')

useEffect(() => {
  const projectTitle = projectDetails.project.title;
        setProjectTitle(projectTitle);

  const addPlanArray = projectDetails.addPlan;
  const paymentPdfItems = addPlanArray.filter(item => item.type === 'Payments Plan');
  if (paymentPdfItems.length > 0) {
    setPaymentPlan(paymentPdfItems[0].plan_url);
  }
}, [projectDetails]);  

const smallImg={
        width:"90px",
        height  :"90px"
    }
    const smallText ={
        fontSize:"14px"
    }
    
  const handleClose = () => {
    setOpen(false);
  };
  if (!projectDetails.floorPlan || projectDetails.floorPlan.length === 0) {
    return null;
  }
  return (
    <>
    {/* Pricing section */}
    <Box sx={{mt:"25px", mb:"25px"}}>
      {/* Main Heading */}
      <Typography sx={heading} gutterBottom>
        Pricing
      </Typography>

      {/* Full width box divided into three columns */}
      <Box
  sx={{
    display: "flex",
    flexDirection: "row",
    width: "100%",
    backgroundColor: "#f7f5f1",
    pt: "30px",
    pb: "30px",
  }}
>
  {/* Map over the floorPlan array */}
  {projectDetails.floorPlan.slice(0, 5).map((item, index) => (
    <Box key={index} sx={{ flex: 1, textAlign: "center" }} >
      <Typography sx={priceBoxes} gutterBottom>
        {item.pricing}M
      </Typography>
      <Typography variant="body1">{item.type.replace("Floor Plan", "")}</Typography>
    </Box>
  ))}
</Box>

      {/* Button */}
      <Box sx={{ textAlign: 'center', marginTop: 2 }}>
      <BookAUnit open={open} handleClose={handleClose} projectTitle={projectTitle} />
      </Box>
    </Box>
    {/* payment plan section */}
    <Box sx={{mt:"25px", mb:"25px"}}>
      {/* Main Heading */}
      <Typography sx={heading} gutterBottom>
        Payment Plan
      </Typography>

      {/* Full width box divided into three columns */}
      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', backgroundColor:"#f7f5f1", pt:"30px", pb:"30px" }}>
        {/* First Column */}
        <Box sx={{ flex: 1, textAlign: 'center',  }}>
          <Typography gutterBottom>
            <img src={moneyImg} style={smallImg} alt="down payment" />
          </Typography>
          <Typography sx={smallText}>
          {projectDetails.project.down_payment_percent}
          </Typography>
          <Typography variant="body1">
            Donw Payment
          </Typography>
          <Typography sx={smallText}>
            On Booking Date
          </Typography>
        </Box>

        {/* Second Column */}
        <Box sx={{ flex: 1, textAlign: 'center' }}>
          <Typography gutterBottom>
            <img style={smallImg} src={constructionImg} alt="during construction" />
          </Typography>
          <Typography sx={smallText}>
          {projectDetails.project.during_construction_payment}</Typography>
          <Typography variant="body1">
            During Construction
          </Typography>
          <Typography sx={smallText}>
            1st to 28th Installment
          </Typography>
        </Box>

        {/* Third Column */}
        <Box sx={{ flex: 1, textAlign: 'center' }}>
          <Typography gutterBottom>
            <img style={smallImg} src={handoverImg} alt="handedover" />
          </Typography>
          <Typography sx={smallText}>
          {projectDetails.project.handover}</Typography>
          <Typography variant="body1">
            Handover
          </Typography>
          <Typography sx={smallText}>
            100% Completion
          </Typography>
        </Box>
      </Box>
      <Box sx={{ textAlign: 'center', marginTop: 2 }}><ModalPaymentPlan paymentPlan={paymentPlan} projectTitle={projectTitle} /></Box>
    </Box>
    </>
  );
}

export default Pricing;

// modal form for download payment plans

export const ModalPaymentPlan = ({ paymentPlan, projectTitle }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [formData, setFormData] = useState({
      name: "",
      email: "",
      phone: "",
      message: "",
      page_name: "single_project_page",
      agentName: "",
      agentEmail: "sales@cityluxedxb.com ",
      button_type:"download payment plan"
    });
    const handleMobileNumberChange = (value) => {
      let sanitizedValue = value.replace(/\D/g, "");
      if (sanitizedValue && sanitizedValue[0] !== "+") {
        sanitizedValue = `+${sanitizedValue}`;
      }
    
      setFormData((prevFormData) => ({
        ...prevFormData,
        phone: sanitizedValue,
      }));
    };
    const sendFormDataToAPI = () => {
      const dataToSend = {
        ...formData,
        page_link: `https://www.pscityluxe.com/projects/single-project-details/${projectTitle}`
      };

      fetch('/cityluxedxb/contact.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      })
      .then(response => {
        if (response.ok) {
              triggerPaymentPlanDownload();
              setSnackbarMessage("Thank you for downloading the payment plan. We will contact you shortly.");
              setSnackbarOpen(true);
        } else {
          console.error('Failed to send form data');
        }
      })
      .catch(error => {
        console.error('Error sending form data:', error);
      });
    };
    const handleSubmitClick = () => {
      if (!isFormValid()) {
       
        setSnackbarMessage("Please fill all input fields first.");
          setSnackbarOpen(true);  
        return;
      }
  
      sendFormDataToAPI();
    };
    const isFormValid = () => {
      return (
        formData.name &&
        formData.email &&
        formData.phone 
        // formData.message
      );
    };
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
    };
    const handleClick = () => {
      handleClose();
      handleSubmitClick();
    };
    const triggerPaymentPlanDownload = () => {
      const link = document.createElement('a');
      link.href = paymentPlan;
      link.download = 'Payments Plan.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    const handleSnackbarClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackbarOpen(false);
    };
  return (
    <div>
      <Button variant="contained" sx={buttonStyle} onClick={handleOpen}>
        Download Payment Plan
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={`animate__animated ${open ? 'animate__fadeIn' : 'animate__fadeOut'}`}
      >
        <Box sx={style}>
          <CloseIcon sx={CloseIconStyle} onClick={handleClose} />
          <Typography variant="h5" component="h2" sx={{ ...subheading, display: "flex", justifyContent: "center", mt: 1 }}>
            Download Payment Plan
          </Typography>
          <TextField size="small" name="name" value={formData.name} onChange={handleInputChange} type="text" placeholder="Enter Name" id='nameTextField' sx={{ ...textfield, width: "100%" }} />
          <TextField size="small" type="email" name="email" value={formData.email} onChange={handleInputChange} placeholder="Enter Email" id='emailTextField' sx={{ ...textfield, width: "100%" }}/>
          <PhoneInput country={"ae"} value={formData.phone} id="phoneNumberTextField"
          onChange={handleMobileNumberChange}
          countryCodeEditable={false}
          inputProps={{
            name: "phone",
            required: true,
            style: {
              width: "inherit",
              height: "auto",
              borderRadius: "0px",
              border: "1px solid #e3e0e0",
            },
          }}
        />
          <Button variant="contained" color="primary" fullWidth sx={buttonStyle} onClick={handleClick}>
           Download Payment Plan
          </Button>
          <Typography variant="body2" sx={{ fontSize: "10px", padding: "2px", marginTop: "10px" }}>
            We value your privacy. Your contact information is never shared with any third party and will remain internal where you can opt out at any time.
          </Typography>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}

      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};